import * as React from "react";
import { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//MUI Core Components
import AddIcon from "@mui/icons-material/Add"

//Types
import { ICommentsPane } from "@/@types/components/MapSidebarPanes";
import ModelTable from "@/ui/Table/ModelTable";
import dataController from "@/lib/dataController";
import modelComments from "@/models/comments";
import { DCRecord } from "@/@types/lib/dataController";
import TableButton from "@/ui/Table/ActionButtons/TableButton";
import DialogContext from "@/ui/DialogContext/DialogContext";
import UserContext from "../UserContext/UserContext";
import CommentShapeDialog from "@/views/MapPage/CommentShapeDialog";
import MapContext from "../Map/MapContext";
import { Extent } from "ol/extent";
import { Box } from "@mui/material";
import OlSourceVector from "ol/source/Vector";
import { fadeOutHighlightedStyle } from "../Map/mapStyles";
import OlPoint from "ol/geom/Point";
import { Permissions as P } from "@/lib/permissions";

const CommentsPane: FunctionComponent<ICommentsPane> = (props) => {
  const userContext = useContext(UserContext);
  const { t } = useTranslation();
  const { changeDrawType, drawType, records, setSelectedSource, setStyle } = props;
  const dc = new dataController(modelComments);
  const dialogContext = useContext(DialogContext);
  const mapContext = useContext(MapContext);

  const handleAddComment = () => {
    dialogContext.showDialog(CommentShapeDialog, {
      drawType: drawType,
      changeDrawType: changeDrawType,
    });
  };

  function handleRowAction(action: string, id: number) {
    const record = records.find((x: DCRecord) => x.id === id);
    if (!record) return

    switch (action) {
      case "show_on_map":
        handleShowOnMap(record as DCRecord, record.extent as Extent);
        break
    }
  }
      
  function handleShowOnMap(record: DCRecord, extent: Extent) {
    if(mapContext?.map) {
      const view = mapContext.map.getView();

      const feature = record.geom;
      // @ts-ignore
      const commentsSource = new OlSourceVector({features: [feature] as OlFeature<Geometry>});

      setSelectedSource(commentsSource);
      // If you want it to fade out uncomment the line under, but the performance is horrible :)
      // fadeOutHighlightedStyle(1, setStyle);

      // This prevents a double rerender of the map which stops the zooming to the selected point
      setTimeout(() => {
        if (feature instanceof OlPoint) {
          view.animate({
            center: feature.getCoordinates(),
            zoom: 20,
            duration: 500
          })
        } else {
          view.fit(extent, { padding: [20, 20, 20, 220], duration: 500});
        }
      }, 100);
    }
  }

  let viewName = "default"

  if (userContext) {
    if (userContext.hasAnyPermission([P.Administration])) {
      viewName = "admin";
    } 
  }

  return (
    <Fragment>
        <ModelTable
          key={`tblid-${records.length}`}
          dc={dc}
          viewName={viewName}
          records={records}
          allowSelection="none"
          title={""} 
          allowRowAction={() => true} 
          onRowAction={handleRowAction}   
          initialPageSize={25}
          allowRowsPerPageSelection={true}
          trackTableState={true}
          disableControls={false}
          displayHeader={true}
          paginationPosition="bottom"
          gotoFirstPageOnSelect={false}
        >
        </ModelTable>
        <TableButton variant="contained" allow="always" onClick={handleAddComment} startIcon={<AddIcon />} selection={[]}>
            {t("titles.add_comment")}
        </TableButton>
    </Fragment>
  );
};

export default CommentsPane;
