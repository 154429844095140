import React, {
  useContext,
  SyntheticEvent,
  FunctionComponent,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";

//Custom Components
import DraggableDialog from "@/ui/Dialog/DraggableDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogContext from "@/ui/DialogContext/DialogContext";
import GridContainer from "@/ui/Grid/GridContainer";

//Types
import { IDialogProps } from "@/@types/ui/DialogContext";
import { DCRecord } from "@/@types/lib/dataController";

const KomunalneStatusDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();

  const { fields } = props;

  const handleClose = (evt: SyntheticEvent) => {
    dialogContext.hideDialog();
  };

  return (
    <DraggableDialog open={true} maxWidth={"lg"} onClose={handleClose}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>{t("common.status_view")}</DialogToolbarHeading>
          <ToolbarFillContent />
          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2}>
          {fields &&
            fields !== null &&
            fields.map((item: DCRecord) => {
              return (
                <div>
                  <li>
                    {item.status}: {item.opis}
                  </li>
                </div>
              );
            })}
        </Box>
      </DialogBody>
      <DialogActions></DialogActions>
    </DraggableDialog>
  );
};

export default KomunalneStatusDialog;
