import { IModel } from "@/@types/models/model";

import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { buttonFields } from "./helpers/buttonFields";

const ParkiralisnaMjestaModel: IModel = {
  title: "Parkirališna mjesta",
  source: "parkiralisna_mjesta",
  apiPath: "parkiralista/parkiralisna-mjesta",
  forms: {
    default: {
      fields: [
        "id",
        "sifra_murs",
        "obveznik",
        "lokacija",
        "opis",
        "aktivnost"
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        "id",
        "objectid",
        "opis",
        "proj",
        "lokcaija",
        "obveznik",
        "sifra_murs",
        "aktivnost"
      ],
      hidden: [],
    },
    sidebar: {
      fields: ["id", "naziv_parkiralista", "show_on_map"],
    },
    attribute_table: {
      fields: ["id", "naziv_parkiralista", "deselect"],
      hidden: [],
    },
  },
  fields: [
    coreFields.id,
    {
      title: "objectid",
      source: "OBJECTID",
      ttoken: "odvoz.objectid",
      type: "numeric",
      readonly: true
    },
    {
      title: "opis",
      source: "opis",
      ttoken: "odvoz.opis",
      type: "text",
      readonly: true
    },
    {
      title: "lokacija",
      source: "lokacija",
      ttoken: "odvoz.lokacija",
      type: "text",
      readonly: true
    },
    {
      title: "lokacija_id",
      source: "lokacija_i",
      ttoken: "odvoz.lokacija_id",
      type: "numeric",
      readonly: true
    },
    {
      title: "obveznik",
      source: "obveznik",
      ttoken: "odvoz.obveznik",
      type: "text",
      readonly: true
    },
    {
      title: "sifra_murs",
      source: "sifra_murs",
      ttoken: "odvoz.sifra_murs",
      type: "text",
      readonly: true
    },
    {
      title: "aktivnost",
      source: "aktivnost",
      ttoken: "odvoz.aktivnost",
      type: "text",
      readonly: true
    },
    {
      title: "projekt_id",
      source: "projekt_id",
      ttoken: "odvoz.projekt_id",
      type: "numeric",
      readonly: true
    },
    buttonFields.deselect,
  ],
};

export default ParkiralisnaMjestaModel;
