import React from "react";
import { useTranslation } from "react-i18next";

// Custom Components
import ModelTable from "@/ui/Table/ModelTable";

//Types
import { IDataController, DCRecord } from "@/@types/lib/dataController"
import { RowActionFn } from "@/@types/ui/Table";

type PhotoCentricTableProps = {
  records: Array<DCRecord>;
  onRowSelect: (id: number, record: DCRecord) => void;
  onRowAction?: RowActionFn;
  rowSelectedCustom?: number;

  titleToken: string,
  dc: IDataController,
  viewName?: string
}

function PhotoCentricTable(props: PhotoCentricTableProps) {
  const { t } = useTranslation();
  const { records, onRowSelect, onRowAction, rowSelectedCustom  } = props;
  const { titleToken, dc, viewName } = props;

  const handleRowSelect = (id: number) => {
    const record = records.find(r => r.id === id);
    if (record !== undefined) {
      onRowSelect(id, record);
    }
  }

  return (
    <ModelTable
      title={t(titleToken)}
      viewName={ viewName || "processing"}
      records={records}
      dc={dc}
      allowSelection="one"
      allowFilter={true}
      allowExport={false}
      allowAdd={false}
      allowEdit={false}
      disableControls={true}
      onRowAction={onRowAction !== undefined ? onRowAction : (id) => { }}
      onRowClick={handleRowSelect}
      initialPageSize={25}
      allowRowAction={() => { return true; }}
      trackTableState={false}
      unselectRow={true}
      rowSelectedCustom={rowSelectedCustom}
    />
  );
}

export default PhotoCentricTable;
