import React, { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";

//MUI
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";
import NavDrawerContext from "@/components/NavDrawerContext/NavDrawerContext";
import NavDrawerLink from "@/ui/NavDrawer/NavDrawerLink";

//Types
import { MenuType, LocationType, MatchType } from "@/@types/common";
import { UserContextType } from "@/@types/context/UserContext";

export interface INavDrawer {
  menu: MenuType[]
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  minHeight: 48,
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  // borderBottom: "2px",
  borderStyle: "solid",
  borderColor: theme.palette.primary.main
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const NavDrawer: FunctionComponent<INavDrawer> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const navDrawerContext = useContext(NavDrawerContext);
  const theme = useTheme();
  const { menu } = props;
  const { sidebarOpen, toggleSidebar } = navDrawerContext;

  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    toggleSidebar();
  };

  const hasGrb = userContext.tenantData && userContext.tenantData.grb ? true : false;

  return (
    <aside id="nav-drawer">
      <Drawer variant="permanent" open={sidebarOpen}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List disablePadding>
          {menu.map((m: MenuType, i: number) => {
            if (m.needPermission !== undefined && !userContext.hasAnyPermission(m.needPermission)) {
              return null;
            } else {
              return (
                <NavDrawerLink
                key={i}
                open={sidebarOpen}
                to={m.path}
                text={t(m.ttoken)}
                ttoken={m.ttoken}
                icon={m.icon}
                />
              );
            }
          })}
        </List>
        {hasGrb ? (
          <Box sx={{ position: "absolute", bottom: 10, width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={`data:image/png;base64, ${userContext.tenantData?.grb}`}
                width={'85%'} // Don't set this at 80% or below unless you want to deal with weird bugs in Chrome
                style={{
                  transition: "width 0.3s",
                }}
              />
            </Box>
          </Box>
        ) : null}
      </Drawer>
    </aside>
  );
};

export default NavDrawer;
