import React, { useContext } from "react";

//Custom Components
import DataCentricPage from "@/views/DataCentric/DataCentricPage";
import modelKuceZaOdmor from "@/models/kuce_za_odmor";
import modelObjekti from "@/models/objekti";
import { Permissions as P } from "@/lib/permissions";

// OL
import { transform } from "ol/proj";

type KomunalniObvezniciDataCentricPageParams = {};

function KuceZaOdmorDataCentricPage(
  props: KomunalniObvezniciDataCentricPageParams
) {

  const handleCustomAction = (action: string, id: number, record?: { [key: string]: any }) => {
    let locationId: number | null = null;
    let objektId: number | null = null;
    if (record && 'lokacija_id' in record) {
      locationId = record['lokacija_id'] as number;
    }
    if (record && 'objekt_id' in record) {
      objektId = record['objekt_id'] as number;
    }
    switch (action) {
      case "show_object":
        if (id && objektId) {
          const locId = locationId === null ? '' : `&loc_id=${locationId}`;
          const url = location.origin + "/komunalne-obveze/?obj_id=" + objektId.toString() + "&open_first=true" + locId;
          window.open(url, "_blank");
        }
        break;
      case "show_3d":
        if (id) {
          const [cx, cy] = transform([record?.cx, record?.cy], "EPSG:3765", "EPSG:4326").map(x => x * Math.PI / 180)

          //navigate("/3d", { state: { cx: cx, cy: cy, cz: record?.cz}}) //(opens in the same tab)
          const url = location.origin + "/3d/?cx=" + cx.toString() + "&cy=" + cy.toString() + "&cz=" + record?.cz.toString();
          window.open(url, "_blank"); // opens in a new tab
        }
        break;
      case "show_photos":
        if (id && objektId) {
          const url = location.origin + "/fotodokumentacija-objekata/?rec_id=" + objektId.toString();
          window.open(url, "_blank");
        }
        break;
    }
  };

  return (
    <DataCentricPage
      needPermission={[P.ManageKuceZaOdmor]}
      primaryModel={modelKuceZaOdmor}
      primaryTitleToken="titles.kuce_za_odmor"
      primaryRecordLocationField="id"
      disableControlsPrimary={false}
      allowFilterTogglePrimary={false}
      onCustomPrimaryTableAction={handleCustomAction}

      secondaryModel={modelKuceZaOdmor}
      secondaryViewName={"secondary"}
      secondaryTitleToken="titles.kuce_za_odmor_other"
      baseRecordPath={modelKuceZaOdmor.apiPath}
      childRecordRelativePath="other"
      geomRelativePath="geom"
      allowShowImages={false}
      mapId={25}
      
      mapModel={modelKuceZaOdmor}
      filterByLocation={false}
      allowLegend={false}
      allowExport={true}
    />
  );
}

export default KuceZaOdmorDataCentricPage;
