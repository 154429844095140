import React, { useContext, SyntheticEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";

import OlFormatWKT from "ol/format/WKT";

//Custom Components
import DraggableDialog from "../../ui/Dialog/DraggableDialog";
import DialogHeader from "../../ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButtonConfirm from "@/ui/Dialog/ActionButtons/DialogActionButtonConfirm";
import DialogActionButtonCancel from "@/ui/Dialog/ActionButtons/DialogActionButtonCancel";
import DialogActionButtonDelete from "@/ui/Dialog/ActionButtons/DialogActionButtonDelete";
import useFormController from "@/components/useFormController";
import GridContainer from "@/ui/Grid/GridContainer";
import FormContent from "@/components/FormContent";

import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import dataController from "@/lib/dataController";
import modelComments from "@/models/comments"
import { IDialogProps } from "@/@types/ui/DialogContext";
import ImageSlider from "@/components/ImageSlider";
import { Typography } from "@mui/material";

function CommentDialog(props: IDialogProps) {
    const { feature, mode, refreshLayers, dataId, onClose, initialRecord, form } = props;

    const { t } = useTranslation();
    const dialogContext = useContext(DialogContext);
    const snackbarContext = useContext(SnackbarContext);
    const [note, setNote] = useState(initialRecord ? initialRecord.note : null);
    const [rijeseno, setRijeseno] = useState(initialRecord ? initialRecord.rijeseno : false);
    const [warningState, setWarningState] = useState(false);
    
    const dc = new dataController(modelComments)

    const close = (result: any) => {
        if (onClose) {
            onClose(result);
        }
    
        dialogContext.hideDialog();
    };

    const initialRecordNoEmpty = {
        note: null,
        photos: null,
        rijeseno: false
    };

    if(initialRecord) {
        if (initialRecord.note && initialRecord.note.length > 0) {
            initialRecordNoEmpty.note = initialRecord.note
        }
        if (initialRecord.rijeseno) {
            initialRecordNoEmpty.rijeseno = initialRecord.rijeseno
        }
        if (Object.keys(initialRecord.photos).length !== 0) {
            initialRecordNoEmpty.photos = initialRecord.photos
        }
    }
    

    const { record, validation, fields, onFieldChange, doDelete } = useFormController({
        mode: mode,
        recordId: dataId,
        form: form,
        dc: dc,
        onClose: close,
        defaultValues: initialRecordNoEmpty
    });
    

    const handleFieldChange = (value: any, source: string) => {
        if (source == "note") {
            setNote(value)
        }
        if (source == "rijeseno") {
            setRijeseno(value)
        }
        if (onFieldChange) {
            onFieldChange(value, source);
        }
    };
    

    const handleClose = (evt: SyntheticEvent) => {
        close({ success: false });
    }


    const handleInsert = () => {
        setWarningState(false);

        const extent = "{" + feature.getGeometry()?.getExtent().toString() + "}"

        // @ts-ignore
        record.photos?.forEach(element => {
            if(element.type !== 'image/jpeg' && element.type !== 'image/png') {
                setWarningState(true);
            }
        });

        if(!warningState) {

            const wktFormatter = new OlFormatWKT();
            const wkt = wktFormatter.writeGeometry(feature.getGeometry());

            dc.InsertRecord({
                id: 0,
                note: note,
                wkt: wkt, 
                extent: extent,
                photos: record.photos,
                rijeseno: rijeseno
            }, "maps/1/comments").then((result) => {
                // @ts-ignore
                if (result.success) {
                    refreshLayers();
                    close({ dataChanged: true, action: "insert" });
                    snackbarContext.showNotification("insert", "success");
                } 
            });
        }
    }

    const handleUpdate = () => {
        setWarningState(false);

        const extent = "{" + feature.getGeometry()?.getExtent().toString() + "}"

        // @ts-ignore
        record.photos?.forEach(element => {
            if(element.type !== 'image/jpeg' && element.type !== 'image/png') {
                setWarningState(true);
            }
        });
    
        if(!warningState) {

            const wktFormatter = new OlFormatWKT();
            const wkt = wktFormatter.writeGeometry(feature.getGeometry());

            dc.InsertRecord({
                id: feature.getId() as number,
                note: note,
                wkt: wkt,
                extent: extent,
                photos: record.photos,
                rijeseno: rijeseno
            }).then((result) => {
                // @ts-ignore
                if (result.success) {
                    refreshLayers();
                    close({ dataChanged: true, action: "update" });
                    snackbarContext.showNotification("update", "success");
                } 
            });
        }
    }

    const handleDelete = () => {
        doDelete()
        .then((result) => {
            if (result.success) {
                snackbarContext.showNotification("delete", "success");
                close({ dataChanged: true, action: "delete" });
            } else if (result.error) {
                const msg = t("error.delete") + result.error;
                snackbarContext.showNotification(msg, "error");
            }
        })
    }

    return (
        <DraggableDialog open={true} maxWidth={"sm"} onClose={handleClose}>
            <DialogHeader>
                <Toolbar variant="dense" disableGutters={true}>
                    <DialogToolbarHeading>{mode=="insert" ? t("titles.add_comment") : mode=="update" ? t("titles.edit_comment") : null}</DialogToolbarHeading>
                    <ToolbarFillContent />
                    <DialogToolbarButtonClose onClick={handleClose} />
                </Toolbar>
            </DialogHeader>
            <DialogBody>
                <Box m={2}>
                    <GridContainer>
                        <FormContent
                            fieldNames={dc.getFormFieldsNames(form)}
                            // @ts-ignore
                            record={record}
                            validation={validation}
                            fields={fields}
                            onFieldChange={handleFieldChange}
                            mode={mode}
                            columns={1} 
                        />
                    </GridContainer>
                </Box>
                {warningState && <Typography color='#FF0000'>{t("comment.incorrect_format")}</Typography>}
            </DialogBody>
            <DialogActions>
                <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
                <DialogActionButtonConfirm variant="contained" onClick={mode == "insert" ? handleInsert : handleUpdate} />
                { mode == "update" ? <DialogActionButtonDelete variant="contained" onClick={handleDelete} /> : null }
            </DialogActions>
        </DraggableDialog>
    )
}

export default CommentDialog
