// These are some common fields that are present in multiple tables in DB
// They can be added to UI models...

import { IFieldText, IFieldMail } from "@/@types/models/model";

const oib: IFieldText = {
  title: "OIB",
  source: "oib",
  ttoken: "common.oib",
  type: "text",
  validation: {
    required: true,
    minLength: 11,
    maxLength: 11,
    regex: "oib"
  },
  export: {
    width: 20
  }
};

const vat: IFieldText = {
  title: "VAT",
  source: "vat",
  ttoken: "common.vat",
  type: "text",
  validation: {
    required: true,
    maxLength: 50
  },
  export: {
    width: 20
  }
};

const email: IFieldMail = {
  title: "E-mail",
  source: "email",
  ttoken: "common.email",
  type: "mail",
  validation: {
    required: true,
    maxLength: 250,
    regex: "email"
  },
  export: {
    width: 30
  }
};

const tel: IFieldText = {
  title: "Telephone",
  source: "tel",
  ttoken: "common.tel",
  type: "text",
  //placeholder: "+385xxxxxxxxx",
  validation: {
    required: false,
    //regex: "tel"
    maxLength: 20
  },
  export: {
    width: 25
  }
};

const mobile: IFieldText = {
  title: "Mobile phone",
  source: "mobile",
  ttoken: "common.mobile",
  type: "text",
  validation: {
    required: false,
    maxLength: 20
  },
  export: {
    width: 25
  }
};

const wkt: IFieldText = {
  title: "WKT",
  source: "wkt",
  ttoken: "common.wkt",
  type: "text",
  readonly: true,
  search: false
}

const proj: IFieldText = {
  title: "EPSG Proj",
  source: "proj",
  ttoken: "common.proj",
  type: "text",
  readonly: true
}

export const commonFields = {
  oib: oib,
  vat: vat,
  email: email,
  tel: tel,
  mobile: mobile,
  wkt: wkt,
  proj: proj
};
