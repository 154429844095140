import * as React from "react";

import { FunctionComponent } from "react";

//Material UI Core
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import { grey, orange } from "@mui/material/colors";

//Custom components
import menuAdministration from "@/routes/menuAdministration";
import AppPage from "@/ui/AppPage/AppPage";
import DialogProvider from "@/ui/DialogContext/DialogProvider";
import DialogContainer from "@/ui/DialogContext/DialogContainer";

//Types
import { IAdminPage } from "@/@types/ui/AppPage";

const AdminPage: FunctionComponent<IAdminPage> = (props) => {

  return (
    <ThemeProvider theme={outerTheme => ({
      ...outerTheme,
      palette: {
        ...outerTheme.palette,
        primary: adminTheme.palette.primary,
        secondary: adminTheme.palette.secondary
      }
    })}>
      <DialogProvider>
        <AppPage menu={menuAdministration} modul="admin">
          {props.children}
        </AppPage>
        <DialogContainer />
      </DialogProvider>
    </ThemeProvider>
  );
};

const adminTheme : Theme = createTheme({
  palette: {
    primary: {
      main: orange[800],
      dark: orange[900],
      light: orange[700]
    },
    secondary: {
      main: grey[800],
      dark: grey[900],
      light: grey[400]
    }
  }
});

export default AdminPage;

