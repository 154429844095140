import api from "../lib/api";

//OpenLayers
import OlSourceOSM from "ol/source/OSM";
import OlSourceVector from "ol/source/Vector";
import OlSourceStamen from "ol/source/Stamen";
import OlSourceTileWMS from "ol/source/TileWMS";
import OlSourceImageWMS from "ol/source/ImageWMS";
import OlSourceWMTS from "ol/source/WMTS";
import OlSourceXYZ from "ol/source/XYZ";

import OlWMTSTileGrid from 'ol/tilegrid/WMTS';

import OlLayerTile from "ol/layer/Tile";
import OlLayerImage from "ol/layer/Image";
import OlLayerVector from "ol/layer/Vector";
import OlLayerGroup from "ol/layer/Group";

import OlCollection from "ol/Collection";
import OlBaseLayer from "ol/layer/Base";
import OlTileSource from "ol/source/Tile";

import {
  BaseLayerDefinition,
  BaseLayersData,
  BaseLayerSourceType,
  BaseLayerLayerType,
  DefaultMapData,
  IMapService,
  LayerDefinition,
  DataLayerSourceType,
  DataLayerLayerType
} from "@/@types/services/mapService";
import { TFunction } from "i18next";
import { Geometry } from "ol/geom";
import { DCRecord } from "@/@types/lib/dataController";

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

const mapService: IMapService = {
  getBaseLayers,
  getLayers,
  getDefaultData
};

function getDefaultData(mapId?: number) {
  const id = mapId !== undefined ? mapId : 1;
  const apiInstance = new api();
  return apiInstance.Call(`maps/${id}/settings`, "get").then((resp) => {
    if (resp && resp.data) {
      return resp.data as DefaultMapData;
    }
    return null;
  });
}
function getBaseLayers(t: TFunction, mapId?: number): Promise<OlLayerGroup> {
  const id = mapId !== undefined ? mapId : 1;
  const apiInstance = new api();
  return apiInstance.Call(`maps/${id}/baselayers`, "get").then((resp) => {
    if (resp.success) {
      const baselayerDefinitions = resp.data as BaseLayersData;
      const baselayers = prepareBaseLayers(baselayerDefinitions, t);
      return new OlLayerGroup({
        layers: baselayers
      });
    } else {
      return new OlLayerGroup({
        layers: []
      });
    }
  });
}

function getLayers(mapId?: number): Promise<OlCollection<OlBaseLayer>> {
  const id = mapId !== undefined ? mapId : 1;
  const apiInstance = new api();
  return apiInstance.Call(`maps/${id}/layers`, "get").then((resp) => {
    if (resp.success) {
      const layerDefinitions = resp.data as Array<LayerDefinition>;
      if (layerDefinitions) {
        const layers = prepareLayers(layerDefinitions);
        const coll = new OlCollection(layers);
        return coll;
      } else {
        return new OlCollection();
      }
      
    } else {
      return new OlCollection();
    }
  });
}

const getBaseLayerSource = (def: BaseLayerDefinition) => {
  switch (def.layer_source) {
    case BaseLayerSourceType.ImageWMS:
      return new OlSourceImageWMS({
        url: def.url ? def.url : gs_url,
        params: {
          LAYERS: def.layer,
          FORMAT: def.format
        }
      });
    case BaseLayerSourceType.TileWMS:
      return new OlSourceTileWMS({
        url: def.url ? def.url : gs_url,
        projection: def.projection,
        params: {
          LAYERS: def.layer,
          FORMAT: def.format,
          TILED: def.tiled ? true : false
        }
      });
    case BaseLayerSourceType.OSM:
      return new OlSourceOSM();
    case BaseLayerSourceType.STAMEN:
      return new OlSourceStamen({
        layer: def.layer
      });
    default:
      return new OlSourceOSM();
  }
};

const getBaseLayerLayer = (def: BaseLayerDefinition, options: any) => {
  switch (def.layer_type) {
    case BaseLayerLayerType.ImageLayer:
      return new OlLayerImage(options);
    case BaseLayerLayerType.TileLayer:
      return new OlLayerTile(options);
  }
};

function prepareBaseLayers(defs: Array<BaseLayerDefinition>, t: TFunction): Array<OlBaseLayer> {
  const layers: Array<OlBaseLayer> = [];
  let first = true;

  defs.forEach((def) => {
    const options = {
      source: getBaseLayerSource(def)
    };

    const layer = getBaseLayerLayer(def, options);
    layer.set("id", "base-" + def.id.toString());
    layer.set("title", def.ttoken ? t(def.ttoken) as string : def.title);
    layer.set("type", "base");
    layer.set("baseLayer", true);
    //@ts-ignore
    if (layer.getPreview !== undefined) {
      //@ts-ignore
      layer.set("preview", layer.getPreview(def.preview_point, def.preview_res)); //projection: EPSG:3857, resolution
    }
    // layer.set("preview", "http://www.culture.gouv.fr/Wave/image/memoire/2484/sap40_z0002136_v.jpg");

    if (first) {
      layer.setVisible(true);
      first = false;
    } else {
      layer.setVisible(false);
    }

    layers.push(layer);
  });

  return layers;
}

function getDataLayerSource(def: LayerDefinition) {
  switch (def.layer_source) {
    case DataLayerSourceType.GROUP:
      return null;
    case DataLayerSourceType.TileWMS:
      return new OlSourceTileWMS({
        url: def.url ? def.url : gs_url,
        params: {
          LAYERS: def.layer,
          FORMAT: def.format,
          TILED: def.tiled ? true : false
        }
      });
    case DataLayerSourceType.ImageWMS:
      const imageWMSoptions = {
        url: def.url ? def.url : gs_url,
        params: {
          LAYERS: def.layer ? def.layer : "",
          FORMAT: def.format
        }
      };
      return new OlSourceImageWMS(imageWMSoptions);
    case DataLayerSourceType.WMTS:
      const wmtsOptions = {
        format: def.format, // Odaberi hibridni format (default image/jpeg)
        layer: def.layer ? def.layer : "",
        matrixSet: 'EPSG:900913', // Ime gridseta
        style: '',
        tileGrid: createEPSG3857Grid(), // Gore kreirani gridset
        url: def.url
      };
      return new OlSourceWMTS(wmtsOptions);
    case DataLayerSourceType.TMS:
      return new OlSourceXYZ({
        url: def.url
      });
    case DataLayerSourceType.OSM:
      return new OlSourceOSM();
    case DataLayerSourceType.STAMEN:
      return new OlSourceStamen({
        layer: def.layer
      });
    case DataLayerSourceType.VectorSource:
      return new OlSourceVector({})
    default:
      return null;
  }
}

function getDataLayerLayer(def: LayerDefinition): OlBaseLayer {
  switch (def.layer_type) {
    case DataLayerLayerType.GROUP:
      let options = {
        fold: "open",
        visible: def.visible,
        openInLayerSwitcher: true
      };
      if (def.ui_switcher_options !== "" && def.ui_switcher_options !== undefined) {
        Object.assign(options, JSON.parse(def.ui_switcher_options));
      }
      return new OlLayerGroup(options);
    case DataLayerLayerType.TileLayer:
      return new OlLayerTile({
        source: getDataLayerSource(def) as OlSourceTileWMS,
        visible: def.visible
      });
    case DataLayerLayerType.ImageLayer:
      return new OlLayerImage({
        source: getDataLayerSource(def) as OlSourceImageWMS,
        visible: def.visible
      });
    case DataLayerLayerType.VectorLayer:
      return new OlLayerVector({
        source: getDataLayerSource(def) as OlSourceVector<Geometry>,
        visible: def.visible
      })
  }
}

function flatDeep(arr: Array<OlBaseLayer>, d: number = 1): Array<OlBaseLayer> {
  return d > 0
    ? arr.reduce(
        (acc: Array<OlBaseLayer>, val) =>
          acc.concat(
            val instanceof OlLayerGroup && val.getLayers().getArray().length > 0
              ? ([val] as Array<OlBaseLayer>).concat(flatDeep(val.getLayers().getArray(), d - 1))
              : val
          ),
        []
      )
    : arr.slice();
}

function prepareLayers(defs: Array<LayerDefinition>): Array<OlBaseLayer> {
  const layers: Array<OlBaseLayer> = [];

  defs.forEach((def: LayerDefinition) => {
    const layer = getDataLayerLayer(def);
    if (layer) {
      layer.set("id", "app-" + def.code.toString());
      layer.set("title", def.title);
      layer.set("ttoken", def.ttoken);
      layer.set("type", "app-defined");
      layer.set("layer", def.layer);
      layer.set("styles", def.styles);
      layer.set("query", def.can_query);
      layer.set("call_group", def.call_group);
      layer.set("format", def.format);
      layer.set("tiled", def.tiled);
      layer.set("use_cache", def.use_cache);
      layer.set("z_index", def.z_index);
      layer.set("url", def.url);
      layer.set("extent", def.extent);
      layer.set("legend", def.legend);
      layer.set("legend_ttoken", def.legend_ttoken);
      layer.set("legend_width", def.legend_width);
      layer.set("legend_height", def.legend_height);
      layer.set("legend_rule", def.legend_rule);
      layer.set("legend_style", def.legend_style);
      layer.set("legend_options", def.legend_options);
      layer.set("legend_ttoken", def.legend_ttoken);
      if(def.min_zoom && def.min_zoom !== null) {
        layer.set("minZoom", def.min_zoom);
      }
      if(def.max_zoom && def.max_zoom !== null) {
        layer.set("maxZoom", def.max_zoom);
      }
      // layer.set("timeseries", def.timeseries);

      if (def.parent_layer_code === null || def.parent_layer_code === undefined) {
        layers.push(layer);
      } else {
        const parentCode = def.parent_layer_code;
        const flatLayers: Array<OlBaseLayer> = layers ? flatDeep(layers, 3) : [];
        const parentLayer = flatLayers.find((x) => x.get("id") === "app-" + parentCode) as OlLayerGroup;
        if (parentLayer) {
          parentLayer.setLayers(new OlCollection([...parentLayer.getLayers().getArray(), layer]));
        }
      }
    }
  });

  return layers;
}

function createEPSG3857Grid() {
  // Kreiraj grid za EPSG:900913/EPSG:3857
  const maxZoom = 22
  const resolutions = new Array(maxZoom)
  const matrixIds = new Array(maxZoom)
  for (let z = 0; z <= maxZoom; z++) {
      resolutions[z] = 40075016 / 256 / Math.pow(2, z)
      matrixIds[z] = "EPSG:900913:" + z // Capabilities/Contents/TileMatrixSet/TileMatrix/ows:Identifier
  }
  const grid = new OlWMTSTileGrid({
      origin: [-20037508, 20037508], // Capabilities/Contents/TileMatrixSet/TileMatrix/TopLeftCorner
      resolutions: resolutions,
      matrixIds: matrixIds,
      extent: [-20037508.34, -20037508.34, 20037508.34, 20037508.34]
  })

  return grid;
}

export default mapService;
