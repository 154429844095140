import React from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


//Custom Components
import TableHeaderButton from "./TableHeaderButton";
import { ITableHeaderButtonProps } from "@/@types/ui/Table";

export interface ITableHeaderButtonToggleFilterProps extends ITableHeaderButtonProps {
  isFiltered: boolean;
}

function TableHeaderButtonToggleFilter(props: ITableHeaderButtonToggleFilterProps) {
  const { t } = useTranslation();
  const { isFiltered } = props;

  return (
    <div style={{maxWidth: "30vw", marginLeft: "1rem"}}>
      <TableHeaderButton variant="contained" startIcon={<ArrowBackIcon/>} {...props}>
        {isFiltered ? t("buttons.show-all") : t("buttons.show-filtered")}
      </TableHeaderButton>
    </div>
  );
}

export default TableHeaderButtonToggleFilter;
