import * as React from "react";
import { Fragment, FunctionComponent, ReactElement, ReactNode, SyntheticEvent, useContext } from "react";

//MUI
import Menu from "@mui/material/Menu";
import { PopoverProps } from "@mui/material/Popover";

//Material-UI Icons
import MenuIcon from "@mui/icons-material/Menu";

//Custom Components
import DialogToolbarButton from "@/ui/Dialog/DialogToolbarButton";
import UserContext from "@/components/UserContext/UserContext";

//Types
import { IDialogToolbarMenuButton } from "@/@types/ui/Dialog";
import { UserContextType } from "@/@types/context/UserContext";

const DialogToolbarMenuButton: FunctionComponent<IDialogToolbarMenuButton> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const [anchorEl, setAnchorEl] = React.useState<PopoverProps["anchorEl"]>(null);

  const { needPermission } = props;

  const handleClick = (evt: SyntheticEvent): void => {
    return setAnchorEl(evt.currentTarget);
  };

  const handleClose = (): void => {
    return setAnchorEl(null);
  };

  const hookAction = (action: () => void): void => {
    setAnchorEl(null);
    if (action) {
      action();
    }
  };

  const childrenWithProps: ReactNode | undefined | null = React.Children.map(props.children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child as ReactElement, {
        onClick: (evt: SyntheticEvent) => hookAction(child.props.onClick)
      });
    }
    return child as ReactElement;
  });

  return (
    <Fragment>
      {needPermission && !userContext.hasAnyPermission(needPermission) ? null : (
        <Fragment>
          <DialogToolbarButton ariaLabel="menu" ariaControls="menu" onClick={handleClick}>
            <MenuIcon />
          </DialogToolbarButton>
          <Menu
            id="dialogt-toolbar-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            sx={{
              ".MuiMenu-paper": {
                backgroundColor: "primary.main"
              },
              ".MuiMenu-list": {
                color: "common.black"
              }
            }}
          >
            {childrenWithProps}
          </Menu>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DialogToolbarMenuButton;
