import * as React from "react";
import { FunctionComponent, useState } from "react";

//Material UI
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";

//Custom components
import ThemeContext from "@/components/ThemeContext/ThemeContext";

//Types
import { IThemeProvider, Theme } from "@/@types/context/ThemeContext";

// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme as MuiTheme } from '@mui/material/styles';

// // ako iz nekog razloga vratimo paket @mui/styles, mozda ce trebati ovo ponovno otkomentirati
// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
//   interface DefaultTheme extends MuiTheme {}
// }

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#b7c800",
      dark: "#808c00",
      light: "#dbe37f"
    },
    secondary: { // @Piero for some reason this doesn' work TODO
      main: "#076BAD",
      dark: "#044a79",
      light: "#83b5d6",
    },
    background: {
      default: "#fafafa",
      paper: "#fafafa"
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color"
          }
        }
      }
    }
  }
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: lightTheme.palette.primary,
    secondary: lightTheme.palette.secondary,
    background: {
      default: "#424242",
      paper: "#424242"
    },
  },
  components: lightTheme.components
});

export const appHeaderTheme = createTheme({
  palette: {
    secondary: {
      main: grey[800]
    }
  }
})


const ThemeProvider: FunctionComponent<IThemeProvider> = (props) => {
  const THEME_LIGHT = "light";
  const THEME_DARK = "dark";

  const [theme, setTheme] = useState<Theme>(THEME_LIGHT);

  const switchTheme = (theme: Theme): void => {
    setTheme(theme);
  };

  const getMuiTheme = () => {
    if (theme === THEME_LIGHT) {
      return lightTheme;
    } else if (theme === THEME_DARK) {
      return darkTheme;
    } else {
      return lightTheme;
    }
  };

  const muiTheme = getMuiTheme();

  const providerValue = {
    theme,
    setTheme: switchTheme
  };

  return (
    <ThemeContext.Provider value={providerValue}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {props.children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

