import moment from "moment";
import numbro from "numbro";

import api from "./api/api";
import { IApi } from "@/@types/lib/api/api";
import { FieldAny, IFieldsCollection, IFormCollection, IModel, IViewCollection, IField, IForm, IView, IFieldOverride, IFieldID, FieldType, IFieldValidation, IPickerItems, PickerItemValue } from "../@types/models/model";
import { FieldValue, IApiResponse, IApiResponseFailure, IApiResponseSuccessInsert, Record } from "../@types/lib/api/api";
import { DataResponse, DataSingleResponse, DCDeleteResponse, DCFieldValue, DCInsertResponse, DCInsertResponseSuccess, DCRecord, DCResponseFail, DCUpdateResponse, IDataController } from "@/@types/lib/dataController";
import { PickerItem } from "@/@types/controls/controls";
import GeoJSON from "ol/format/GeoJSON";
import WKT from "ol/format/WKT";
import { Feature } from "ol";
import { Geometry } from "ol/geom";

class dataController implements IDataController {

  apiPath: string;
  filename: string | null;
  listViews: IViewCollection;
  forms: IFormCollection;
  fields: IFieldsCollection;

  fieldId: IFieldID;

  api: IApi;

  constructor(model: IModel, customPath?: string) {
    this.apiPath = customPath ? customPath : model.apiPath;
    this.filename = model.exportFileName ? model.exportFileName : null;
    this.listViews = model.listViews;
    this.forms = model.forms;
    this.fields = model.fields;

    this.fieldId = this.fields.find(attr => {
      return attr.idattr === true;
    }) as IFieldID;

    this.api = new api();
  }

  path_combine(path: string, recordId: number): string {
    const pathEndsWithSlash = path.charAt(path.length - 1) === "/";
    return path + (pathEndsWithSlash ? "" : "/") + recordId;
  }

  // CustomGetAction(url: string) : Promise<IApiResponse> {
  //   return this.api.Call(url, "get");
  // }

  // CustomPutAction(url: string, data: Record): Promise<IApiResponse> {
  //   const putData = this.prepareSendingData(data);
  //   return this.api.Call(url, "put", putData);
  // }

  // CustomPostAction(url: string, data: Record | Array<Record>, multi = false): Promise<IApiResponse> {
  //   let postData = null;

  //   if (multi && Array.isArray(data)) {
  //     postData = data.map(d => this.prepareSendingData(d));
  //   } else {
  //     postData = this.prepareSendingData(data as Record);
  //   }

  //   return this.api.Call(url, "post", postData);
  // }

  // CustomPatchAction(url: string, data: Record): Promise<IApiResponse> {
  //   const patchData = this.prepareSendingData(data);
  //   return this.api.Call(url, "patch", patchData);
  // }

  GetData(customPath: string | null = null): Promise<DataResponse> {
    const path = customPath !== null ? customPath : this.apiPath;
    return this.api.Call(path, "get")
      .then(resp => {
        if (Array.isArray(resp.data)) {
          const data = resp.data.map(record => this.prepareReturnData(record));
          const success = resp.success;
          return { success: success, data: data };
        } else if (resp.data === null) {
          return { success: resp.success, data: [] }
        } else {
          return { success: resp.success, data: this.prepareReturnData(resp.data as Record) };
        }
      });
  }

  GetDataSingle(id: number, customPath: string | null = null): Promise<DataSingleResponse> {
    const path = customPath !== null ? customPath : this.path_combine(this.apiPath, id);

    return this.api.Call(path, "get")
      .then(resp => {        
        
        if (resp.success) {
          // array = receiving photos for a comment
          if (Array.isArray(resp.data)) {
            if (resp.data.length > 0) {
              const data = resp.data.map(record => this.prepareReturnData(record));
              const success = resp.success;
              return { success: success, data: data };
            } else {
              return Promise.reject({ sucess: false, error: resp.error });
            }
          // the usual situation
          } else {
            if(resp.data !== null && resp.data !== undefined) {
              const returnData = this.prepareReturnData(resp.data as Record);
              return Promise.resolve({ success: true, data: returnData });
            } else {
              return Promise.reject({ sucess: false });
            }
          }
        } else {
          return Promise.reject({ sucess: false, error: resp.error });
        }
      });
  }

  InsertRecord(record: DCRecord, apiPath: string | null = null): Promise<DCInsertResponse> {
    let insertData = this.prepareSendingData(record);
    const path = apiPath !== null ? apiPath : this.apiPath;
    return this.api
      .Call(path, "post", insertData)
      .then(resp => {
        if (resp && resp.success) {
          const response = resp as IApiResponseSuccessInsert;
          let newId = response.data.id;
          if (newId) {
            return Promise.resolve({ success: true, data: response.data, id: newId } as DCInsertResponseSuccess);
          } else {
            return Promise.resolve({ success: true, data: response.data, id: 0 } as DCInsertResponseSuccess);
          }
        } else {
          const response = resp as IApiResponseFailure;
          return Promise.reject({ success: false, error: response.error.toString() } as DCResponseFail);
        }
      })
      .catch(err => {
        return Promise.reject({success: false, error: err.error} as DCResponseFail);
      });
  }

  UpdateRecord(recordId: number, record: DCRecord, apiPath: string | null = null): Promise<DCUpdateResponse> {
    if (!this.fieldId) {
      this.debugLog(
        "Attempt to update record without id attribute in the model!"
      );
      return Promise.reject("Unknown ID field!");
    }

    if (!recordId) {
      this.debugLog("Attempt to update record withhout recordId!");
      return Promise.reject("Unknown record Id!");
    }

    let updateData = this.prepareSendingData(record);
    const currPath = apiPath !== null ? apiPath : this.apiPath;
    const path = this.path_combine(currPath, recordId);
    return this.api
      .Call(path, "put", updateData)
      .then(response => {
        if (response.success) {
          return Promise.resolve({ success: true, data: response.data });
        } else {
          return Promise.reject({ success: false, error: response.error });
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  DeleteRecord(recordId: number, apiPath: string | null = null): Promise<DCDeleteResponse> {
    if (!this.fieldId) {
      this.debugLog(
        "Attempt to delete record without id attribute in the model!"
      );
      return Promise.reject("Unknown ID field!");
    }

    if (!recordId) {
      this.debugLog("Attempt to delete record withhout recordId!");
      return Promise.reject("Unknown record Id!");
    }

    let deleteData: Record = {};
    const sourceKey = this.fieldId.source as keyof Record;
    deleteData[sourceKey] = recordId;
    const currPath = apiPath !== null ? apiPath : this.apiPath;
    const path = this.path_combine(currPath, recordId);
    return this.api
      .Call(path, "delete", deleteData)
      .then(response => {
        if (response && response.success) {
          // const response = resp as IApiResponseSuccessDelete;
          return Promise.resolve({ success: true, data: response.data });
        } else {
          // const response = resp as IApiResponseFailure;
          return Promise.reject({ success: false, error: response.error });
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  debugLog(msg: string) {
    console.warn(msg);
  }

  prepareReturnData(record: Record): DCRecord {
    let dcr: DCRecord = {};

    //we pass everything (filter keys also!)
    Object.keys(record).forEach(k => {
      const field = this.getField(k);
      const key = k as keyof Record;
      const dkey = k as keyof DCRecord;
      if (field) {
        switch (field.type) {
          case "date":
          case "datetime":
            if (record[key]) {
              dcr[dkey] = moment.utc(record[key] as string);
            }
            break;
          case "dokumenti":
            if (!Array.isArray(record[key])) {
              dcr[dkey] = [record[key] as DCRecord];
            }
          // case "table":
          //   let arr = data[key];
          //   let parseArr = [];
          //   arr.forEach(rec => {
          //     parseArr.push(this.prepareReturnData(rec));
          //   })
          //   data[key] = parseArr;
            break;
          case "picker":
            if (record[key] !== null) {
              if (field.formatting) {
                switch (field.formatting) {
                  case "dhm":
                    const secondsInDay = 24 * 60 * 60;
                    const time = moment().startOf("day").seconds(record[key] as number * 60 * 60);

                    const timeFormatted = (record[key] as number) < secondsInDay
                      ? time.format("H") + " h " + time.format("m") + " min"
                      : Math.floor(record[key] as number / secondsInDay) + " d " + time.format("H") + " h " + time.format("m") + " min";

                    dcr[dkey] = { value: record[key] as PickerItemValue, label: timeFormatted } as PickerItem;
                    break;
                  case "km":
                    const kmFormatted: PickerItem = { value: record[key] as PickerItemValue, label: Math.ceil(record[key] as number).toString() + " km" };
                    dcr[dkey] = kmFormatted;
                    break;
                }
              } else {
                dcr[dkey] = record[key] as PickerItemValue | PickerItemValue[];
              }
            }
            break;
          case "radio":
            if (record[key] !== null) {
              dcr[dkey] = record[key] as PickerItemValue;
            }
            break;
          case "checkbox":
            if (record[key] !== null) {
              dcr[dkey] = record[key] as PickerItemValue | PickerItemValue[];
            }
            break;
          case "wkt":
            if (record[key] !== null) {
              const format = new WKT()
              const feature = format.readFeature(record[key]);
              feature.setId(record.id as string);
              // @ts-ignore
              dcr[dkey] = feature;
            }
            break;
          default:
            dcr[dkey] = record[key] as DCFieldValue;
            break;
        }
      }
    });

    return dcr;
  }

  prepareSendingData(record: DCRecord): Record {
    let data: Record = Object.assign({}, record);

    const subModelKey = "value";

    //we pass everything (filter keys also!)
    Object.keys(data).forEach(k => {
      const field = this.getField(k);
      const key = k as keyof Record;
      if (field) {
        switch (field.type) {
          case "date":
            data[key] = data[key] ? moment(data[key] as string).format("YYYY-MM-DD") : null;
            break;
          case "datetime":
            data[key] = data[key] ? moment.utc(data[key] as string).toISOString(true) : null;
            break;
          case "picker":
          case "radio":
            const dk = data[key];
            if (typeof dk === "object" && dk !== null && dk.hasOwnProperty(subModelKey)) {
              data[key] = (dk as Record)[subModelKey];

            } else if (field.multi) {
              if (Array.isArray(data[key])) {
                data[key] = (data[key] as Array<Record>).map(d => d[subModelKey] ? d[subModelKey] : d);
              }
            }
            break;
          case "checkbox":
            const dd = data[key];
            if (Array.isArray(dd) && dd.length > 0) {
              const dataArray: Array<FieldValue> = [];
              let dataInt = 0;
              if (field.flags) {
                dd.forEach(d => {
                  dataInt += d[subModelKey] as number;
                });
                data[key] = dataInt;
              } else {
                dd.forEach(d => {
                  if (d.hasOwnProperty(subModelKey)) {
                    dataArray.push(d[subModelKey]);
                  } else {
                    dataArray.push(d);
                  }
                });
                data[key] = dataArray;
              }
            }
            break;
          case "numeric":
            if (data[key] !== null && typeof data[key] === "string") {
              data[key] = parseFloat((data[key] as string).replace(",", "."));
            }
            break;
          case "currency":
            if (data[key] !== null) {
              const parsedValue = numbro(data[key]);
              numbro.setLanguage("en-US");
              data[key] = numbro.unformat(parsedValue.format());
              numbro.setLanguage("hr-HR");
            }
            break;
          case "images":
          case "dokumenti":
            if (field.object) {
              data[key] = (data[key] as Array<Record>)[0];
            }
            break;
          case "wkt":
              if (data[key] !== null) {
                const writer = new GeoJSON();
                const geojson = writer.writeFeatureObject(data[key] as Feature<Geometry>).geometry
                data[key] = data[key] ? geojson : null;
              }
              break;
          // case "table":
          //   let arr = data[key];
          //   let parseArr = [];
          //   arr.forEach(rec => {
          //     parseArr.push(this.prepareSendingData(rec));
          //   })
          //   data[key] = parseArr;
          //   break;
        }
      }
    });

    // ensure we send id
    // if (this.fieldId) {
    //   data[this.fieldId.source] = record[this.fieldId.source]; //ensure we have recordId
    // }

    return data;
  }

  getField(name: string): FieldAny {
    const field = this.fields.find(f => f.source === name);
    return field ? JSON.parse(JSON.stringify(field)) : null; //clone
  }

  getFields(fieldNames: Array<string>): IFieldsCollection {
    const fields = fieldNames
      ? fieldNames.map(name => this.fields.find(f => f.source === name))
      : [];
    return JSON.parse(JSON.stringify(fields)); //clone
  }

  //Helpers

  overrideFieldConfigurations(baseFields: IFieldsCollection, overrideFields: Array<string | IFieldOverride>): IFieldsCollection {
    overrideFields.forEach(f => {
      if (typeof f === "object") {
        Object.keys(f).filter(x => x !== "source").forEach(key => {
          let baseField: IField = baseFields.find(x => x.source == f.source) as IField;
          const fIndexKey = key as keyof IFieldOverride;
          const baseFieldIndexKey = key as keyof IField;
          if (baseField ) {
            if (f[fIndexKey] !== null && f[fIndexKey] !== undefined) {
              switch (fIndexKey) {
                case "title":
                  baseField.title = f[fIndexKey] as string;
                  break;
                case "ttoken":
                  baseField.ttoken = f[fIndexKey] as string;
                  break;
                case "tooltip":
                  baseField.tooltip = f[fIndexKey] as string;
                  break;
                case "type":
                  baseField.type = f[fIndexKey] as FieldType;
                  break;
                case "readonly":
                  baseField.readonly = f[fIndexKey] as boolean;
                  break;
                case "validation":
                  baseField.validation = f[fIndexKey] as IFieldValidation;
                  break;
                case "items":
                  baseField.items = f[fIndexKey] as IPickerItems;
                  break;
              }

              // baseField[fIndexKey] = f[fIndexKey];
            } else {
              delete baseField[baseFieldIndexKey];
            }
          }
        });
      }
    });
    return baseFields;
  }

  getSource(): string {
    return this.apiPath !== null && this.apiPath !== undefined && this.apiPath.length ?
      this.apiPath
      : "undefined_source";
  }

  //View

  getView(viewId: string): IView | null {
    if (viewId !== undefined && this.listViews.hasOwnProperty(viewId)) {
      return this.listViews[viewId];
    } else if (this.listViews.hasOwnProperty("default")) {
      return this.listViews["default"];
    } else {
      this.logError(this.listViews, "default");
      return null;
    }
  }

  getViewFieldsNames(viewId: string): Array<string> {
    const view = this.getView(viewId);
    if (view && view.fields && Array.isArray(view.fields)) {
      return view.fields.map(x => (typeof x === "object" ? x.source : x));
    } else {
      this.logError(view, "fields");
      return [];
    }
  }

  getViewFields(viewId: string): IFieldsCollection {
    const view = this.getView(viewId);
    if (view === null) {
      this.logError(view, "");
      return [];
    }

    const fieldNames = this.getViewFieldsNames(viewId);
    let baseFields = this.getFields(fieldNames);
    baseFields = this.overrideFieldConfigurations(baseFields, view.fields);

    return baseFields;
  }

  getHiddenViewFieldsNames(viewId: string): Array<string> {
    const view = this.getView(viewId);
    if (view !== null && view.hasOwnProperty("hidden") && Array.isArray(view.hidden)) {
      return ["id"].concat(view.hidden);
    } else {
      return ["id"];
    }
  }

  //Form

  getForm(formId: string): IForm | null {
    if (this.forms === undefined) {
      return null;
    } else if (formId !== undefined && this.forms[formId]) {
      return this.forms[formId];
    } else if (this.forms.default) {
      return this.forms.default;
    } else {
      this.logError(this.forms, "default");
      return null;
    }
  }

  getFormFieldsNames(formId: string): Array<string> {
    const form = this.getForm(formId);
    if (form && form.fields && Array.isArray(form.fields)) {
      return form.fields.map(x => (typeof x === "object" ? x.source : x));
    } else {
      this.logError(form, "fields");
      return [];
    }
  }

  getFormFields(formId: string): IFieldsCollection {
    const form = this.getForm(formId);
    if (form === null) {
      this.logError(form, "");
      return [] as IFieldsCollection;
    }
    const fieldNames = this.getFormFieldsNames(formId);
    let baseFields = this.getFields(fieldNames);

    baseFields = this.overrideFieldConfigurations(baseFields, form.fields);

    return baseFields;
  }

  //Validator

  getValidator(formId: string) {
    const form = this.getForm(formId);
    if (form && form.validator) {
      return form.validator;
    } else {
      // this.logError(form, "validator");
      return null;
    }
  }

  //Error handling

  /**
   * @description Error logger for objects
   * @name logError
   * @params
   *   obj: object
   *   key: string (optional)
   *   type: string (optional)
   * @returnVal
   *  void
   */
  logError(obj: object | null, key = "") {

    const indexKey = key as keyof object;
    if (obj === null || obj === undefined) {
      console.error("Object", obj, "is null or undefined!");
    } else if (!obj.hasOwnProperty(key)) {
      console.error("Object", obj, "doesn't contain the key:", key);
    } else if (obj[indexKey] === null || obj[indexKey] === undefined) {
      console.error("Object", obj, "value under key:", key, "is", obj[indexKey]);
    } else {
      console.error("Unknown error!", obj, key);
    }
  }
}

export default dataController;
