import React from "react";

//MUI
import Box from "@mui/material/Box";

//Types
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: any
}

const AttributeTableTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

export default AttributeTableTabPanel;
