import Control from "ol/control/Control";
import { Options } from "ol/control/Control";

export default class AttributeTableClass extends Control {

  constructor(opt_options: Options) {

    super(opt_options);

    var options: Options = {};
    Object.assign(options, null, opt_options);

    var element = document.createElement("div");
    element.id = "attributetable-control";
    element.className = "attributetable";
    if (options.element) {
       element.appendChild(options.element);
    }
  }
}

//@ts-ignore TODO: Property 'ol' does not exist on type 'Window & typeof globalThis'
if (window.ol && window.ol.control) {
  //@ts-ignore TODO: Property 'ol' does not exist on type 'Window & typeof globalThis'
  window.ol.control.Status = AttributeTableClass;
}
