import React, { useContext, useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

// d.ts
import { IDataController, DCRecord } from "@/@types/lib/dataController"

// Material UI Core
import Tooltip from "@mui/material/Tooltip";

// Custom Components
import ModelTable from "@/ui/Table/ModelTable";
import DialogContext from "@/ui/DialogContext/DialogContext";

import dataController from "@/lib/dataController";

// Types
import { DialogContextType } from "@/@types/ui/DialogContext";
import { IHeaderData, IImageData } from "@/@types/views/KomunalniObveznici";
import { IModel } from "@/@types/models/model";

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

interface IDataCentricSecondaryTable {
  lokacijaId: number;
  selectedObjektId: number;
  onObjektSelect: (id: number) => void;
  primaryRecord: DCRecord | null;
  setImgData: (record: IImageData) => void;
  onLoad?: (records: Array<DCRecord>) => void;

  model: IModel;
  viewName?: string;
  titleToken: string;

  baseRecordPath: string;
  childRecordRelativePath: string;

  disableControls?: boolean;
}

type MemoColorType = {
  [key: number]: string;
}

function DataCentricSecondaryTable(props: IDataCentricSecondaryTable) {
  let prevRecId = 0;
  let colors = ["#fafafa", "#dadada"];
  let colI = 0;
  const memoColor: MemoColorType = {};

  const dialogContext = useContext<DialogContextType>(DialogContext);
  const { t } = useTranslation();
  const { lokacijaId, selectedObjektId, onObjektSelect, primaryRecord, setImgData, onLoad } = props;
  const { model, viewName, titleToken, baseRecordPath, childRecordRelativePath, disableControls } = props;

  const [records, setRecords] = useState<DCRecord[]>([]);
  const [displayRecords, setDisplayRecords] = useState<DCRecord[]>([]);

  const dc: IDataController = new dataController(model);

  useEffect(() => {
    setRecords([]);
    if (lokacijaId !== 0) {
      refreshRecords();
    }
  }, [lokacijaId]);

  useEffect(() => {
    if (records.length && lokacijaId) {
      setDisplayRecords(records);
    } else {
      setDisplayRecords([]);
    }
  }, [records, lokacijaId])

  const refreshRecords = () => {
    const path = `${baseRecordPath}/${lokacijaId}/${childRecordRelativePath}`;
    dc.GetData(path).then((resp) => {
      if (resp.success && Array.isArray(resp.data)) {
        const objs: IImageData = {};
        resp.data.forEach(d => {
          const id = d.id as number;
          if (id && (id) in objs) {
            if (d.kat) {
              (objs[id]).kat = Math.max(objs[id].kat, (d.kat as number));
            }
            if (d.ukupno) {
              (objs[id]).ukupno += (d.ukupno as number);
            }
          } else if (d.id) {
            objs[id] = {
              kat: d.kat ? d.kat as number : 0,
              ukupno: d.ukupno ? d.ukupno as number : 0
            }
          }
        });
        setImgData(objs);
        const rs = resp.data.map(r => {
          if (r && r.id) {
            r.obj_id = (r.id as number) % 1_000_000;
          }
          return r;
        });
        setRecords(rs);
        if (onLoad) {
          onLoad(rs);
        }
      };
    });
  };

  function getTooltip(): React.ReactFragment {
    return primaryRecord !== null ?
      <Fragment>
        <div>{`${t('komunalni_obveznici.tt.objekti')}: ${primaryRecord.lok}`}</div>
        <div>{`${t('komunalni_obveznici.tt.adresa')}: ${primaryRecord.adrobj}`}</div>
        <div>{`${t('komunalni_obveznici.tt.obveznik')}: ${primaryRecord.obv}`}</div>
      </Fragment>
      : '';
  }

  function getSubTitle(): React.ReactNode {
    return lokacijaId !== 0 ?
      <Tooltip title={getTooltip()} TransitionProps={{ style: { maxWidth: "500px" } }}>
        <div style={{ display: "inline-block" }}>
          {primaryRecord ? primaryRecord.adrobj : ""}
          {primaryRecord && primaryRecord.kc ? `, ${t("komunalni_obveznici.kc")}: ${primaryRecord.kc}` : ""}
          {primaryRecord && primaryRecord.ko ? `, ${t("komunalni_obveznici.ko")}: ${primaryRecord.ko}` : ""}
          </div>
      </Tooltip>
      : null
  }

  function getRowTooltipTitle(original: { [key: string]: any }): React.ReactNode[] {
    const fields = dc.getViewFields("tooltip");
    return fields.map((f, i) => {
      if (!(f.source in original) || f.source === "kat") {
        return null;
      }

      let ttTitle = `${t(f.tooltip ?? "")}: ${original[f.source]}`;
      if (f.source === 'obj_id') {
        const fKat = fields.find(ff => ff.source === "kat");
        if (fKat && fKat.tooltip) {
          ttTitle += ` (${t(fKat.tooltip ?? '')} ${fKat.source in original ? original[fKat.source] : ""})`;
        }
      }
      return <div key={i}>{ttTitle}</div>
    })
  }

  function getRowTooltip(children: React.ReactNode, original?: { [key: string]: any }): React.ReactNode {
    return (
      <Tooltip
        placement="bottom-start"
        title={getRowTooltipTitle(original ? original : {})}
      >
        {children as React.ReactElement}
      </Tooltip>
    )
  }

  const getRowStyle = (id: number) => {
    if (memoColor[id]) 
      return { backgroundColor: memoColor[id] };
    if (id !== prevRecId) {
      colI += 1;
    }
    prevRecId = id;
    memoColor[id] = colors[colI % 2];
    return { backgroundColor: memoColor[id] };
  }
  // type BackgroundColorObj = { backgroundColor: string };

  return (
    <ModelTable
      title={t(titleToken)}
      subTitle={getSubTitle()}
      //RowTooltip={({ children, original }) => getRowTooltip(children, original)}
      records={displayRecords}
      initialPageSize={records.length}
      dc={dc}
      viewName={viewName}
      allowSelection="one"
      allowFilter={false}
      allowExport={false}
      allowAdd={false}
      allowEdit={false}
      allowSearchBar={false}
      disableControls={disableControls !== undefined ? disableControls : true}
      onRowAction={() => {}}
      allowRowAction={() => { return true;}}
      rowSelectedCustom={selectedObjektId}
      onRowClick={(id:number) => onObjektSelect(id)}
      trackTableState={false}
      unselectRow={true}
      getRowStyle={getRowStyle}
    />
  );
}

export default DataCentricSecondaryTable;
