import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import appValidator from '@/lib/appValidator';
import confirmDialog from '@/lib/confirmDialog';
import dataControllerSubModel from "@/lib/dataControllerSubModel";

import radio from "@/models/submodels/radio";
import checkbox from "@/models/submodels/checkbox";
import picker from "@/models/submodels/picker";

import SnackbarContext from "UI/SnackbarContext/SnackbarContext";

import { authService } from "@/services/authService";

// Types
import { AuthFormControllerProps, PickerDataControllers, ServerErrorPromise, DoLoginResponse, DoLoginResponseSuccess, DoLoginResponseFail, DoRegisterResponseFail, DoRegisterResponseSuccess, DoActivateResponseFail, DoActivateResponseSuccess, DoRequestResetPasswordSuccess, DoRequestResetPasswordFail, DoChangePasswordResponseSuccess, DoChangePasswordResponseFail, DoResetPasswordResponseSuccess, DoResetPasswordResponseFail } from "@/@types/components/authFormController";
import { FieldAny, IFieldPickerModel, IFieldsCollection } from "@/@types/models/model";
import { DCFieldValue, DCRecord } from "@/@types/lib/dataController";
import { ConfirmResult } from "@/@types/lib/confirmDialog";
import { RecordValidation, ValidateFunction } from "@/@types/lib/appValidator";
import { DoActivateResponse, DoRequestResetPassword, DoChangePasswordResponse, DoRegisterResponse, DoResetPasswordResponse } from "../@types/components/authFormController";
import { LoginData } from "@/@types/lib/api/api";
import { ActivateData } from "@/@types/services/authService";


function useAuthFormController(props: AuthFormControllerProps) {

  const [ validation, setValidation] = useState<RecordValidation>({});
  const [ validated, setValidated] = useState(false);
  const [ subModels, setSubModels] = useState<PickerDataControllers>({});
  const [ dataChanged, setDataChanged ] = useState(false);
  const [ record, setRecord] = useState<DCRecord>({});
  const [ isLoading, setIsLoading] = useState(false);

  const [ lastChangedField, setLastChangedField] = useState<string>();
  const [ lastChangeAt, setLastChangeAt] = useState<Date>();

  const [ originalRecord, setOriginalRecord] = useState<DCRecord>({});

  const { t } = useTranslation();

  const validator = new appValidator(t);

  const { dc, form, mode, fieldNames, initialRecord, controllerForm } = props;

  const formId = controllerForm ? controllerForm : (form ? form : mode);
  const fields: IFieldsCollection = dc.getFormFields(formId);

  let mounted = false;

  useEffect(() => {
    mounted = true;

    if (initialRecord !== undefined) {
      const recordCopy = Object.assign({}, initialRecord);
      const originalRecord = Object.assign({}, initialRecord);

      setRecord(recordCopy);
      setOriginalRecord(originalRecord);

    }

    return () => { mounted = false; }
  }, [])

  useEffect(() => {
    if (validated && lastChangedField !== undefined) {
      validateField(lastChangedField);
    }
    setDataChanged(checkIfChanged(record));

  }, [lastChangeAt, lastChangedField])

  const checkIfChanged = (record: DCRecord) => {
    const changed = validator.checkIfRecordChanged(record, originalRecord);
    return changed;
  }

  const validateField = (source: string) => {
    const field = fields.find(f => f.source === source);

    if (field !== undefined) {
      const fieldValidation = validator.validateField(record, field);

      setValidation((prevState) => ({
        ...prevState,
        [source]: fieldValidation
      }))
    }
  }

  const getValidator = () => {

    const formId = form ? form : mode;
    const validateFunc = dc.getValidator(formId);
    if (validateFunc !== null && validateFunc !== 't') {
      if (validator[validateFunc] !== undefined && typeof validator[validateFunc] === 'function') {
        return validator[validateFunc];
      } /*else {
        // return (record: any, t: any) => ({});
      }*/
    } /*else {
      // return (record: any, t: any) => ({});
    }*/
    return null;
  }

  const validate = () => {
    //model validation
    let validation = validator.validateModel(record, fields);

    //custom validation
    const validatorFn = getValidator();
    const customValidation = validatorFn !== null ? validatorFn(record, t) : {};

    const finalValidation = validator.mergeValidation(validation, customValidation);
    const isValid = validator.checkIfValid(finalValidation);

    setValidation(finalValidation);
    setValidated(true);

    return isValid;
  }

  const serverErrorPromise = (serverValidation: RecordValidation): Promise<ServerErrorPromise> => {
    setValidation(serverValidation);
    return Promise.reject({
      success: false,
      validationPass: false,
      validation: serverValidation
    });
  }

  // *** confirmations ***

  const confirmClose = (): Promise<ConfirmResult> => {

    const title = t("cdialogs.changes");
    const text = t("cdialogs.want_to_save");
    const type = "warning";
    const confirmButtonText = t("cdialogs.yes_save_changes");
    const cancelButtonText = t("cdialogs.no_just_close");

    return confirmDialog(
      title,
      text,
      type,
      confirmButtonText,
      cancelButtonText
    );
  }

  const confirmRegister = (): Promise<ConfirmResult> => {
    return Promise.resolve({ confirmed: true });

    // const title = t("cdialogs.register");
    // const text = t("cdialogs.please_confirm");
    // const type = "warning";
    // const confirmButtonText = t("cdialogs.register");
    // const cancelButtonText = t("cdialogs.cancel");

    // return confirmDialog(
    //   title,
    //   text,
    //   type,
    //   confirmButtonText,
    //   cancelButtonText
    // );
  }

  // *** Export functions ***

  const onFieldChange = (value: DCFieldValue, source: string) => {

    setRecord((prevState) => ({
      ...prevState,
      [source]: value
    }));

    setLastChangedField(source);
    setLastChangeAt(new Date());
  }

  const doClose = () => {
    if (dataChanged) {
      return confirmClose().then(result => {
        if (result.canceled) {
          return Promise.resolve({ success: false, canceled: true });
        } else if (result.confirmed) {
          return Promise.resolve({ success: false, shouldsave: true });
        } else {
          return Promise.resolve({ success: true });
        }
      });
    } else {
      return Promise.resolve({ success: true });
    }
  }

  const doLogin = (): Promise<DoLoginResponse> => {
    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation: validation
      });
    }

    setIsLoading(true);
    return authService
      .login(record.username as string, record.password as string)
      .then((data): Promise<DoLoginResponse> => {
        if ((data as LoginData).auth_token) {
          return Promise.resolve({ success: true, ...data } as DoLoginResponseSuccess);
        } else {
          return serverErrorPromise({
            username: {
              valid: false,
              msg: "Neispravna e-mail adresa ili lozinka"
            },
            password: {
              valid: false,
              msg: "Neispravna e-mail adresa ili lozinka"
            }
          }) as Promise<DoLoginResponseFail>;
        }
      })
      .catch((data): Promise<DoLoginResponseFail> => {
        switch (data.errorCode) {
          case 407:
            return serverErrorPromise({
              email: {
                valid: false,
                msg: "Neispravna e-mail adresa ili lozinka."
              },
              password: {
                valid: false,
                msg: "Neispravna e-mail adresa ili lozinka"
              }
            }) as Promise<DoLoginResponseFail>;
          default:
            return Promise.reject({ success: false, error: "Unknwon error"} as DoLoginResponseFail)
        }
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });
  }

  const doRegister = (): Promise<DoRegisterResponse> =>{

    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation: validation
      } as DoRegisterResponseFail);
    }

    return confirmRegister().then((result): Promise<DoRegisterResponse> => {
      if (result.canceled || result.confirmed == false) {
        return Promise.reject({ success: false, canceled: true } as DoRegisterResponseFail);
      } else if (result.confirmed) {
        setIsLoading(true);
        return authService
          .register(record)
          .then(data => {
            return Promise.resolve({ success: true } as DoRegisterResponseSuccess);
          })
          .catch((data): Promise<DoRegisterResponseFail> => {
            switch (data.errorCode) {
              case 401:
                return serverErrorPromise({
                  email: {
                    valid: false,
                    msg:
                      "Korisnik s navedenom e-mail adresom je već registriran u sustavu."
                  }
                }) as Promise<DoRegisterResponseFail>;
              case 402:
                return serverErrorPromise({
                  oib: {
                    valid: false,
                    msg:
                      "Korisnik s navedenim OIB-om je već registriran u sustavu."
                  }
                }) as Promise<DoRegisterResponseFail>;
            }
            return Promise.reject({
              success: false,
              error: data.error as string,
              errorCode: data.errorCode as number
            } as DoRegisterResponseFail);
          })
          .finally(() => {
            if (mounted) {
              setIsLoading(false);
            }
          });
      } else if (result.confirmed == false) {
        return Promise.resolve({ success: false, canceled: true } as DoRegisterResponseFail);
      } else {
        return Promise.resolve({ success: false } as DoRegisterResponseFail);
      }
    });
  }

  const doActivate = (): Promise<DoActivateResponse> => {

    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation: validation
      });
    }

    setIsLoading(true);
    return authService
      .activate(record)
      .then((data): Promise<DoActivateResponse> => {
        if ((data as ActivateData).hasOwnProperty("auth_token")) {
          return Promise.resolve({ success: true, ...data } as DoActivateResponseSuccess);
        } else {
          return Promise.reject({ success: false, error: "Unknown error"} as DoActivateResponseFail);
        }
      })
      .catch((data): Promise<DoActivateResponseFail> => {
        switch (data.errorCode) {
          case 409:
            return serverErrorPromise({
              password: { valid: false, msg: "Lozinka nije ispravna." }
            });
        }
        return Promise.resolve({
          success: false,
          error: data.error,
          errorCode: data.errorCode
        } as DoActivateResponseFail)
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });
  }

  const doSendPasswordResetRequest = (): Promise<DoRequestResetPassword> => {

    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation: validation
      });
    }

    setIsLoading(true);
    return authService
      .requestResetPassword(record)
      .then((): Promise<DoRequestResetPasswordSuccess> => {
        return Promise.resolve({ success: true } as DoRequestResetPasswordSuccess);
      })
      .catch((): Promise<DoRequestResetPasswordFail> => {
        return Promise.reject({ success: false } as DoRequestResetPasswordFail);
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });
  }

  const doResetPassword = (): Promise<DoResetPasswordResponse> => {

    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation: validation
      });
    }

    setIsLoading(true);
    return authService
      .resetPassword(record)
      .then((data) => {
        return Promise.resolve({ success: true, user: data } as DoResetPasswordResponseSuccess);
      })
      .catch(() => {
        return Promise.reject({ success: false, validationPass: true } as DoResetPasswordResponseFail);
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });
  }

  const doChangePassword = (): Promise<DoChangePasswordResponse> =>{

    const isValid = validate();
    if (!isValid) {
      return Promise.resolve({
        success: false,
        validationPass: false,
        validation: validation
      });
    }

    setIsLoading(true);
    return authService
      .changePassword(record)
      .then((): Promise<DoChangePasswordResponseSuccess> => {
        return Promise.resolve({ success: true });
      })
      .catch((data): Promise<DoChangePasswordResponseFail> => {
        switch (data.errorCode) {
          case 409:
            return serverErrorPromise({
              password: { valid: false, msg: "Lozinka nije ispravna." }
            });
        }
        return Promise.resolve({
          success: false,
          validationPass: true,
          error: data.error,
          errorCode: data.errorCode
        });
      })
      .finally(() => {
        if (mounted) {
          setIsLoading(false);
        }
      });
  }


  return {
    validation,
    validated,
    subModels,
    dataChanged,
    fields,
    record,
    isLoading,

    onFieldChange,

    doClose,
    doLogin,
    doRegister,
    doActivate,
    doChangePassword,
    doResetPassword,
    doSendPasswordResetRequest
  }
}

export default useAuthFormController;