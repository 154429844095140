import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

//MUI Icons
import AppsIcon from "@mui/icons-material/Apps";

//Custom Components
import UserContext from "@/components/UserContext/UserContext";
import HeaderMenuIconButton from "@/ui/Header/HeaderMenuIconButton";
import HeaderMenuItem from "@/ui/Header/HeaderMenuItem";
import { Permissions } from "@/lib/permissions";

//Types
import { IModuleMenu } from "@/@types/ui/Header";
import { UserContextType } from "@/@types/context/UserContext";

const ModuleMenu: FunctionComponent<IModuleMenu> = (props) => {
  const userContext = useContext(UserContext) as UserContextType;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { needPermission } = props;

  const handleSelectAdministration = (): void => {
    navigate("/admin");
  };
  const handleSelectGIS = (): void => {
    navigate("/");
  };

  return needPermission && !userContext.hasAnyPermission(needPermission) ? null : (
    <HeaderMenuIconButton icon={<AppsIcon />} desc="modules">
      <HeaderMenuItem
        label={t("menu.administration")}
        onClick={handleSelectAdministration}
        needPermission={[Permissions.Administration]}
      />
      <HeaderMenuItem label={t("menu.gis")} onClick={handleSelectGIS} />
    </HeaderMenuIconButton>
  );
};

export default ModuleMenu;
