import React from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Box from '@mui/material/Box';

//Custom Components
import AdminPage from "UI/AppPage/AdminPage";
import Dashboard from "Views/HomePage/Dashboard";

function AdminHomePage() {
  const { t } = useTranslation();

  return (
    <AdminPage>
      <Box m={2}>
        <Dashboard />
      </Box>
    </AdminPage>
  );
}

export default AdminHomePage;
