import React, { useState, useEffect, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";

import Map from "@/components/Map/Map";

// OpenLayers
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection, transformWithProjections } from "ol/proj";
import OlMapBrowserEvent from "ol/MapBrowserEvent";
import OlBaseLayer from "ol/layer/Base";
import OlLayerGroup from "ol/layer/Group";
import OlCollection from "ol/Collection";
import OlSourceVector from "ol/source/Vector";
import Geometry from "ol/geom/Geometry";
import OlFormatWKT from "ol/format/WKT";
import OlFeature from "ol/Feature";

// Custom
import {
  ButtonControl,
  Controls,
  RotateControl,
  ScaleLineControl,
  ZoomControl,
  ZoomToExtentControl
} from "@/components/Map/Controls";

import { Layers, TileLayer, VectorLayer, GroupLayer } from "@/components/Map/Layers";

import GeoBaseLayerSwitcher from "@/components/Map/Controls/GEO/GeoBaseLayerSwticher";
import GeoPortalGSLayer from "@/views/MapPage/GeoPortalGSLayer";
import GeoLayer from "@/components/Map/Layers/GEO/GeoLayer";
import UserContext from "@/components/UserContext/UserContext";
import { padExtent } from "@/lib/olHelpers";

import dataController from "@/lib/dataController";

//Services
import mapService from "@/services/mapService";
import gsService from "@/services/gsService";

import { recordStyle, selectedRecordStyle, hoveringRecordStyle, workingRecordStyle } from "@/components/Map/mapStyles";
import { DCRecord } from "@/@types/lib/dataController";

//Types
import { UserContextType } from "@/@types/context/UserContext";
import { IModel } from "@/@types/models/model";

interface IDataCentricMiniMapProps {
  lokacijaId: number;
  selectedObjektId: number;
  onObjektSelect: Function;
  onLokacijaSelect: Function;
  _setObjExtent: (objExtent: number[]) => void;

  model: IModel;
  mapId: number;
  baseRecordPath: string
  geomRelativePath: string
  locationField: string
}

function DataCentricMiniMap(props: IDataCentricMiniMapProps) {
  const userContext = useContext(UserContext) as UserContextType;
  const { t } = useTranslation();

  const [mapInitialized, setMapInitialized] = useState(false);

  //@ts-ignore
  const { lokacijaId, selectedObjektId, onObjektSelect, onLokacijaSelect, _setObjExtent } = props;
  const { model, mapId, baseRecordPath, geomRelativePath, locationField } = props;

  const [defaultExtent, setDefaultExtent] = useState(padExtent(userContext?.mapSettings?.default_extent));
  const [selectedExtent, setSelectedExtent] = useState(padExtent(defaultExtent));
  const [objExtent, setObjExtent] = useState<number[]>([]);
  const [viewOptions, setViewOptions] = useState({
    center: userContext?.mapSettings?.initial_view_center,
    extent: userContext?.mapSettings ? userContext.mapSettings.max_extent : undefined,
    zoom: userContext?.mapSettings ? userContext.mapSettings.initial_view_zoom : 8,
    minZoom: 8,
    maxZoom: 21
  });
  const [layersCollection, setLayersCollection] = useState<OlCollection<OlBaseLayer> | undefined>(undefined);
  const [objektiSource, setObjektiSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [selectedSource, setSelectedSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [highlightedSource, setHighlightedSource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [showDOFLayer, setShowDOFLayer] = useState(true);
  const [showObjektiLayer, setShowObjektiLayer] = useState(true);

  let highlightedFeature: OlFeature<Geometry> | null = null;

  //define proj
  proj4.defs(
    "EPSG:3765",
    "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
  );
  proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
  proj4.defs(
    "EPSG:31276",
    "+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs"
  );
  proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
  );
  OlRegister(proj4);

  const htrs96 = OlGetProjection("EPSG:3765");
  const wgs84 = OlGetProjection("EPSG:4326");
  const wgs84PM = OlGetProjection("EPSG:3857");

  const wkt = new OlFormatWKT();

  const dc = new dataController(model);

  useEffect(() => {
    // getDefaultData();
    getLayers();
  }, []);

  useEffect(() => {
    if (lokacijaId > 0) {
      refreshObjekti(lokacijaId);
    } else {
      setObjektiSource(new OlSourceVector({}));
      setObjExtent(defaultExtent);
      setViewOptions(prevState => {
        return {
          ...prevState,
          center: userContext?.mapSettings?.initial_view_center,
          zoom: userContext?.mapSettings ? userContext.mapSettings.initial_view_zoom : 8,
        }
      });
    }
    setSelectedSource(new OlSourceVector({}));
    setHighlightedSource(new OlSourceVector({}));
  }, [lokacijaId]);

  useEffect(() => {
    const src = new OlSourceVector({});
    const srcLoc = new OlSourceVector({});
    if (selectedObjektId > 0) {
      const ft: OlFeature<Geometry> | undefined = objektiSource
        .getFeatures()
        .find((x) => x.getId() === selectedObjektId);
      if (ft) {
        src.addFeature(ft);
      }

      const locFeatures = objektiSource.getFeatures().filter((x) => x.get(locationField) === lokacijaId);
      if (Array.isArray(locFeatures) && locFeatures.length > 0) {
        srcLoc.addFeatures(locFeatures);
      }
    }
    setSelectedSource(src);
    // Zoom to object
    // if (src && selectedObjektId) {
    //   setObjExtent(padExtent(src.getExtent()));
    //   _setObjExtent(src.getExtent());
    // } 
    // Zoom to location
    if (src && srcLoc && selectedObjektId) {
      setObjExtent(padExtent(srcLoc.getExtent()));
      _setObjExtent(src.getExtent());
    } 
    else if (objektiSource) {
      const extent = objektiSource.getExtent();
      if (extent && extent.every(x => x !== Infinity)) {
        setObjExtent(padExtent(extent));
      }
    }
  }, [selectedObjektId]);

  const getLayers = () => {
    mapService.getLayers(mapId).then((coll) => {
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };

  const refreshObjekti = (lokacijaId: number) => {
    // objektiService.getObjekti(lokacijaId).then((resp) => {
    dc.GetData(`${baseRecordPath}/${lokacijaId}/${geomRelativePath}`).then((resp) => {
      if (resp.success) {
        const src = new OlSourceVector({});
        const records = resp.data as DCRecord[];
        if (Array.isArray(records) && records.length > 0) {
          records.forEach((record: DCRecord) => {
            if (record.wkt) {
              let newFeature = new OlFeature({
                geometry: wkt.readGeometry(record.wkt, {
                  dataProjection: htrs96,
                  featureProjection: wgs84PM
                })
              });
              newFeature.setId(record.id as number);
              newFeature.setProperties({ type: model.source, id: record.id, [locationField]: lokacijaId });
              src.addFeature(newFeature);
            }
          });

          setObjektiSource(src);
          setSelectedExtent(padExtent(src.getExtent()));
          setObjExtent([]);

        } else {

          setObjektiSource(new OlSourceVector({}));
          setSelectedExtent(padExtent(defaultExtent));
          setObjExtent(defaultExtent);
        }

        
      }
    });
  };

  // const getDefaultData = () => {
  //   mapService.getDefaultData().then((data) => {
  //     if (data) {
  //       const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
  //       setDefaultExtent(viewData.default_extent);
  //       setSelectedExtent(viewData.default_extent);
  //       setViewOptions((prevViewOptions) => ({
  //         ...prevViewOptions,
  //         ...{
  //           zoom: 14,
  //           center: viewData.initial_view_center
  //         }
  //       }));
  //       // this.setState((prevState) => {
  //       //   return {
  //       //     ...prevState,
  //       //     viewOptions: {
  //       //       ...prevState.viewOptions,
  //       //       zoom: viewData.initial_view_zoom,
  //       //       center: viewData.initial_view_center
  //       //     },
  //       //     zoomToExtent: viewData.default_extent,
  //       //     defaultExtent: viewData.default_extent
  //       //   };
  //       // });
  //     }
  //   });
  // };

  const handleClick = (evt: OlMapBrowserEvent<any>) => {
    var feature = evt.map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature) {
        return feature;
      },
      {
        layerFilter: (layer) => {
          const layerId = layer.get("id");
          return layerId !== null && layerId !== undefined && layerId.startsWith("objekti");
        }
      }
    );

    if (feature) {
      const id = feature.getId();  
      onObjektSelect(id);
     
    } else if (showObjektiLayer) {

      const layer = layersCollection?.getArray().find(x => x.get("id") === "app-objekti");
      const gsLayerName = layer ? layer.get("layer") : null;

      if (gsLayerName) {
        gsService.getFeatureInfo(evt.map, evt.pixel, [gsLayerName]).then((resp) => {
          if (resp && Object.keys(resp).length != 0) {
            const layerKeys = Object.keys(resp);
            const key = layerKeys.find(x => x.substring(4) === model.source);
            const features = key ? resp[key] : [];
  
            if (Array.isArray(features) && features.length > 0) {
              const obj = features[0];
              // const objid = obj.properties.id;
              const locId = obj.properties[locationField];
              onLokacijaSelect(locId, obj.properties);
            } else {
              onObjektSelect(null);
            }
          } else {
            onObjektSelect(null);
          }
        });
      } else {
        onObjektSelect(null);
      }
    } else {
      onObjektSelect(null);
    }
  };

  const handlePointerMove = (evt: OlMapBrowserEvent<any>) => {
    if (evt.dragging) {
      return;
    }
    var pixel = evt.map.getEventPixel(evt.originalEvent);
    var feature = evt.map.forEachFeatureAtPixel(
      pixel,
      function (feature: any) {
        return feature;
      },
      {
        layerFilter: (layer) => {
          const layerId = layer.get("id");
          return layerId !== null && layerId !== undefined && layerId.startsWith("objekti");
        }
      }
    ) as unknown as OlFeature<Geometry>;
    highlightFeature(feature);
  };

  const handleLayerSwitcherDOFClick = () => {
    setShowDOFLayer((prevState) => {
      return !prevState
    });
  };

  const handleLayerSwitcherObjektiClick = () => {
    setShowObjektiLayer((prevState) => {
      return !prevState
    });
  };

  const highlightFeature = (feature: OlFeature<Geometry>) => {
    const olFeature = feature;
    if (olFeature !== highlightedFeature) {
      if (highlightedSource) {
        if (highlightedFeature) {
          highlightedSource.removeFeature(highlightedFeature);
        }
        if (olFeature) {
          highlightedSource.addFeature(olFeature);
        }
      }
      highlightedFeature = olFeature;
    }
  };

  // console.log(objExtent, selectedExtent);

  return (
    <Fragment>
      {layersCollection ? (
        <Map
          height="500px"
          view={viewOptions}
          onClick={handleClick}
          onPointermove={handlePointerMove}
          className="map"
          id="mini-map"
          zoomToExtent={objExtent.length ? objExtent : selectedExtent}
          // zoomToExtentPadding={[50, 50, 50, 50]}
          disableExtentPadding={true}
          initialized={mapInitialized}
        >
          <Controls>
            <ZoomControl zoomInTipLabel={t("map:controls.zoom_in")} zoomOutTipLabel={t("map:controls.zoom_out")} />
            <RotateControl autoHide={false} />
            <ScaleLineControl />
            <GeoBaseLayerSwitcher />
            <ZoomToExtentControl
              id="zoom-extent-default"
              extent={defaultExtent}
              tipLabel={t("map:controls.zoom_to_extent")}
              className="ol-control ol-zoom-extent"
            />
            <ZoomToExtentControl
              id="zoom-extent-selected"
              extent={selectedExtent}
              tipLabel={t("map:controls.zoom_to_selected")}
              className="ol-control ol-zoom-selected"
              labelClass="fas fa-bullseye"
            />
            <ButtonControl
              id="layerswitcher-dof"
              className={`ol-control ol-layerswitcher-dof ${showDOFLayer ? "ol-active" : ""}`}
              html="<i class='fas fa-camera'></i>"
              handleClick={handleLayerSwitcherDOFClick}
              title={t("map:layerswitcher.toggle_dof")}
            />
            <ButtonControl
              id="layerswitcher-objekti"
              className={`ol-control ol-layerswitcher-objekti ${showObjektiLayer ? "ol-active" : ""}`}
              html="<i class='fas fa-vector-square'></i>"
              handleClick={handleLayerSwitcherObjektiClick}
              title={t("map:layerswitcher.toggle_objekti")}
            />
          </Controls>
          <Layers>
            {layersCollection ? (
              <>
                {layersCollection.getArray().map((layer: OlBaseLayer, i: number) => {

                  const id = layer.get("id");
                  const visible = id === "app-dof" ? showDOFLayer : 
                    id === "app-objekti" ? showObjektiLayer 
                    : true;

                    return (
                      <GeoPortalGSLayer
                        key={i}
                        id={id}
                        title={t(layer.get("title"))}
                        layer={layer}
                        query={layer.get("query")}
                        zIndex={layer.get("zIndex")}
                        visible={visible}
                      />
                    );
                })}
              </>
            ) : null}
            <VectorLayer id="objekti" source={objektiSource} style={workingRecordStyle} zIndex={900} />
            <VectorLayer id="selected" source={selectedSource} style={selectedRecordStyle} zIndex={950} />
            <VectorLayer id="highlighted" source={highlightedSource} style={hoveringRecordStyle} zIndex={960} />
          </Layers>
        </Map>
      ) : null}
    </Fragment>
  );
}

export default DataCentricMiniMap;
