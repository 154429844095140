//window.CESIUM_BASE_URL = "/static/Cesium"

import React, { useContext, useEffect, useState, useRef } from 'react';
import {renderToString} from 'react-dom/server'
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import proj4 from "proj4";
import { register } from 'ol/proj/proj4';
//import * as Cesium from "cesium"
import { authHeader } from '@/lib/api/authHeader';

import { Tooltip, Typography, useTheme, Box, CircularProgress,Fade,Collapse } from '@mui/material';

//import "cesium/Build/Cesium/Widgets/widgets.css";
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import api from '@/lib/api';
import dataController from "@/lib/dataController";
import modelDKP from "@/models/dkp";

import OlFormatWKT from "ol/format/WKT";
import { transform } from "ol/proj";

import EntityInfoPane from "@/views/ThreeD/EntityInfoPane"

proj4.defs(
  "EPSG:3765",
  "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);

const DH = 12

const gs_url = process.env.REACT_APP_GEOSERVERPATH;

const apiPath = process.env.REACT_APP_APIPATH; 

const LINEPOINTCOLOR = Cesium.Color.RED;
const LINEWIDTH = 3;

const useQueryParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const getFloatParam = (param) => {
    const value = searchParams.get(param);
    return value && !isNaN(parseFloat(value)) ? parseFloat(value) : undefined;
  };

  return {
    cx: getFloatParam('cx'),
    cy: getFloatParam('cy'),
    cz: getFloatParam('cz'),
  };
};

function CesiumPage() {

  const { t } = useTranslation();

  // const [viewer, setViewer] = useState(null);
  // const [tileset, setTileSet] = useState(null);
  const [baseLayers, setBaseLayers] = useState(null);
  const [layers, setLayers] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [drawAllowed, setDrawAllowed] = useState(false);
  const [drawActive, setDrawActive] = useState(false);
  const [refresh, setRefreshToken]=useState(false);
  const [handler, setHandler]=useState(null)
  const [sourceCount, setSourceCount] = useState(1);
  const [locationRecords, setLocationRecords] = useState([]);;
  const [showEntities, setShowEntities] = useState(true);
  const [selectedEntityRecord, setSelectedEntityRecord] = useState(undefined);
  const [selectedEntityId,setSelectedEntityId] = useState(0);
  const [trackedEntityId, setTrackedEntityId] = useState(0);
  const [isOpenGFI, setIsOpenGFI] = useState(false);

  const theme = useTheme();

  const tileset_list = useRef();

  const viewer = useRef(); // new Cesium.Viewer('cesiumContainer');
  const camera = useRef();
  const scene = useRef();
  const globe = useRef(new Cesium.Globe(Cesium.Ellipsoid.WGS84));
  const ellipsoid = useRef(Cesium.Ellipsoid.WGS84);
  const geodesic = useRef(new Cesium.EllipsoidGeodesic());

  const points = useRef();
  const point1 = useRef();
  const point2 = useRef();
  const point1GeoPosition = useRef();
  const point2GeoPosition = useRef();
  const point3GeoPosition = useRef();

  const polylines = useRef();
  const polyline1 = useRef();
  const polyline2 = useRef();
  const polyline3 = useRef();

  const distanceLabel = useRef();
  const verticalLabel = useRef();
  const horizontalLabel = useRef();

  const wkt = new OlFormatWKT()

  const labelStyle = {
    font: '14px monospace',
    showBackground: true,
    horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
    verticalOrigin: Cesium.VerticalOrigin.CENTER,
    pixelOffset: new Cesium.Cartesian2(0, 0),
    eyeOffset: new Cesium.Cartesian3(0, 0, -50),
    fillColor: Cesium.Color.WHITE,
  };

  const getColorByStatus = (status) => {
    switch (status)
    {
      case 1:
        return "#3944BC";
        break;
      case 2:
        return "#990000";
        break;
      case 3:
        return "#009999";
        break;
      case 5:
        return "#808c00";
        break;
      default:
        return theme.palette.primary.main;
        break;
    }
  }

  // used when opening 3d model from the same tab as the main map
  /*const {state} = useLocation();
  const cx = state?.cx;
  const cy = state?.cy;
  const cz = state?.cz;*/

  // used when opening 3d model from a new tab
  const { cx, cy, cz } = useQueryParams();
  //console.log("Cx, cy, cz" , cx, cy, cz);

  const handleRefresh=()=>{setRefreshToken(prev=>!prev)}

  const dcDKP = new dataController(modelDKP);

  proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
  proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
  );

  useEffect(()=>{
    const apiInstance = new api();

    const url_count = "cesium/config/count";
    apiInstance.Call(url_count, "get").then(
      (resp) => {

        setSourceCount(resp.data.count);
      }
    )
  }, [])

  useEffect(()=>{
    const apiInstance = new api();

    const url_lokacije = "cesium/locations";
    apiInstance.Call(url_lokacije, "get").then(
      (resp) => {
        if (resp.data.length!==0)
          setLocationRecords(resp.data);
      }
    )
  }, [])

  useEffect(()=>{
    if (locationRecords.length>0 && viewer.current ){
      for (const locationKey in locationRecords){

        const ENTITY_PADDING = 0;

        const location = locationRecords[locationKey]; // contains wkt,kc,kb,id 
        const coordsFromWKT = wkt.readGeometry(location.wkt).getCoordinates();
        const [cx,cy] = transform([coordsFromWKT[0],coordsFromWKT[1]], "EPSG:3765", "EPSG:4326")

        //console.log(location);

        // TODO: FIND WAY TO UPDATE TEXT ON TRANSLATION CHANGE
        const newEntity = viewer.current.entities.add({
          id: location.id,
          //name: t("komunalni_obveznici.kc")+": "+location.kc+", "+t("objekti.kucni_broj")+": "+location.kb,
          name: t("komunalni_obveznici.lok"),
          allowPicking: false,
          position: Cesium.Cartesian3.fromDegrees(cx, cy, coordsFromWKT[2]+ENTITY_PADDING),
          point: {
            pixelSize: 8,
            color: Cesium.Color.fromCssColorString(getColorByStatus(location.lokacija_status_id)),
            outlineColor: Cesium.Color.BLACK,
            outlineWidth: 2,
            scaleByDistance: new Cesium.NearFarScalar(200, 1, 1000, 0.75)
          },
          description: renderToString("Loading..."),
          label: {
            text: location.kb,
            font: '20pt "Roboto", monospace',
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            outlineWidth: 4,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, -15),
            translucencyByDistance: new Cesium.NearFarScalar(200, 1, 300.0, 0),
            scaleByDistance: new Cesium.NearFarScalar(105, 1, 200.0, 0.5)
          },
          properties:
          {
            record: {...location,adresa: (location.ul_ime?location.ul_ime:"")+" "+(location.kb?location.kb:"")+", "+ (location.na_ime?location.na_ime:"")}
          }
        },
      )};
    }
  }, [locationRecords,viewer.current])

  useEffect(()=>{
    if (viewer.current!==undefined) {
      viewer.current.entities.values.map((entity) => {
        entity.show = showEntities;
      });

      const updateVisibility = () => {
        viewer.current.scene.requestRender(); // Force Cesium to update the scene
      };
  
      window.requestAnimationFrame(updateVisibility);

    }
  }, [showEntities])

  useEffect(() => {
    Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiMDVhZmY2Yi1mYTRjLTQ1NTYtODJmZC0wOTZlZDE4Yzg1MzEiLCJpZCI6MTE1Nzc0LCJpYXQiOjE2NjkxNTI5NzV9.3yUjYxWXb2KtUMQjH9WWd1DQDVcN-8WnxJeTAO8mmXI";

    // arrays for storing base layers and layers
    const baseLayerViewModels = []
    const layers = []

    // define base layers
    // baseLayerViewModels.push(new Cesium.ProviderViewModel({
    //   name: 'Open\u00adStreet\u00adMap',
    //   iconUrl: Cesium.buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
    //   tooltip: 'OpenStreetMap (OSM) is a collaborative project to create a free editable \
	// 		map of the world.\nhttp://www.openstreetmap.org',
    //   creationFunction: function () {
    //     return new Cesium.OpenStreetMapImageryProvider({
    //       url: 'https://a.tile.openstreetmap.org/'
    //     });
    //   }
    // }));
    // baseLayerViewModels.push(new Cesium.ProviderViewModel({
    //   name: "DGU DOF",
    //   tooltip: "DGU DOF",
    //   iconUrl: "http://geoportal.dgu.hr/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng8&TRANSPARENT=true&LAYERS=DOF&TILED=true&WIDTH=256&HEIGHT=256&CRS=EPSG%3A3857&STYLES=&BBOX=2015491.5618235283%2C5257033.057341281%2C2016103.0580498097%2C5257644.553567563",
    //   creationFunction: () => {
    //     return new Cesium.WebMapServiceImageryProvider({
    //       url: "http://geoportal.dgu.hr/wms?",
    //       layers: "DOF",
    //       crs: "EPSG:3857",
    //       parameters: {
    //         format: "image/png",
    //         transparent: true
    //       }
    //     })
    //   }
    // }))
    
    
    // Viewer
    
    if (!viewer.current) {
      viewer.current = new Cesium.Viewer('cesiumContainer', {
        animation: false,
        baseLayerPicker: false,
        geocoder: false,
        // Ovo ne radi?
        maximumRenderTimeChange: Infinity,
        requestRenderMode: true,
        scene3DOnly: true,
        timeline: false,
        infoBox: true,
        globe: globe.current,
        //terrainProvider: Cesium.createWorldTerrain(),
        //imageryProviderViewModels: baseLayerViewModels,
        //selectedImageryProviderViewModel: baseLayerViewModels[1],
      });
    }
    
    camera.current = viewer.current.camera;
    scene.current = viewer.current.scene;
    points.current = scene.current.primitives.add(new Cesium.PointPrimitiveCollection());
    polylines.current = scene.current.primitives.add(new Cesium.PolylineCollection());

    setHandler( new Cesium.ScreenSpaceEventHandler(scene.current.canvas));

    // var ellipsoid = Cesium.Ellipsoid.WGS84;
    // var geodesic = new Cesium.EllipsoidGeodesic();

    // define layers
    // 	layers.push(new Cesium.ImageryLayer(new Cesium.WebMapServiceImageryProvider({
    //   url: gs_url,
    //   layers: "ENA:105_DOF",
    //   crs: "EPSG:3857",
    //   parameters: {
    //     format: "image/vnd.jpeg-png",
    //     transparent: true,
    //   }
    // })))
    // layers.push(new Cesium.ImageryLayer(new Cesium.WebMapServiceImageryProvider({
    //     url: gs_url,
    //     layers: "ENA:105_DKP",
    //     crs: "EPSG:3857",
    //     parameters: {
    //       format: "image/vnd.jpeg-png",
    //       transparent: true,
    //     }
    // })))
    // layers.push(new Cesium.ImageryLayer(new Cesium.WebMapServiceImageryProvider({
    //     url: gs_url,
    //     layers: "ENA:105_KB",
    //     crs: "EPSG:3857",
    //     parameters: {
    //       format: "image/vnd.jpeg-png",
    //       transparent: true,
    //     }
    // })))

    // add layers to viewer
    //layers.forEach((layer) => viewer.current.imageryLayers.add(layer))

    // Prehnit d.o.o. logo
    scene.current.frameState.creditDisplay.addDefaultCredit(new Cesium.Credit('<a href="https://prehnit.hr"><img src="logo.png"/></a>'));

    // Cesium tileset

    var tilesets = [];
    
    for(let i = 0; i < sourceCount; i++ ){
      //console.log("I got called");
      const authorizationHeader = authHeader();

      const url = apiPath + "cesium/" + i + "/config" ;
  
      const resource = new Cesium.Resource({
        url: url,
        headers: authorizationHeader
      });

      let new_set = new Cesium.Cesium3DTileset({
        //debugShowBoundingVolume: true,
        //debugShowGeometricError: true,
        //debugShowRenderingStatistics: true,
        dynamicScreenSpaceError: true,
        immediatelyLoadDesiredLevelOfDetail: true,
        maximumScreenSpaceError: 1,
        skipLevelOfDetail: true,
        url: resource
      });

      tilesets.push(new_set)
    }
  
    //console.log("Tilesets", tilesets)

    tileset_list.current = tilesets;

    // tileset.current.allTilesLoaded.addEventListener(function () {
    //   console.log('All tiles are loaded');
    // });

    // Konfiguriraj što radi home gumb1207
    viewer.current.homeButton.viewModel.command.beforeExecute.addEventListener(function (commandInfo) {
      viewer.current.flyTo(tileset_list.current[0]);
      commandInfo.cancel = true;
    });

    // Add tileset to viewer and set initial camera position
    scene.current.screenSpaceCameraController.enableCollisionDetection = true;

   
    tilesets.forEach((tileset) => {
      tileset.readyPromise
        .then(() => {
          const boundingSphere = tileset.boundingSphere;
          camera.current.viewBoundingSphere(
            boundingSphere,
            new Cesium.HeadingPitchRange(
              0.5,
              -0.2,
              boundingSphere.radius * 4.0
            )
          );
          camera.current.lookAtTransform(Cesium.Matrix4.IDENTITY);
        })
        .catch((error) => {
          throw error;
        });
  
      viewer.current.scene.primitives.add(tileset);
    });

    //scene.current.primitives.add(tileset.current);
    viewer.current.zoomTo(tilesets);

    // setLayers(layers);
    // setBaseLayers(baseLayerViewModels);

    // return () => {
    //   if (viewer.current) {
    //     viewer.current.destroy();
    //     viewer.current = null;
    //   }
    // };
  }, [sourceCount])

  useEffect(() => {
    if (viewer.current !== undefined && viewer.current.camera) {

      const flyToHome = cx==undefined || cy==undefined || cz == undefined;
      
      //If object cordinates are not set fly to entire tileset
      if(flyToHome){
        setTimeout(() => {
          //console.log("Fly you fool")
          if(!tileset_list || tileset_list.length == 0) return;
          viewer.current.flyTo(tileset_list.current[0])
        }, 2000);
        return;
      } 
      //Else take and fly to given cordinates
      const flyOptions = {};
      const heightOffset = 50;
      const longitude = cx;
      const laditude = cy;
      const height = cz + heightOffset;

      const posCartographic = new Cesium.Cartographic(longitude, laditude, height);
      const posCartesian = Cesium.Cartographic.toCartesian(posCartographic);
      flyOptions.destination = posCartesian;
      
      setTimeout(() => {
        //console.log("Fly you fool")
        camera.current.flyTo(flyOptions);
      }, 2000);

     
    }
  }, [viewer.current])

  useEffect(()=>{

    // Mouse over the globe to see the cartographic position
    
    handler?.setInputAction(function (click) {
      if(!drawAllowed) {
        //console.log("Drawing not allowed!");
        return;
      }
      else {
        setDrawActive(true);
        if (scene.current.mode !== Cesium.SceneMode.MORPHING) {
          const pickedObject = scene.current.pick(click.position);
          if (scene.current.pickPositionSupported && Cesium.defined(pickedObject)) {

            const cartesian = scene.current.pickPosition(click.position);
            // console.log(cartesian);
            if (Cesium.defined(cartesian)) {
              if (points.current.length === 2) {
                points.current.removeAll();
                polylines.current.removeAll();
                viewer.current.entities.remove(distanceLabel.current);
                viewer.current.entities.remove(horizontalLabel.current);
                viewer.current.entities.remove(verticalLabel.current);
              }
              //add first point
              if (points.current.length === 0) {
                point1.current = points.current.add({
                  position: new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z),
                  color: LINEPOINTCOLOR
                });
              } //add second point and lines
              else if (points.current.length === 1) {
                point2.current = points.current.add({
                  position: new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z),
                  color: LINEPOINTCOLOR
                });
                point1GeoPosition.current = Cesium.Cartographic.fromCartesian(point1.current.position);
                point2GeoPosition.current = Cesium.Cartographic.fromCartesian(point2.current.position);
                point3GeoPosition.current = Cesium.Cartographic.fromCartesian(new Cesium.Cartesian3(point2.current.position.x, point2.current.position.y, point1.current.position.z));

                const pl1Positions = [
                  new Cesium.Cartesian3.fromRadians(point1GeoPosition.current.longitude, point1GeoPosition.current.latitude, point1GeoPosition.current.height),
                  new Cesium.Cartesian3.fromRadians(point2GeoPosition.current.longitude, point2GeoPosition.current.latitude, point2GeoPosition.current.height)
                ];
                const pl2Positions = [
                  new Cesium.Cartesian3.fromRadians(point2GeoPosition.current.longitude, point2GeoPosition.current.latitude, point2GeoPosition.current.height),
                  new Cesium.Cartesian3.fromRadians(point2GeoPosition.current.longitude, point2GeoPosition.current.latitude, point1GeoPosition.current.height)
                ];
                const pl3Positions = [
                  new Cesium.Cartesian3.fromRadians(point1GeoPosition.current.longitude, point1GeoPosition.current.latitude, point1GeoPosition.current.height),
                  new Cesium.Cartesian3.fromRadians(point2GeoPosition.current.longitude, point2GeoPosition.current.latitude, point1GeoPosition.current.height)
                ];

                polyline1.current = polylines.current.add({
                  show: true,
                  positions: pl1Positions,
                  width: 1,
                  material: new Cesium.Material({
                    fabric: {
                      type: 'Color',
                      uniforms: {
                        color: LINEPOINTCOLOR
                      }
                    }
                  })
                });
                polyline2.current = polylines.current.add({
                  show: true,
                  positions: pl2Positions,
                  width: 1,
                  material: new Cesium.Material({
                    fabric: {
                      type: 'PolylineDash',
                      uniforms: {
                        color: LINEPOINTCOLOR,
                      }
                    },
                  })
                });
                polyline3.current = polylines.current.add({
                  show: true,
                  positions: pl3Positions,
                  width: 1,
                  material: new Cesium.Material({
                    fabric: {
                      type: 'PolylineDash',
                      uniforms: {
                        color: LINEPOINTCOLOR,
                      }
                    },
                  })
                });
                let labelZ;
                if (point2GeoPosition.current.height >= point1GeoPosition.current.height) {
                  labelZ = point1GeoPosition.current.height + (point2GeoPosition.current.height - point1GeoPosition.current.height) / 2.0;
                } else {
                  labelZ = point2GeoPosition.current.height + (point1GeoPosition.current.height - point2GeoPosition.current.height) / 2.0;
                };

                addDistanceLabel(point1.current, point2.current, labelZ);

              }
            }
          }
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  }, [drawAllowed])

  useEffect(() => {
    if (viewer.current !== null && viewer.current !== undefined) {
      viewer.current.selectedEntityChanged.addEventListener((selectedEntity) => {
        if (Cesium.defined(selectedEntity)) {
          if (Cesium.defined(selectedEntity.id)) {
            setSelectedEntityRecord(selectedEntity.properties.getValue(Cesium.JulianDate.now()).record);
            setSelectedEntityId(selectedEntity.id);
            setIsOpenGFI(true);
          } else {
            handleCloseInfoPane();
          }
        } else {
          handleCloseInfoPane();
        }
      });

      viewer.current.trackedEntityChanged.addEventListener((trackedEntity) => {
        if (trackedEntity!==undefined) {
          setTrackedEntityId(trackedEntity.id);
        } else {
          setTrackedEntityId(0);
        }
      });
    }
  }, [viewer.current]);

  const handleCloseInfoPane = () => {
    setIsOpenGFI(false);
    viewer.current.selectedEntity = undefined;
    viewer.current.trackedEntity = undefined;
    setTrackedEntityId(0);
  }

  const focusOnObject = (id) => {
    if (viewer.current!==undefined) {
      viewer.current.entities.values.forEach((entity) => {
        if (entity.id === id) {
          if (viewer.current.trackedEntity && viewer.current.trackedEntity.id === entity.id) {
            viewer.current.trackedEntity = undefined;
            setTrackedEntityId(0);
          } else {
            viewer.current.trackedEntity = entity;
            setTrackedEntityId(id);
          }
        }
      });
    }
  }

  const addDistanceLabel = (p1, p2, height) => {
    p1.cartographic = ellipsoid.current.cartesianToCartographic(p1.position);
    p2.cartographic = ellipsoid.current.cartesianToCartographic(p2.position);
    p1.longitude = parseFloat(Cesium.Math.toDegrees(p1.position.x));
    p1.latitude = parseFloat(Cesium.Math.toDegrees(p1.position.y));
    p2.longitude = parseFloat(Cesium.Math.toDegrees(p2.position.x));
    p2.latitude = parseFloat(Cesium.Math.toDegrees(p2.position.y));

    const l1 = { ...labelStyle };
    l1.text = getHorizontalDistanceString(p1, p2);
    horizontalLabel.current = viewer.current.entities.add({
      position: getMidpoint(p1, p2, point1GeoPosition.current.height),
      label: l1
    });

    const l2 = { ...labelStyle }
    l2.text = getDistanceString(p1, p2);
    distanceLabel.current = viewer.current.entities.add({
      position: getMidpoint(p1, p2, height),
      label: l2
    });

    const l3 = { ...labelStyle }
    l3.text = getVerticalDistanceString();
    verticalLabel.current = viewer.current.entities.add({
      position: getMidpoint(p2, p2, height),
      label: l3
    });
  };

  const getHorizontalDistanceString = (p1, p2) => {
    geodesic.current.setEndPoints(p1.cartographic, p2.cartographic);
    var meters = geodesic.current.surfaceDistance.toFixed(2);
    if (meters >= 1000) {
      return (meters / 1000).toFixed(1) + ' км ⟷';
    }
    return meters + ' м ⟷';
  };

  const getVerticalDistanceString = () => {
    const heights = [point1GeoPosition.current.height, point2GeoPosition.current.height];
    const meters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
    if (meters >= 1000) {
      return (meters / 1000).toFixed(1) + ' км ↥';
    }
    return meters.toFixed(2) + ' м ↥';
  };

  const getDistanceString = (p1, p2) => {
    geodesic.current.setEndPoints(p1.cartographic, p2.cartographic);
    const horizontalMeters = geodesic.current.surfaceDistance.toFixed(2);
    const heights = [point1GeoPosition.current.height, point2GeoPosition.current.height];
    const verticalMeters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
    const meters = Math.pow((Math.pow(horizontalMeters, 2) + Math.pow(verticalMeters, 2)), 0.5);

    if (meters >= 1000) {
      return (meters / 1000).toFixed(1) + ' км';
    }
    return meters.toFixed(2) + ' м';
  };

  const getMidpoint = (p1, p2, height) => {
    const scratch = new Cesium.Cartographic();
    geodesic.current.setEndPoints(p1.cartographic, p2.cartographic);
    const midpointCartographic = geodesic.current.interpolateUsingFraction(0.5, scratch);
    return Cesium.Cartesian3.fromRadians(midpointCartographic.longitude, midpointCartographic.latitude, height);
  };

  const getTriangleMidpoint = (p1, p2, height) => {
    const scratch = new Cesium.Cartographic();
    geodesic.current.setEndPoints(p1.cartographic, p2.cartographic);
    const midpointCartographic = geodesic.current.interpolateUsingFraction(0.5, scratch);
    return Cesium.Cartesian3.fromRadians(midpointCartographic.longitude, midpointCartographic.latitude, height);
  };

  const handleResetDraw = ()=>{
    points.current.removeAll();
    polylines.current.removeAll();
    viewer.current.entities.remove(distanceLabel.current);
    viewer.current.entities.remove(horizontalLabel.current);
    viewer.current.entities.remove(verticalLabel.current);
    setDrawActive(false);
  }

  return (
    <>
      <div id='cesium-draw-toolbar' 
        style={
          {
            display: "flex",
            flexDirection: "column",
            gap: 2,
            position: "absolute",
            right: "1%",
            top: "35%",
            height: "50%",
            justifyContent: "center",
            alignItems: "end"
          }
        }
      >
        <Tooltip title={drawAllowed ? t("buttons.toggle_drawing_off") : t("buttons.toggle_drawing_on")} placement='left'>
          <DesignServicesOutlinedIcon  
            fontSize='large' 
            cursor="pointer"
            color={drawAllowed ? '' : 'disabled'} 
            sx={
              {
                backgroundColor: theme.palette.background.default,
                borderRadius: "10%",
                zIndex: 999,

                ":hover":{
                  backgroundColor: theme.palette.secondary,
                  border: "1px solid white"
                }
              }
            }
            onClick={()=>{ 
              if(drawActive) handleResetDraw();
              if(drawAllowed) setDrawAllowed(false);
              else setDrawAllowed(true);
            }
            }
          />
        </Tooltip>
        <Tooltip title={t("buttons.delete_drawings")} placement='left'>
          <DeleteOutlineOutlinedIcon 

            fontSize='large' 
            color={drawActive ? '' : 'disabled'} 
            cursor={drawActive ? 'pointer': 'not-allowed'}
            
            sx={
              {
                backgroundColor: theme.palette.background.default,
                borderRadius: "10%",
                zIndex: 999,
                
                ":hover":{
                  backgroundColor: theme.palette.secondary,
                  border: "1px solid white"
                }
              }
            }
            onClick={()=>{
              if(!drawActive) return;
              handleResetDraw();
              handleRefresh();
            }}
          />
        </Tooltip>
        { locationRecords.length > 0 && <Tooltip title={t("buttons.show_locations")} placement='left'>
          <LocationOnIcon 

            fontSize='large' 
            color={showEntities ? '' : 'disabled'} 
            cursor={'pointer'}
            
            sx={
              {
                mt: 3,
                backgroundColor: theme.palette.background.default,
                borderRadius: "10%",
                zIndex: 999,
                
                ":hover":{
                  backgroundColor: theme.palette.secondary,
                  border: "1px solid white"
                }
              }
            }
            onClick={()=>{
              setShowEntities(showEntities => !showEntities);
            }}
          />
        </Tooltip>}
      </div>
      <div style={{position: "relative"}} id="cesiumContainer">
        <Box zIndex={1200} margin={"10px"} position={"absolute"} left={0} top={0}>
            <Collapse orientation={"horizontal"} in={isOpenGFI}>
            { selectedEntityId!==0 && 
              <EntityInfoPane 
                focusOnObject={focusOnObject} 
                onClose={handleCloseInfoPane}
                
                trackedEntityId={trackedEntityId}

                entityId={selectedEntityId} 
                record={selectedEntityRecord}
              />
            }
            </Collapse>
        </Box>
      </div>
    </>
  );
}


export default CesiumPage;