import React, { useContext, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

//MUI
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";

//Custom Components
import DraggableDialog from "../../ui/Dialog/DraggableDialog";
import DialogHeader from "../../ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogActionButtonCancel from "@/ui/Dialog/ActionButtons/DialogActionButtonCancel";


import DialogContext from "@/ui/DialogContext/DialogContext";
import { Button, ButtonGroup, Typography } from "@mui/material";

const LINE = "LineString";
const POLY = "Polygon";
const PT = "Point";

function CommentShapeDialog(props: any) {
    const { t } = useTranslation();
    const dialogContext = useContext(DialogContext);
    
    const { drawType, changeDrawType } = props;

    const handleClose = (evt: SyntheticEvent) => {
        close({ success: false });
    }

    const close = (result: any) => {
        dialogContext.hideDialog();
    };


    return (
        <DraggableDialog open={true} onClose={handleClose} >
            <DialogHeader>
                <Toolbar variant="dense" disableGutters={true}>
                    <DialogToolbarHeading>{t("titles.add_comment")}</DialogToolbarHeading>
                    <ToolbarFillContent />
                    <DialogToolbarButtonClose onClick={handleClose} />
                </Toolbar>
            </DialogHeader>
            <DialogBody>
                <Box m={2} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="h6" gutterBottom>
                        {t("messages.choose_shape")}
                    </Typography>
                    <ButtonGroup>
                        <Button
                        value="length"
                        color="primary"
                        onClick={() => { changeDrawType(LINE); close({ success: false }); }}
                        variant={drawType === LINE ? "contained" : "outlined"}
                        startIcon={<i className="fas fa-ruler-horizontal" />}
                        >
                        {t("map:measure.buttons.line")}
                        </Button>
                        <Button
                        value="area"
                        color="primary"
                        onClick={() => { changeDrawType(POLY); close({ success: false }); }}
                        variant={drawType === POLY ? "contained" : "outlined"}
                        startIcon={<i className="fas fa-vector-square" />}
                        >
                        {t("map:measure.buttons.area")}
                        </Button>
                        <Button
                        value="point"
                        color="primary"
                        onClick={() => { changeDrawType(PT); close({ success: false }); }}
                        variant={drawType === PT ? "contained" : "outlined"}
                        startIcon={<i className="fas fa-map-marker-alt" />}
                        >
                        {t("map:measure.buttons.point")}
                        </Button>
                    </ButtonGroup>
                </Box>
            </DialogBody>
            <DialogActions>
                <DialogActionButtonCancel variant="outlined" onClick={handleClose} />
            </DialogActions>
        </DraggableDialog>
    )
}

export default CommentShapeDialog
