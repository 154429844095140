// @ts-nocheck

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import GridContainer from "UI/Grid/GridContainer";

import DialogContext from "UI/DialogContext/DialogContext";
import ImageDialog from "@/components/ImageDialog";
import { Typography, Card } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button } from '@mui/material';

const imageStyles = {
  width: "100px",
  height: "100px",
  borderRadius: "5px",
  cursor: "pointer"
}

function ImageSlider(props) {
  const { t } = useTranslation();
  const dialogContext = useContext(DialogContext);
  const [activeIndex, setActiveIndex] = useState(0);

  const {images} = props;

  const handleOpenImageDialog = (image) => {

    dialogContext.showDialog(ImageDialog, {
      image: image,
      onClose: handleDialogClose
    })
  }

  const handleDialogClose = () => {
    dialogContext.hideDialog();
  }
  
  const handleButtonScroll = (direction: string) => {
    if(direction === 'right' && activeIndex < images.length - 1) {
      setActiveIndex(activeIndex => activeIndex + 1);
    } else if(direction === 'left'  && activeIndex > 0) {
      setActiveIndex(activeIndex => activeIndex - 1);
    }
  }

  return (
    <Card sx={{ p: 1, mt: 1, bgcolor: "#ececec", width: "100%" }}>
      {/* <Typography sx={{ textAlign: 'center'}}>{t("dump_report.photos")}</Typography> */}
      <GridContainer
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: "8px"
        }}
      >
        {images.map((image, index) => {
          if(index === activeIndex) {
            return <img
              key={"image-" + index}
              src={`data:image/jpeg;base64, ${image}`}
              style={imageStyles}
              onClick={() => handleOpenImageDialog(image)}
            />
          } else {
            return null;
          }
        })}

        {activeIndex > 0 &&
          <Button
            onClick={
              () => {
                handleButtonScroll('left');
              }
            }
            sx={{
              visibility: "visible",
              borderRadius:"50%",
              position:"absolute",
              top:"50%",
              left:{
                xs:1,
                lg:3
              },
              p:0,
              width: {
                xs:"2rem",
                lg:"3rem"
              },
              minWidth:{
                xs:"2rem",
                lg:"3rem"
              },
              height:{
                xs:"2rem",
                lg:"3rem"
              },
              backgroundColor: "rgb(255,255,255,0.7)",
              "&:hover":{
                backgroundColor:"rgb(255,255,255,0.5)",
                borderColor: "rgb(0,0,0,0.7)"
              },
              border:" 1px solid black"
              }}>
            <ArrowLeftIcon fontSize='large' sx={{
              color:"black",
              "&:hover":{
                borderColor: "rgb(0,0,0,0.7)"
              },
              transform:{
                lg:"scale(1.5)"
              }
            }} />
            </Button>
        }
        
        {activeIndex < images.length - 1 && 
          <Button 
            onClick={
              () => {
                handleButtonScroll('right');
              }
            }
            sx={{
              visibility: "visible",
              borderRadius:"50%",
              position:"absolute",
              top:"50%",
              right:{
                xs:1,
                lg:3
              },
              width: {
                xs:"2rem",
                lg:"3rem"
              },
              minWidth:{
                xs:"2rem",
                lg:"3rem"
              },
              height:{
                xs:"2rem",
                lg:"3rem"
              },
              p:0,
              backgroundColor: "rgb(255,255,255,0.7)",
              "&:hover":{
                backgroundColor:"rgb(255,255,255,0.9)"
              }  ,
              border:" 1px solid black"
              }}>
            <ArrowRightIcon fontSize='large' sx={{
              color:"black",
              transform:{
                lg:"scale(1.5)"
              }
            }} />
            </Button>
          }

      </GridContainer>
    </Card>
  );
}

export default ImageSlider;
