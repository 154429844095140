import { IModel } from "@/@types/models/model";

import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { buttonFields } from "./helpers/buttonFields";

const RasvjetnaMjestaModel: IModel = {
  title: "Rasvjetna mjesta",
  source: "rasvjeta_mjesta",
  apiPath: "rasvjeta/rasvjetna-mjesta",
  forms: {
    default: {
      fields: ["oznaka", "lokacija", "tip", "napomena", "broj_svjetala", "snaga_ukupna"],
    }
  },
  listViews: {
    default: {
      fields: [
        "id", "oznaka", "lokacija", "tip", "broj_svjetala", "snaga_ukupna", "omm_naziv", "napomena"
      ],
      hidden: []
    },
    sidebar: {
      fields: ["id", "oznaka", "show_on_map"]
    },
    attribute_table: {
      fields: ["id", "oznaka", "deselect"],
      hidden: []
    },
  },
  fields: [
    coreFields.id,
    {
      title: "Oznaka",
      source: "oznaka",
      ttoken: "rasvjeta.oznaka",
      type: "text"
    },
    {
      title: "Lokacija",
      source: "lokacija",
      ttoken: "rasvjeta.lokacija",
      type: "text"
    },
    {
      title: "Tip stupa",
      source: "tip",
      ttoken: "rasvjeta.tip_stupa",
      type: "text"
    },
    {
      title: "Napomena",
      source: "napomena",
      ttoken: "rasvjeta.prehnit_napomena",
      type: "text"
    },
    {
      title: "Godišnja potrošnja",
      source: "snaga_ukupna",
      ttoken: "rasvjeta.godisnja_potrosnja",
      type: "numeric"
    },
    {
      title: "Broj svjetala",
      source: "broj_svjetala",
      ttoken: "rasvjeta.broj_svjetala",
      type: "numeric"
    },
    {
      title: "OMM Naziv",
      source: "omm_naziv",
      ttoken: "rasvjeta.mjerno_mjesto",
      type: "text"
    },
    {
      title: "",
      source: "show_on_map",
      ttoken: "",
      tooltip: "buttons.show_on_map",
      type: "button",
      icon: "fmd_good",
      action: "show_on_map",
      padding: "none",
      sort: false
    },
    commonFields.wkt,
    commonFields.proj,
    buttonFields.deselect
  ]
};

export default RasvjetnaMjestaModel;
