import HomePage from "@/views/HomePage/HomePage";
import MapPage from "@/views/MapPage/MapPage";
import ProfilePage from "@/views/ProfilePage/ProfilePage";
import AdminHomePage from "@/views/Administration/AdminHomePage";
import UsersPage from "@/views/Administration/UsersPage/UsersPage";

import KomunalniObvezniciDataCentricPage from "@/views/KomunalniObvezniciPage/KomunalniObvezniciDataCentricPage";
import ObjektiPhotoCentricPage from "@/views/Objekti/ObjektiPhotoCentricPage";
import RasvjetaPhotoCentricPage from "@/views/Rasvjeta/RasvjetaPhotoCentricPage";
import RasvjetaDataCentricPage from "@/views/Rasvjeta/RasvjetaDataCentricPage";
import ThreeDPage from "@/views/ThreeD/ThreeDPage";
import KuceZaOdmorDataCentricPage from "@/views/KuceZaOdmorPage/KuceZaOdmorDataCentricPage";

//Types
import { RouteType } from "@/@types/common";

const privateRoutes: RouteType[] = [
  { path: "/profile", name: "Profile", component: ProfilePage },
  { path: "/admin/users", name: "Map", component: UsersPage },
  { path: "/admin", name: "Admin Home Page", component: AdminHomePage },
  { path: "/map", name: "Map", component: MapPage },
  {
    path: "/komunalne-obveze",
    name: "Komunalne obveze",
    component: KomunalniObvezniciDataCentricPage,
  },
  {
    path: "/fotodokumentacija-objekata",
    name: "Fotodokumentacija objekata",
    component: ObjektiPhotoCentricPage,
  },
  { path: "/rasvjeta", name: "Rasvjeta", component: RasvjetaDataCentricPage },
  {
    path: "/fotodokumentacija-rasvjeta",
    name: "Fotodokumentacija rasvjetnih mjesta",
    component: RasvjetaPhotoCentricPage,
  },
  { path: "/3d", name: "3D", component: ThreeDPage },
  { path: "/", name: "Home", component: MapPage },
  {
    path: "/kuce-za-odmor",
    name: "Kuće za odmor",
    component: KuceZaOdmorDataCentricPage,
  },
];

export default privateRoutes;
