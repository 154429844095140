import React, { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";

//MUI
import Box from "@mui/material/Box";

//Custom Components
import { GridContainer, GridItem } from "@/ui/Grid";
import AppPage from "@/ui/AppPage/AppPage";
import PhotoCentricTable from "./PhotoCentricTable";
import PhotoCentricMiniMap from "./PhotoCentricMiniMap";
import PhotoCentricThumbs from "./PhotoCentricThumbs";
import useWindowSize from "@/components/useWindowSize";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import dataController from "@/lib/dataController";

//Types
import { LoaderContextType } from "@/@types/context/LoaderContext";
import { SnackbarContextType } from "@/@types/ui/SnackbarContext";
import { IDataController, DCRecord } from "@/@types/lib/dataController";
import { IImageData, } from "@/@types/views/KomunalniObveznici";
import { Size } from "@/components/useWindowSize";

import { IModel } from "@/@types/models/model";
import { Permission } from "@/@types/common";
import { RowActionFn } from "@/@types/ui/Table";

type PhotoCentricPageParams = {
  needPermission?: [Permission]
  titleToken: string,
  model: IModel,
  mapId: number
  onCustomTableAction?: RowActionFn,
  viewName?: string
}

function PhotoCentricPage(props: PhotoCentricPageParams) {
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;

  const [selectedRecord, setSelectedRecord] = useState<DCRecord | null>(null);
  const [showImages, setShowImages] = useState(false);
  const [imgData, setImgData] = useState<IImageData | null>(null);
  const [objExtent, setObjExtent] = useState<number[]>([]);

  const [records, setRecords] = useState<DCRecord[]>([]);

  const size: Size = useWindowSize();

  const { needPermission, titleToken, model, mapId, onCustomTableAction, viewName } = props;

  const [searchParams] = useSearchParams();

  const dc: IDataController = new dataController(model);

  const rec_id = searchParams.get("rec_id");
  const int_rec_id = rec_id ? parseInt(rec_id, 10) : undefined;

  useEffect(() => {
    refreshRecords();
  }, []);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData().then((resp) => {
      if (resp.success && Array.isArray(resp.data)) {
        setRecords(resp.data)
        // if (onLoad) {
        //   onLoad(resp.data);
        // }
        if (resp.data.length === 0) {
          snackbarContext.showNotification("messages.nodata", "warning", true);
        }
        if (int_rec_id !== undefined) {
          const rec = resp.data.find(x => x.id === int_rec_id);
          if (rec) {
            setSelectedRecord(rec);
          }
        }
      };
    })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRecordSelect = (id: number, record: DCRecord | null) => {
    // console.log(id, record);
    if (selectedRecord !== null && id === selectedRecord.id) {
      setSelectedRecord(null);
    } else {
      setSelectedRecord(record);
    }
    // if (!showImages && id) {
    //   setShowImages(true);
    // }
  };

  useEffect(() => {
    setShowImages(selectedRecord !== null);
  }, [selectedRecord]);

  useEffect(() => {
    if (int_rec_id === undefined) {
      setSelectedRecord(null);
    } else {
      const rec = records.find(x => x.id === int_rec_id);
      if (rec) {
        setSelectedRecord(rec);
      }
    }
  }, [int_rec_id])

  const firstRowHeight = size.height ? `${(size.height - 50) * 0.6}px` : "60%";
  const secondRowHeight = size.height ? `${(size.height - 50) * 0.4}px` : "40%";
  // const mapWidth = secondRowHeight;

  return (
    <AppPage needPermission={needPermission}>
      <Box m={0} style={{ height: firstRowHeight }}>
        <PhotoCentricTable
          records={records}
          onRowSelect={handleRecordSelect}
          onRowAction={onCustomTableAction}
          rowSelectedCustom={selectedRecord ? selectedRecord.id as number : undefined}

          titleToken={titleToken}
          dc={dc}
          viewName={viewName}
        />
      </Box>
      <GridContainer sx={{ height: secondRowHeight }}>
        <GridItem xs={3}>
          <PhotoCentricMiniMap
            onObjektSelect={handleRecordSelect}
            selectedObjekt={selectedRecord}
            _setObjExtent={setObjExtent}

            mapId={mapId}
          />
        </GridItem>
        <GridItem xs={9}>
          <PhotoCentricThumbs
            baseRecordPath={model.apiPath}
            recordId={selectedRecord ? selectedRecord.id as number : null}
          />
        </GridItem>
      </GridContainer>
    </AppPage>
  );
}

export default PhotoCentricPage;
