import { IModel } from "@/@types/models/model";
import { commonFields } from "./helpers/commonFields";
import { coreFields } from "./helpers/coreFields";

const User: IModel = {
  title: "Nerazvrstane ceste",
  apiPath: "data/nc",
  forms : {
    "default": {
      fields: [ "oznaka", "kategorija", "tip_kolnik", "vrsta", "opis", "smjer", "dionica_od", "dionica_do", "naselje", "duljina", "kc", "vlasnistvo", "ko", "stac_od", "stac_do",
      ]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "oznaka", "kc", "show_on_map"]
    }
  },
  fields: [
    coreFields.id,
    { 
      title: "oznaka",
      source: "oznaka",
      type: "text",
      ttoken: "nc.oznaka",
      readonly: true
    },
    { 
      title: "kategorija",
      source: "kategorija",
      ttoken: "nc.kategorija",
      type: "text",
      readonly: true
    },
    { 
      title: "tip_kolnik",
      source: "tip_kolnik",
      ttoken: "nc.tip_kolnik",
      type: "text",
      readonly: true
    },
    { 
      title: "vrsta",
      source: "vrsta",
      ttoken: "nc.vrsta",
      type: "text",
      readonly: true
    },
    { 
      title: "opis",
      source: "opis",
      ttoken: "nc.opis",
      type: "text",
      readonly: true
    },
    { 
      title: "smjer",
      source: "smjer",
      ttoken: "nc.smjer",
      type: "text",
      readonly: true
    },
    { 
      title: "dionica_od",
      source: "dionica_od",
      ttoken: "nc.dionica_od",
      type: "text",
      readonly: true
    },
    { 
      title: "dionica_do",
      source: "dionica_do",
      ttoken: "nc.dionica_do",
      type: "text",
      readonly: true
    },
    { 
      title: "naselje",
      source: "naselje",
      ttoken: "nc.naselje",
      type: "text",
      readonly: true
    },
    { 
      title: "duljina",
      source: "duljina",
      ttoken: "nc.duljina",
      type: "text",
      readonly: true
    },
    { 
      title: "kc",
      source: "kc",
      ttoken: "nc.kc",
      type: "text",
      readonly: true
    },
    { 
      title: "vlasnistvo",
      source: "vlasnistvo",
      ttoken: "nc.vlasnistvo",
      type: "text",
      readonly: true
    },
    { 
      title: "ko",
      source: "ko",
      ttoken: "nc.ko",
      type: "text",
      readonly: true
    },
    { 
      title: "stac_od",
      source: "stac_od",
      ttoken: "nc.stac_od",
      type: "text",
      readonly: true
    },
    { 
      title: "stac_do",
      source: "stac_do",
      ttoken: "nc.stac_do",
      type: "text",
      readonly: true
    },
    {
      title: "",
      source: "show_on_map",
      ttoken: "",
      tooltip: "buttons.show_on_map",
      type: "button",
      icon: "fmd_good",
      action: "show_on_map",
      padding: "none",
      sort: false,
      search: false
    },
    commonFields.wkt
  ]
}

export default User;
