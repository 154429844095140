import React, { useState, MouseEvent, ReactNode } from "react";
import { useAsyncDebounce } from "react-table";
import { useTranslation } from "react-i18next";

//MUI
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

//MUI Icons
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

//Custom components
import TableHeaderButton from "./HeaderButtons/TableHeaderButton";

//Types
import { GlobalFilterFn } from "@/@types/ui/Table";
import { IMenuState } from "@/@types/ui/Table";
import { Row, Column } from "react-table";

interface ITableSearchBarProps {
  preGlobalFilteredRows: Array<Row>;
  globalFilter: GlobalFilterFn;
  setGlobalFilter: (globalFilter?: string) => void;
  count?: number;
  columns: Array<Column>;
	hiddenColumnNames: Array<string> | undefined;
  onScopeChange: (source: string, newState: boolean) => void;
  smallButtons?: boolean;
}


function TableSearchBar(props: ITableSearchBarProps) {
  const { t } = useTranslation();
  const { preGlobalFilteredRows, globalFilter, setGlobalFilter, columns, hiddenColumnNames, onScopeChange, smallButtons } = props;
  const [value, setValue] = useState<GlobalFilterFn>(globalFilter);

  const [menuState, setMenuState] = useState<IMenuState>({ open: false, anchorEl: null });


  const count = props.count !== undefined ? props.count : preGlobalFilteredRows.length;

  //actions that will manipulate the table state may result in multiple rapid rerenders
  //useAsyncDebounce will allow you to debounce rapid side-effects and only use the latest one
  const onSearchTextChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  // // Here's where the API call happens
  // // We use useEffect since this is an asynchronous action
  // useEffect(
  //   () => {
  //     // Make sure we have a value (user has entered something in input)
  //     console.log(value, searchTerm);
  //     if (debouncedSearchTerm) {
  //       onChange(debouncedSearchTerm);
  //     } else {
  //       onChange(undefined);
  //     }
  //   },
  //   // This is the useEffect input array
  //   // Our useEffect function will only execute if this value changes ...
  //   // ... and thanks to our hook it will only change if the original ...
  //   // value (searchTerm) hasn't changed for more than 500ms.
  //   [debouncedSearchTerm, searchTerm]
  // );

  const placeholder = t("common.search_count_placeholder", { count: count });

  const handleOpen = (evt: MouseEvent<HTMLButtonElement>) => {
    setMenuState({ open: true, anchorEl: evt.currentTarget });
  };

  const handleToggle = (evt: MouseEvent<HTMLLIElement>, source: string) => {
    const isVisible = hiddenColumnNames ? !hiddenColumnNames.includes(source) : true;
    const newState = !isVisible;

    if (onScopeChange) {
      onScopeChange(source, newState);
    }
  };

  const handleClose = (evt: MouseEvent<HTMLButtonElement>) => {
    setMenuState({ open: false, anchorEl: null });
  };

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <TextField
        id="global-search"
        InputProps={{
          startAdornment: <SearchIcon color="primary" />
        }}
        variant="standard"
        color="primary"
        margin="dense"
        placeholder={placeholder}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onSearchTextChange(e.target.value);
        }}
        sx={{
          ml: 2,
          width: "30rem",
          maxWidth: "25vw"
        }}
      />
      <TableHeaderButton
        startIcon={<FilterListIcon />}
        onClick={handleOpen}
        variant="outlined"
        smallButtons={true}
      >
        {t("buttons.global_search_scope")}
      </TableHeaderButton>
      <Menu
        open={menuState.open}
        onClose={handleClose}
        anchorEl={menuState.anchorEl}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
      >
        {columns.map((c, i) => {
          return c.accessor && c.Header ? (
            <MenuItem value={i} onClick={(evt) => handleToggle(evt, c.accessor as string)} key={i}>
              <Switch
                color="primary"
                checked={hiddenColumnNames ? !hiddenColumnNames.includes(c.accessor as string) : true}
                value={i}
              />
              <Typography variant="caption" style={{ textTransform: "uppercase", fontWeight: 600 }}>
                {
                  c.Header && typeof c.Header === 'function' ?
                    (c.Header as (() => ReactNode))() :
                    c.Header
                }
              </Typography>
            </MenuItem>
          ) : null;
        })}
      </Menu>
    </Stack>
  );
}

export default TableSearchBar;
