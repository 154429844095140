import { IModel } from "@/@types/models/model";

import { coreFields } from "./helpers/coreFields";
import { buttonFields } from "./helpers/buttonFields";

const RasvjetnaTijelaModel: IModel = {
  title: "Rasvjetna tijela",
  source: "rasvjetna_tijela",
  apiPath: "rasvjeta/rasvjetna-tijela",
  forms: {
    default: {
      fields: ["visina", "tip", "zarulja_tip", "zarulja_snaga", "napomena"],
    }
  },
  listViews: {
    default: {
      fields: [
        "id",
        "tip", "visina", "zarulja_tip", "zarulja_snaga", "napomena"
      ],
      hidden: ["id"]
    }
  },
  fields: [
    coreFields.id,
    
    {
      title: "visina",
      source: "visina",
      ttoken: "rasvjeta.visina_svjetiljke",
      type: "numeric"
    },
    {
      title: "Tip svjetiljke",
      source: "tip",
      ttoken: "rasvjeta.tip_svjetiljke",
      type: "text"
    },
    {
      title: "Tip žarulje",
      source: "zarulja_tip",
      ttoken: "rasvjeta.zarulja_tip",
      type: "text"
    },
    {
      title: "Snaga žarulje",
      source: "zarulja_snaga",
      ttoken: "rasvjeta.zarulja_snaga",
      type: "numeric"
    },
    {
      title: "Napomena",
      source: "napomena",
      ttoken: "common.napomena",
      type: "text"
    },
  ]
};

export default RasvjetnaTijelaModel;
