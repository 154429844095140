import React, { useState, useRef } from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI Core Elements
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { Tabs, Tab } from "@mui/material";

//MUI Icons
import CloseIcon from "@mui/icons-material/Close";

//Custom Components
import ModelTable from "@/ui/Table/ModelTable";
import AttributeTableTabPanel from "./AttributeTableTabPanel";

//Types
import { DCRecord } from "@/@types/lib/dataController";
import { SxProps } from "@mui/material";
import { IDataController } from "@/@types/lib/dataController";

export type FetureDescription = {
  object_type: string,
  dc: IDataController
  titleToken: string
}

type RecordsDictionary = {
  [key: string]: DCRecord[]
}

type DCDictionary = {
  [key: string]: IDataController
}

type AttributeTableProps = {
  autohide: boolean;
  records: DCRecord[];
  // dc: IDataController;
  dcDictionary: DCDictionary;
  selectedRecords: DCRecord[];
  onSelectRecord?: (id: number) => void;
  onRemoveRecord?: (id: number) => void;
  onCustomAction?: (action: string, id: number, record?: { [key: string]: any }) => void;
  onClose?: () => void;
  viewName?: string;
};

function AttributeTable(props: AttributeTableProps) {
  const [tabValue, setTabValue] = useState(0);
  const { t } = useTranslation();

  const { autohide, onClose, records, dcDictionary, selectedRecords, onSelectRecord, onRemoveRecord, onCustomAction, viewName } = props;

  const handleRowClick = (id: number) => {
    if (onSelectRecord) {
      onSelectRecord(id);
    }
  };

  const handleRowAction = (action: string, id: number, record?: { [key: string]: any }) => {
    switch (action) {
      case "deselect":
        if (onRemoveRecord) {
          onRemoveRecord(id);
        }
      default:
        if (onCustomAction) {
          onCustomAction(action, id, record);
        }
    }
  };

  const handleChangeTab = (evt: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  

  // Categorize records

  let categorizedRecords: RecordsDictionary = {};

  records.forEach((r: DCRecord) => {
    if (r !== null && r.id !== null) {
      const type = r.id > 1014900000 && r.id < 1014999999 ? "rasvjetna_mjesta" : "objekti";

      if (categorizedRecords.hasOwnProperty(type)) {
        (categorizedRecords[type]).push(r);
      } else {
        categorizedRecords[type] = [r]
      }
    }
  })

  const categoryKeys = Object.keys(categorizedRecords);
  const categoriesCount = categoryKeys.length;
  const firstCategoryKey = categoriesCount > 0 ? categoryKeys[0] : 'objekti';

  const maxRecordsInCategory = Math.max(...categoryKeys.map(key => categorizedRecords[key].length));

  // Calc heights

  const rlen = Array.isArray(records) ? records.length : 0;

  const headerHeight = 0;
  const tabsHeight = 48;
  const tableToolbarHeight = 48;
  const tableHeaderRowHeight = 37;
  const tableRowHeight = 47;

  const fullBoxHeight = tabsHeight + tableToolbarHeight + tableHeaderRowHeight + maxRecordsInCategory * tableRowHeight;
  const constrainedBoxHeight = fullBoxHeight > 400 ? 400 : fullBoxHeight;
  const tableHeight = constrainedBoxHeight - tabsHeight;

  return (
    <>
      {autohide && rlen === 0 ? null : (
        <Box m={0} p={0} style={{ position: "relative", height: constrainedBoxHeight }}>
          <Fab size="small" color="primary" aria-label="close" onClick={onClose} sx={fabStyle as SxProps}>
            <CloseIcon />
          </Fab>
          {categoriesCount > 1 ?
            <>
              <Tabs value={tabValue} onChange={handleChangeTab} aria-label="feature tabs">
                {categoryKeys.map((type, i) => (
                  <Tab key={i} label={t(`titles.${type}`)} {...a11yProps(i)} />
                ))}
              </Tabs>
              {categoryKeys.map((type, i) => (
                <AttributeTableTabPanel key={i} value={tabValue} index={i} style={{height: tableHeight}}>
                  <ModelTable
                    title={null}
                    viewName={viewName || "attribute_table"}
                    records={categorizedRecords[type]}
                    dc={dcDictionary[type]}
                    disableControls={true}
                    showControlSwitch={false}
                    allowSelection="one"
                    allowFilter={false}
                    allowExport={false}
                    allowAdd={false}
                    allowEdit={false}
                    allowColumnPicker={false}
                    allowInc={false}
                    onRowAction={handleRowAction}
                    onRowClick={handleRowClick}
                    allowRowAction={() => {
                      return true;
                    }}
                    rowSelectedCustom={selectedRecords.length > 0 ? (selectedRecords[0].id as number) : undefined}
                  />
                </AttributeTableTabPanel>
              ))}
            </>
            :

            <ModelTable
              title={t(`titles.${firstCategoryKey}`)}
              viewName={viewName || "attribute_table"}
              records={categorizedRecords[firstCategoryKey]}
              dc={dcDictionary[firstCategoryKey]}
              disableControls={true}
              showControlSwitch={false}
              allowSelection="one"
              allowFilter={false}
              allowExport={false}
              allowAdd={false}
              allowEdit={false}
              allowColumnPicker={false}
              allowInc={false}
              onRowAction={handleRowAction}
              onRowClick={handleRowClick}
              allowRowAction={() => {
                return true;
              }}
              rowSelectedCustom={selectedRecords.length > 0 ? (selectedRecords[0].id as number) : undefined}
            />
          }
        </Box>
      )}
    </>
  );
}

const fabStyle = {
  position: "absolute",
  top: 8,
  right: 16,
  zIndex: 1000
};

export default AttributeTable;
