import { IModel } from "@/@types/models/model";
import { buttonFields } from "@/models/helpers/buttonFields";
import { commonFields } from "./helpers/commonFields";

const Objekti: IModel = {
  title: "Objekti",
  source: "objekti",
  apiPath: "objekti/",
  forms : {
    "default": {
      fields: ["obv", "adrobv", "sifobv", "nasobv",
      "adrobj", "nasobj", "ulobj", "kbobj", "lokacija_id", "zap",]
    }
  },
  listViews: {
    "default": {
      fields: ["obj_id", "kat", "stambeno", "poslovno", "garaza", "pomocno", "balk_ter", "z_func_o", "neizgr_gr", "ostalo", "ukupno"],
      hidden: ["id"]
    },
    "tooltip": {
      fields: ["obj_id", {"source": "kat", "tooltip": "objekti.kat_uppercase"}, "stambeno", "poslovno", "garaza", "pomocno", "balk_ter", "z_func_o", "neizgr_gr", "ostalo", "ukupno"],
    },
    "attribute_table": {
      fields: ["id", "obj_id", "adresa_full", "kn_zona", "kn_koef", "ko", "kc", "pre_pt", "broj_katova", "deselect", "btn_show_object", "btn_show_photos"],
      hidden: ["id"]
    },
    "attribute_table_with_3d": {
      fields: ["id", "obj_id", "adresa_full", "kn_zona", "kn_koef", "ko", "kc", "pre_pt", "broj_katova", "deselect", "btn_show_3d", "btn_show_object", "btn_show_photos"],
      hidden: ["id"]
    },
    "processing": {
      fields: ["id", "obj_id", "adresa_full", "kn_zona", "kn_koef", "ko", "kc", "pre_pt", "broj_katova", "btn_show_object"],
      hidden: ["id"]
    },
    "processing_with_3d": {
      fields: ["id", "obj_id", "adresa_full", "kn_zona", "kn_koef", "ko", "kc", "pre_pt", "broj_katova", "btn_show_object", "btn_show_3d"],
      hidden: ["id"]
    }

  },
  fields: [
    {
      title: "id",
      source: "id",
      sort: false,
      ttoken: "common.id",
      tooltip: "objekti.id",
      type: "numeric",
      idattr: true,
      readonly: true,
      search: false
    },
    {
      title: "id",
      source: "obj_id",
      sort: false,
      ttoken: "objekti.id",
      tooltip: "objekti.id",
      type: "numeric",
      search: true,
    },
    {
      title: "Kat",
      source: "kat",
      sort: false,
      ttoken: "objekti.kat",
      tooltip: "objekti.kat",
      type: "numeric",
      search: false
    },
    {
      title: "Stambeno",
      source: "stambeno",
      sort: false,
      ttoken: "objekti.stambeno",
      tooltip: "objekti.stambeno",
      type: "numeric",
      search: false
    },
    {
      title: "Poslovno",
      source: "poslovno",
      sort: false,
      ttoken: "objekti.poslovno",
      tooltip: "objekti.poslovno",
      type: "numeric",
      search: false
    },
    {
      title: "Garaza",
      source: "garaza",
      sort: false,
      ttoken: "objekti.garaza",
      tooltip: "objekti.garaza",
      type: "numeric",
      search: false
    },
    {
      title: "Pomocno",
      source: "pomocno",
      sort: false,
      ttoken: "objekti.pomocno",
      tooltip: "objekti.pomocno",
      type: "numeric",
      search: false
    },
    {
      title: "Balk_ter",
      source: "balk_ter",
      sort: false,
      ttoken: "objekti.balk_ter",
      tooltip: "objekti.balk_ter",
      type: "numeric",
      search: false
    },
    {
      title: "Z_func_o",
      source: "z_func_o",
      sort: false,
      ttoken: "objekti.z_func_o",
      tooltip: "objekti.z_func_o",
      type: "numeric",
      search: false
    },
    {
      title: "Neizgr_gr",
      source: "neizgr_gr",
      sort: false,
      ttoken: "objekti.neizgr_gr",
      tooltip: "objekti.neizgr_gr",
      type: "numeric",
      search: false
    },
    {
      title: "Ostalo",
      source: "ostalo",
      sort: false,
      ttoken: "objekti.ostalo",
      tooltip: "objekti.ostalo",
      type: "numeric",
      search: false
    },
    {
      title: "Ukupno",
      source: "ukupno",
      sort: false,
      ttoken: "objekti.ukupno",
      tooltip: "objekti.ukupno",
      type: "numeric",
      search: false
    },
    {
      title: "Fishnet ID",
      source: "fishnet_id",
      sort: false,
      ttoken: "objekti.fishnet",
      tooltip: "objekti.fishnet",
      type: "numeric",
      readonly: true,
      search: false
    },
    {
      title: "Projekt ID",
      source: "projekt_id",
      sort: false,
      ttoken: "objekti.projekt",
      tooltip: "objekti.projekt",
      type: "numeric",
      readonly: true,
      search: false
    },
    {
      title: "Naselje",
      source: "naselje",
      ttoken: "objekti.naselje",
      type: "text",
      readonly: true,
      search: false
    },
    {
      title: "Ulica",
      source: "ulica",
      ttoken: "objekti.ulica",
      type: "text",
      readonly: true,
      search: false
    },
    {
      title: "kb",
      source: "kb",
      ttoken: "objekti.kb",
      type: "text",
      readonly: true,
      search: false
    },
    {
      title: "adresa_full",
      source: "adresa_full",
      ttoken: "objekti.adresa_objekta",
      type: "text",
      readonly: true,
      search: true
    },
    {
      title: "kn zona",
      source: "kn_zona",
      ttoken: "objekti.kn_zona",
      type: "text",
      readonly: true,
      align: "center",
      search: false
    },
    {
      title: "PRE PT",
      source: "pre_pt",
      ttoken: "objekti.pre_pt",
      type: "numeric",
      readonly: true,
      search: false
    },
    {
      title: "KN Koef",
      source: "kn_koef",
      ttoken: "objekti.kn_koef",
      type: "numeric",
      readonly: true,
      align: "center",
      search: false
    },
    {
      title: "Lokacija ID",
      source: "lokacija_id",
      ttoken: "objekti.lokacija_id",
      type: "numeric",
      readonly: true,
      search: false
    },
    {
      title: "Katastarska opcina",
      source: "ko",
      ttoken: "komunalni_obveznici.ko",
      type: "text",
      sort: true,
      search: true
    },
    {
      title: "Katastarska cestica",
      source: "kc",
      ttoken: "komunalni_obveznici.kc",
      type: "text",
      sort: true,
      search: true
    },
    {
      title: "Broj katova",
      source: "broj_katova",
      ttoken: "objekti.broj_katova",
      type: "numeric",
      sort: true,
      align: "center",
      search: false
    },
    {
      title: "Broj slika",
      source: "broj_slika",
      ttoken: "objekti.broj_slika",
      type: "numeric",
      sort: true,
      search: false
    },
    {
      title: "cx",
      source: "cx",
      ttoken: "",
      type: "numeric",
      sort: true,
      search: false
    },
    {
      title: "cy",
      source: "cy",
      ttoken: "",
      type: "numeric",
      sort: true,
      search: false
    },
    {
      title: "cz",
      source: "cz",
      ttoken: "",
      type: "numeric",
      sort: true,
      search: false
    },
    commonFields.wkt,
    buttonFields.deselect,
    buttonFields.show_3d,
    buttonFields.show_object,
    buttonFields.show_photos

  ]
}

export default Objekti;
