import React, {
  useContext,
  useState,
  useEffect,
  FunctionComponent,
} from "react";
import { useTranslation } from "react-i18next";

// d.ts
import { IDataController, DCRecord } from "@/@types/lib/dataController";

// Custom Components
import ModelTable from "@/ui/Table/ModelTable";
import DialogContext from "@/ui/DialogContext/DialogContext";

import api from "@/lib/api";
import { downloadFile } from "@/lib/downloadFile";
import dataController from "@/lib/dataController";
import { DialogContextType } from "@/@types/ui/DialogContext";
import LoaderContext from "@/components/LoaderContext/LoaderContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";

//Types
import { LoaderContextType } from "@/@types/context/LoaderContext";
import { SnackbarContextType } from "@/@types/ui/SnackbarContext";
import { IModel } from "@/@types/models/model";
import { RowActionFn } from "@/@types/ui/Table";

// TODO:
// subModels cleanup: they are added to the project because Table.jsx can not function without them

interface IDataCentricPrimaryTableProps {
  onRowSelect: (id: number, record: DCRecord) => void;
  onLoad?: (records: Array<DCRecord>) => void;
  lokacijaId?: number;
  filterByLocation?: boolean;
  selectedObjektId?: number;

  model: IModel;
  viewName?: string;
  titleToken: string;
  locationField: string;

  disableControls?: boolean;

  allowExport?: boolean;
  allowLegend?: boolean;
  onLegendClick?: () => void;
  allowFilterToggle?: boolean;

  onRowAction?: RowActionFn;
}

let CHANGED_FLAG = false;

const DataCentricPrimaryTable: FunctionComponent<
  React.PropsWithChildren<IDataCentricPrimaryTableProps>
> = (props) => {
  const dialogContext = useContext<DialogContextType>(DialogContext);
  const loaderContext = useContext(LoaderContext) as LoaderContextType;
  const snackbarContext = useContext(SnackbarContext) as SnackbarContextType;
  const { t } = useTranslation();
  const {
    onRowSelect,
    onLoad,
    lokacijaId,
    filterByLocation,
    disableControls,
    allowLegend,
    onLegendClick,
    allowFilterToggle,
    allowExport,
    onRowAction,
  } = props;
  const { model, viewName, titleToken, locationField } = props;

  const [records, setRecords] = useState<DCRecord[]>([]);
  const [filteredRecords, setFilteredRecords] = useState<DCRecord[]>([]);

  const [zapisnikDl, setZapisnikDl] = useState<{ id: number }>({ id: 0 });
  const [rowSelected, setRowSelected] = useState<DCRecord | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string | undefined>();

  const dc: IDataController = new dataController(model);

  useEffect(() => {
    refreshRecords();
  }, []);

  useEffect(() => {
    const { id } = zapisnikDl;
    if (id === 0) {
      // do error logging
      return;
    }
    const record = records.find((r) => r.id === id);
    if (!record) {
      // do error logging
      return;
    }
    const recordName = (record.obv as string) || "";
    const encodedRecordName = record.obv
      ? encodeURIComponent(record.obv as string)
      : "";
    const apiInstance = new api();
    const path = `${model.apiPath}/${id}/zapisnik?ko_name=${encodedRecordName}`;

    downloadFile(path, undefined, `${recordName}-${id}.pdf`);
    // apiInstance.Download(path).then((file: unknown) => {
    //   console.log("GOT FILE:");
    //   console.log(file);
    // })
  }, [zapisnikDl]);

  const refreshRecords = () => {
    loaderContext.toggleLoading(true);
    dc.GetData()
      .then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          const rs = resp.data.map((r) => ({
            ...r,
            ...{ zapisnik_dl: r.zap === "1" ? true : false },
          }));
          setRecords(rs);
          setFilteredRecords(rs);
          if (resp.data.length === 0) {
            snackbarContext.showNotification(
              "messages.nodata",
              "warning",
              true
            );
          }
        }
      })
      .catch((resp) => {
        console.log(resp);
      })
      .finally(() => {
        loaderContext.toggleLoading(false);
      });
  };

  const handleRowSelect = (id: number) => {
    if (rowSelected === null) CHANGED_FLAG = true;
    const record = records.find((r) => r.id === id);
    if (record !== undefined) {
      const [rLok, rId] =
        rowSelected === null
          ? [-1, -1]
          : [rowSelected[locationField], rowSelected.id];
      if (record.id === rId && record[locationField] === rLok) {
        setRowSelected(null);
        onRowSelect(Number(record[locationField]), record);
      } else if (record.id !== rId && record[locationField] === rLok) {
        setRowSelected(record);
      } else {
        setRowSelected(record);
        onRowSelect(Number(record[locationField]), record);
      }
    } else {
      setRowSelected(null);
    }
  };

  const downloadZapisnik = (koId: number) => {
    setZapisnikDl({ id: koId });
  };

  const handleGlobalFilterChange = (globalFilter: string | undefined) => {
    if (CHANGED_FLAG && !globalFilter) {
      CHANGED_FLAG = false;
    } else {
      setGlobalFilter(globalFilter);
    }
  };

  useEffect(() => {
    if (filterByLocation) {
      if (lokacijaId) {
        const fRecords: DCRecord[] = records.filter(
          (r) => r[locationField] === lokacijaId
        );
        setFilteredRecords(fRecords);
        if (fRecords.length > 0) {
          setRowSelected(fRecords[0]);
          if (onLoad) {
            onLoad(fRecords);
          }
        } else {
          setRowSelected(null);
        }
      } else {
        setFilteredRecords(records);
        setRowSelected(null);
      }
    } else {
      if (lokacijaId) {
        const fRecords: DCRecord[] = records.filter(
          (r) => r[locationField] === lokacijaId
        );
        if (fRecords.length > 0) {
          setRowSelected(fRecords[0]);
        }
      }
    }
  }, [records, lokacijaId]);

  useEffect(() => {
    if (rowSelected !== null) {
      onRowSelect(Number(rowSelected[locationField]), rowSelected);
    }
  }, [globalFilter]);

  // const handleRowAction = (action: string, id: number, record?: { [key: string]: any }) => {
  //   switch (action) {
  //     case "show_photos":
  //       if (id) {
  //         const url =
  //           location.origin +
  //           "/fotodokumentacija-objekata/?rec_id=" +
  //           record?.glavniobj_id;
  //         window.open(url, "_blank");
  //       }
  //     break;
  //     default:
  //       //console.log("Unknown action: " + action);
  //       //console.log(record);
  //       //console.log(id);
  //     break;
  //   }
  // };

  const handleFilterToggle = () => {
    setFilteredRecords(records);
    if (rowSelected) {
      onRowSelect(Number(rowSelected[locationField]), rowSelected);
      setRowSelected(null);
    }
  };

  return (
    <ModelTable
      title={t(titleToken)}
      records={filteredRecords}
      dc={dc}
      viewName={viewName}
      allowSelection="one"
      allowFilter={true}
      allowExport={allowExport ? true : false}
      allowAdd={false}
      allowEdit={false}
      disableControls={disableControls !== undefined ? disableControls : true}
      onRowAction={onRowAction !== undefined ? onRowAction : (id) => {}}
      onRowClick={handleRowSelect}
      initialPageSize={25}
      allowRowAction={() => {
        return true;
      }}
      trackTableState={false}
      customFn={downloadZapisnik}
      unselectRow={true}
      rowSelectedCustom={
        rowSelected !== null && rowSelected.id ? (rowSelected.id as number) : -1
      }
      onGlobalFilterChange={handleGlobalFilterChange}
      customRecordCount={records.length}
      gotoFirstPageOnSelect={false}
      allowLegend={allowLegend !== undefined ? allowLegend : false}
      onLegendClick={onLegendClick ? onLegendClick : undefined}
      allowFilterToggle={
        allowFilterToggle !== undefined ? allowFilterToggle : false
      }
      onFilterToggle={handleFilterToggle}
      isFiltered={records.length !== filteredRecords.length}
    />
  );
};

export default DataCentricPrimaryTable;
