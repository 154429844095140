import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

//MUI
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';

//MUI LAB
import { TreeItemContentProps, useTreeItem } from "@mui/lab/TreeItem";

// Components
import MapContext from "@/components/Map/MapContext";
import OpacitySlider from "@/ui/Slider/OpacitySlider";
import { findLayer } from "@/lib/olHelpers";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { Box, Tooltip } from "@mui/material";

//Openlayers
import OlLayerGroup from "ol/layer/Group";
import OlBaseLayer from "ol/layer/Base";

type LayerTreeItemContentProps = TreeItemContentProps & {
  layer_content?: OlBaseLayer | OlLayerGroup;
}

const LayerTreeItemCustomContent = React.forwardRef(
  function LayerTreeItemCustomContent(props: LayerTreeItemContentProps, ref) {
    const mapContext = useContext(MapContext) as MapContextType;
    const [opacityValue, setOpacityValue] = useState(100);
    const [visible, setVisible] = useState(true);
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
      layer_content
    } = props;
    // @ts-ignore
    const { checked, hasExpandIcon } = props;
    const { t } = useTranslation();

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const opacitySliderItems = [
      "app-dof",
      "app-objekti",
      "app-kuce_za_odmor",
      "app-namjena",
      "app-dsm",
      "app-poplav",
      "app-dem"
    ];
    const hasOpacitySlider = nodeId && opacitySliderItems.includes(nodeId);

    const handleMouseDown = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      preventSelection(event);
    };

    const handleExpansionClick = (event: React.SyntheticEvent) => {
      handleExpansion(event);
    };

    const handleSelectionClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      handleSelection(event);
    };

    function handleChangeOpacity(
      event: Event,
      value: number | Array<number>,
      activeThumb: number
    ) {
      if (typeof value === "number") {
        setOpacityValue(value);
      }
    }

    useEffect(() => {
      // Gets current opacity from mapLayer and sets the opacityValue state. Without this opacity will be set to 100% on every rerender.
      if (mapContext && mapContext.map) {
        const mapLayer = findLayer(mapContext.map, props.nodeId);
        const layerId = mapLayer?.get("id");
        const lastOpacity = mapLayer?.getOpacity();
        if (typeof lastOpacity === "number") {
            setOpacityValue(Math.round(lastOpacity * 100));
        }
      }
    }, []);

    useEffect(() => {
      if (mapContext && mapContext.map) {
        const mapLayer = findLayer(mapContext.map, props.nodeId);
        mapLayer?.setOpacity(opacityValue / 100);
      }
    }, [opacityValue]);

    useEffect(() => {
      checkLayerVisibility();

      const eventHandlerId = mapContext.map?.getView().on('change:resolution', checkLayerVisibility);

      return () => {
        if(eventHandlerId) {
          mapContext.map?.getView().un('change:resolution', checkLayerVisibility);
        }
      }
    }, []);

    const checkLayerVisibility = () => {
      // For debugging:
      // //@ts-ignore
      // console.log(layer_content.get('minZoom'));
      // console.log(mapContext.map?.getView().getZoom());
      // //@ts-ignore
      // console.log(layer_content.get('maxZoom'));
      // console.log('===');
      // //@ts-ignore
      // console.log(layer_content && layer_content.get('minZoom') < mapContext.map?.getView().getZoom() && mapContext.map?.getView().getZoom() < layer_content.get('maxZoom'));
      // console.log('-----------------------------------');

      // @ts-ignore
      if(layer_content && layer_content.get('minZoom') <= mapContext.map?.getView().getZoom() && mapContext.map?.getView().getZoom() <= layer_content.get('maxZoom')) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref as React.Ref<HTMLDivElement>}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        {hasExpandIcon ? (
          <IconButton
            onClick={handleExpansionClick}
            size="small"
            sx={{ minWidth: 38, minHeight: 38 }}
            disabled={icon ? false : true}
          >
            {icon}
          </IconButton>
        ) : null}
        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
        >
          {label}
        </Typography>
        <Box display="flex" flexDirection="row" maxWidth="7rem">
          {hasOpacitySlider ? (
            <OpacitySlider
              opacity={opacityValue}
              checked={checked} // Currently always active
              handleChange={handleChangeOpacity}
            />
          ) : null}
          {!visible ? (
            <Tooltip title={t("tooltips.zoom_level_helper_text") as string}>
              <IconButton style={{paddingLeft: 0, marginLeft: 0, marginRight: 12}} size="small">
                <VisibilityOffTwoToneIcon sx={{color: "grey", paddingLeft: 0, marginLeft: 0}} />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      </div>
    );
  }
);

export default LayerTreeItemCustomContent;
