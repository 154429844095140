import { IFieldButton } from "@/@types/models/model";

const view: IFieldButton = {
  title: "",
  source: "view",
  tooltip: "buttons.view",
  ttoken: "",
  type: "button",
  icon: "open_in_new",
  action: "view",
  padding: "none",
  sort: false,
  search: false
};

const edit: IFieldButton = {
  title: "",
  source: "edit",
  tooltip: "buttons.edit",
  ttoken: "",
  type: "button",
  icon: "edit",
  action: "edit",
  padding: "none",
  sort: false,
  search: false
};

const remove: IFieldButton = {
  title: "",
  source: "remove",
  tooltip: "buttons.remove",
  ttoken: "",
  type: "button",
  icon: "delete_sweep",
  action: "remove",
  padding: "none",
  sort: false,
  search: false
}

const print: IFieldButton = {
  title: "",
  source: "print",
  tooltip: "buttons.print",
  ttoken: "",
  type: "button",
  icon: "print",
  action: "print",
  padding: "none",
  sort: false,
  search: false
}

const download: IFieldButton = {
  title: "",
  source: "download",
  tooltip: "buttons.download",
  ttoken: "",
  type: "button",
  icon: "file_download",
  action: "download",
  padding: "none",
  sort: false,
  search: false
}

const deselect: IFieldButton = {
  title: "",
  source: "deselect",
  tooltip: "buttons.deselect",
  ttoken: "",
  type: "button",
  icon: "deselect",
  action: "deselect",
  padding: "none",
  sort: false,
  search: false
}

const show_3d: IFieldButton = {
  title: "",
  source: "btn_show_3d",
  tooltip: "buttons.show_3d",
  ttoken: "",
  type: "button",
  icon: "public",
  action: "show_3d",
  padding: "none",
  sort: false,
  search: false
}

const show_object: IFieldButton = {
  title: "",
  source: "btn_show_object",
  tooltip: "buttons.show_object",
  ttoken: "",
  type: "button",
  icon: "request_page",
  action: "show_object",
  padding: "none",
  sort: false,
  search: false
}

const show_photos: IFieldButton = {
  title: "",
  source: "btn_show_photos",
  tooltip: "buttons.show_photos",
  ttoken: "",
  type: "button",
  icon: "photo_camera",
  action: "show_photos",
  padding: "none",
  sort: false,
  search: false
}

export const buttonFields = {
  view: view,
  edit: edit,
  remove: remove,
  print: print,
  download: download,
  deselect: deselect,
  show_3d,
  show_object,
  show_photos
};
