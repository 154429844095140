import { Permissions as P } from "@/lib/permissions";
import { MenuType } from "@/@types/common";

const menuRoutes: MenuType[] = [
  { path: "/map", name: "map", ttoken: "menu.map", icon: "map" },
  {
    path: "/3d",
    name: "3D",
    ttoken: "menu.3d",
    icon: "public",
    needPermission: [P.ThreeD],
  },
  {
    path: "/rasvjeta",
    name: "rasvjeta",
    ttoken: "menu.rasvjeta",
    icon: "light",
    needPermission: [P.Rasvjeta],
  },
  {
    path: "/fotodokumentacija-rasvjeta",
    name: "rasvjeta-photo",
    ttoken: "menu.rasvjeta_processing",
    icon: "photo_camera",
    needPermission: [P.Rasvjeta],
  },
  {
    path: "/komunalne-obveze",
    name: "komunalne-obveze",
    ttoken: "menu.komunalne_obveze",
    icon: "request_page",
  },
  {
    path: "/fotodokumentacija-objekata",
    name: "processing",
    ttoken: "menu.processing",
    icon: "photo_camera",
  },
  {
    path: "/admin/users",
    name: "users",
    ttoken: "menu.users",
    icon: "group",
    needPermission: [P.Administration],
  },
  {
    path: "/kuce-za-odmor",
    name: "kuce-za-odmor",
    ttoken: "menu.kuce_za_odmor",
    icon: "house",
    needPermission: [P.ManageKuceZaOdmor],
  },
];

export default menuRoutes;
