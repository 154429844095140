import * as React from "react";
import { FunctionComponent, useContext } from "react";

//Custom Components
import RadioControl from "@/controls/RadioControl";
import MapConfigContext from "@/components/MapConfigContext/MapConfigContext";

//Types
import { MapConfigContexType } from "@/@types/context/MapConfigContext";
import { PickerItem } from "@/@types/controls/controls";
import { PickerItemValue } from "@/@types/models/model";

function prefixLayer(name: string): string {
  return process.env.REACT_APP_GEOSERVERDBWORKSPACE + ":" + name;
}

const GridPane: FunctionComponent<any> = (props) => {
  const mapConfigContext = useContext(MapConfigContext) as MapConfigContexType;

  const fieldGridLayerPicker = {
    title: "Grid Layer",
    source: "gridLayer",
    ttoken: "map.grid",
    type: "radio",
    items: {
      labels: [
        "Isključi",
        "Automatska",
        "100 km x 100 km",
        "50 km x 50 km",
        "10 km x 10 km",
        "2 km x 2 km",
        "1 km x 1 km",
      ],
      values: [
        0,
        prefixLayer("v_grid_auto_combined"),
        prefixLayer("v_grid_100x100_combined"),
        prefixLayer("v_grid_50x50_combined"),
        prefixLayer("v_grid_10x10_combined"),
        prefixLayer("v_grid_2x2_combined"),
        prefixLayer("v_grid_1x1_combined"),
      ],
      default: 0,
    },
  }

  const handleChange = (item: PickerItem | PickerItemValue | null, source: string, setGridLayer: (layer: any) => void): void => {
    if (setGridLayer) {
      //@ts-ignore TODO: value does not exist on type 'string | number | true | PickerItem'
      if (item && item.value) {
        setGridLayer({
          //@ts-ignore TODO: value does not exist on type 'string | number | true | PickerItem'
          title: item.label,
          //@ts-ignore TODO: value does not exist on type 'string | number | true | PickerItem'
          name: item.value
        });
      } else {
        setGridLayer(null);
      }
    }
  }

  return (
    <RadioControl
      //@ts-ignore TODO: 'string' is not assignable to type, yet "radio" is a type
      field={fieldGridLayerPicker}
      formMode="form"
      controlMode="edit"
      value={mapConfigContext && mapConfigContext.layer ? mapConfigContext.layer.name : null}
      onChange={(value, source) =>
        handleChange(value, source, mapConfigContext.setGridLayer)
      }
    />
  );
};

export default GridPane;
