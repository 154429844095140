import { IModel } from "@/@types/models/model";

const DEM: IModel = {
  title: "DEM",
  apiPath: "DEM",
  forms : {
    "default": {
      fields: ["DEM"]
    }
  },
  listViews: {
    "default": {
      fields: ["DEM"]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      type: "numeric",
      idattr: true,
      readonly: true
    },
    {
      title: "GRAY_INDEX",
      source: "DEM",
      ttoken: "common.visina",
      type: "numeric",
      format: ".2m",
      readonly: true
    }
  ]
}

export default DEM;
