import React, { MutableRefObject, MouseEvent, useState, useEffect } from "react";

import Crop from "@/lib/crop";

export type CanvasProps = {
  baseDraw: () => void;
  onStartDrawing: () => void;
  onDrawZoomBox: (x: number, y: number, w: number, h: number) => void;
  onDrawCropBox: (x: number, y: number, w: number, h: number) => void;
  onWheelDown: () => void;
  onCanvasClick: (coords: Point) => void;
  viewOtherObjects: boolean;
  drawClip: Clip | null;
  containerWidth: number;
  containerHeight: number;
}

type Clip = [number, number, number, number];
type Pair<A, B> = [A, B];
type Point = [number, number];

const MIN_WIDTH = 400;
const MIN_DRAW_SIZE = 25;
const MIN_CROP_SIZE = 400;
const MIN_ZOOM_WIDTH = 300;

const Canvas = React.forwardRef<HTMLCanvasElement, CanvasProps>((props, ref) => {

  const [startCoordinates, setStartCoordinates] = useState<Point | null>(null);
  const [currentCoordinates, setCurrentCordinates] = useState<Point | null>(null);
  const [polygon, setPolygon] = useState<[Point, Point] | null>(null);
  const [isZooming, setIsZooming] = useState(false);
  const [isCropping, setIsCroping] = useState(false);
  const [cropSizeOk, setCropSizeOk] = useState(false);
  const [zoomSizeOk, setZoomSizeOk] = useState(false);

  const {
    baseDraw,
    onStartDrawing,
    onDrawZoomBox,
    onDrawCropBox,
    onWheelDown,
    onCanvasClick,
    viewOtherObjects,
    drawClip,
    containerWidth,
    containerHeight,
  } = props;

  const getClickCoordinates = (evt: MouseEvent<HTMLCanvasElement>) => {
    if (ref) {
      const rect = (ref as MutableRefObject<HTMLCanvasElement>).current.getBoundingClientRect();
      const x = evt.clientX - rect.left;
      const y = evt.clientY - rect.top;

      return [x, y] as Point;
    } else {
      return [0, 0] as Point
    }
  }

  const calculateBox = (start: Point, end: Point) => {
    const minX = Math.min(start[0], end[0]);
    const maxX = Math.max(start[0], end[0]);
    const minY = Math.min(start[1], end[1]);
    const maxY = Math.max(start[1], end[1]);

    const p1 = [minX, minY];
    const p2 = [maxX, minY];
    const p3 = [maxX, maxY];
    const p4 = [minX, maxY];
    const p5 = p1;

    return [p1, p2, p3, p4, p5];
  }

  const getCtx = () => {
    let ctx: CanvasRenderingContext2D | null = null;
    const hasCanvas = ref && (ref as MutableRefObject<HTMLCanvasElement>).current;
    if (hasCanvas) {
      ctx = (ref as MutableRefObject<HTMLCanvasElement>).current.getContext('2d');
    }
    return ctx;
  }

  const handleMouseDown = (evt: MouseEvent<HTMLCanvasElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    const coords = getClickCoordinates(evt);
    setStartCoordinates(coords);

    const modifierKeyPressed = evt.getModifierState("Shift")
    if (modifierKeyPressed) {
      setIsCroping(true);
    } else {
      setIsZooming(true);
    }

    const ctx = getCtx();
    const canvas = (ref as MutableRefObject<HTMLCanvasElement>).current;
    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      baseDraw();
    }

    onStartDrawing();
  }

  const handleClick = (evt: MouseEvent<HTMLCanvasElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    const coords = getClickCoordinates(evt);
    onCanvasClick(coords);
  }

  const handleMouseMove = (evt: MouseEvent<HTMLCanvasElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (isZooming || isCropping) {
      const coords = getClickCoordinates(evt);
      setCurrentCordinates(coords);
    }
  }

  const handleMouseUp = (evt: MouseEvent<HTMLCanvasElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    if ((isZooming || isCropping) && startCoordinates) {
      const coords = getClickCoordinates(evt);
      const ctx = getCtx();
      const canvas = (ref as MutableRefObject<HTMLCanvasElement>).current;
      if (ctx && startCoordinates && currentCoordinates && startCoordinates[0] !== coords[0] && startCoordinates[1] !== coords[1]) {

        const w = currentCoordinates[0] - startCoordinates[0];
        const h = currentCoordinates[1] - startCoordinates[1];

        if (isZooming) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          baseDraw();
          // setPolygon([startCoordinates, coords]);

          const crop = new Crop(startCoordinates[0], startCoordinates[1], w, h);
          crop.keepRatio(1.5);
          if (zoomSizeOk) {
            onDrawZoomBox(crop.x, crop.y, crop.w, crop.h);
          }

        } else if (isCropping) {

          const crop = new Crop(startCoordinates[0], startCoordinates[1], w, h);
          crop.makeSquare();
      
          crop.shrinkToFit(canvas.width, canvas.height);

          const startPoint = [crop.x, crop.y] as Point;
          const endPoint = [crop.x + crop.w, crop.y + crop.h] as Point;

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          baseDraw();
          if (cropSizeOk) {
            setPolygon([startPoint, endPoint]);
            onDrawCropBox(crop.x, crop.y, crop.w, crop.h);
          }
        }

      }

      if (isZooming) {
        setIsZooming(false);
      }
      if (isCropping) {
        setIsCroping(false);
      }
    }
  }

  const calculateRealCropSize = (x: number, y: number, w: number, h: number, currentDrawClip: Clip) => {
    if (currentDrawClip) {
      // console.log(x, y, w, h, image.width, image.height, IMAGE_WIDTH, IMAGE_HEIGHT);

      const [bx, by, ex, ey] = currentDrawClip;
      const clip_width = ex - bx;
      const clip_height = ey - by;

      // CROP relative coordinates
      const zoom_start_x = Math.floor(x);
      const zoom_start_y = Math.floor(y);
      const zoom_end_x = Math.floor(x + w);
      const zoom_end_y = Math.floor(y + h);

      // CLIP relative coordinates
      const clip_start_x = Math.floor((zoom_start_x / containerWidth) * (clip_width as number));
      const clip_start_y = Math.floor((zoom_start_y / containerHeight) * (clip_height as number));
      const clip_end_x = Math.floor((zoom_end_x / containerWidth) * (clip_width as number));
      const clip_end_y = Math.floor((zoom_end_y / containerHeight) * (clip_height as number));

      // IMAGE coordinates
      const real_start_x = bx + clip_start_x;
      const real_start_y = by + clip_start_y;
      const real_end_x = bx + clip_end_x;
      const real_end_y = by + clip_end_y;

      const real_width = real_end_x - real_start_x;
      const real_height = real_end_y - real_start_y;

      return [real_width, real_height]
    }
    else {
      return null;
    }
  }

  const handleMouseWheel = (evt: any) => {
    //@ts-ignore
    if (evt.deltaY > 0) {
      onWheelDown();
    }
  }

  useEffect(() => {
    const ctx = getCtx();
    const canvas = (ref as MutableRefObject<HTMLCanvasElement>).current;
    if (ctx && startCoordinates && currentCoordinates) {

      // calculate size of the polygon
      const w = currentCoordinates[0] - startCoordinates[0];
      const h = currentCoordinates[1] - startCoordinates[1];

      const absW = Math.abs(w);
      const absH = Math.abs(h);

      const crop = new Crop(startCoordinates[0], startCoordinates[1], w, h);

      if (absW >= MIN_DRAW_SIZE || absH >= MIN_DRAW_SIZE) {

        if (isZooming) {
          crop.keepRatio(1.5);
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          baseDraw();
          drawRect(ctx, crop.x, crop.y, crop.w, crop.h);
        } else if (isCropping) {
          crop.makeSquare();
      
          crop.shrinkToFit(canvas.width, canvas.height);
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          baseDraw();
          drawRect(ctx, crop.x, crop.y, crop.w, crop.h);
        } 
      }

      if (drawClip) {
        const realCropSize = calculateRealCropSize(crop.x, crop.y, crop.w, crop.h, drawClip);
        if (realCropSize) {
          const realCropWidth = realCropSize[0];
          const realCropHeight = realCropSize[1];

          if (isCropping) {
            if (realCropHeight < MIN_CROP_SIZE || realCropWidth < MIN_CROP_SIZE) {
              setCropSizeOk(false);
            } else {
              setCropSizeOk(true);
            }
          } else if (isZooming) {
            if (realCropWidth < MIN_ZOOM_WIDTH) {
              setZoomSizeOk(false);
            } else {
              setZoomSizeOk(true);
            }
          }
        }
      }
    }
  }, [currentCoordinates]);

  useEffect(() => {
    // console.log(polygon);

    const ctx = getCtx();
    if (ctx && polygon) {
      const canvas = (ref as MutableRefObject<HTMLCanvasElement>).current

      const [a, b] = polygon;
      const w = b[0] - a[0];
      const h = b[1] - a[1];
      const crop = new Crop(a[0], a[1], w, h);
      crop.makeSquare();
      crop.shrinkToFit(canvas.width, canvas.height);
      drawRect(ctx, crop.x, crop.y, crop.w, crop.h);
      // ctx.drawImage(current, 0, 0);
    }
  }, [polygon]);

  const drawRect = (ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number) => {
    ctx.lineWidth = 2;
    if (isCropping) {
      ctx.setLineDash([6]);
    }
    ctx.strokeStyle = isZooming ? (zoomSizeOk ? "#FFF" : "#F00") : isCropping ? (cropSizeOk ? "#0F0" : "#F00") : "#FFF";
    ctx.strokeRect(x, y, w, h);
  }

  return (
    <canvas
      ref={ref}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onWheel={handleMouseWheel}
      onClick={handleClick}
    />
  )
});

export default Canvas;