import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  SyntheticEvent,
  FunctionComponent,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import OlFormatWKT from "ol/format/WKT";

//MUI
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Toolbar from "@mui/material/Toolbar";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

//Custom Components
import DraggableDialog from "@/ui/Dialog/DraggableDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogActionButtonClose from "@/ui/Dialog/ActionButtons/DialogActionButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import imgService from "@/services/imgService";
import Canvas from "./Canvas";

//Types
import { ClosingDetails } from "@/@types/components/formController";
import { IDialogProps } from "@/@types/ui/DialogContext";
import { DialogContextType } from "@/@types/ui/DialogContext";

import { DCRecord } from "@/@types/lib/dataController";
import UserContext from "@/components/UserContext/UserContext";

type Clip = [number, number, number, number];
type Ratio = [number, number];
type Pair<A, B> = [A, B];
type Coordinates = Pair<number, number>[] | null;

const PT_RADIUS = 5;
const IMAGE_WIDTH = 800;
const IMAGE_HEIGHT = IMAGE_WIDTH * 0.6666;
const MIN_WIDTH = 400;

const PhotoCentricImageDialog: FunctionComponent<IDialogProps> = (props) => {
  const dialogContext = useContext(DialogContext) as DialogContextType;
  const snackbarContext = useContext(SnackbarContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation();
  const [b64, setB64] = useState<string>("");
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { record, baseRecordPath, baseRecordId, onClose } = props;

  const [zoomClip, setZoomClip] = useState<Clip | null>(null);
  const [fullB64, setFullB64] = useState<string>("");
  const [initialB64, setInitialB64] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const imageId: number | null = record ? (record.id as number) : null;

  const handleClose = (evt: SyntheticEvent) => {
    close({ dataChanged: false, action: "cancel" });
  };

  const close = (result: ClosingDetails) => {
    if (result.dataChanged) {
      onClose({ dataChanged: true, action: result.action });
    }

    dialogContext.hideDialog();
  };

  const getCoordinates = (wkt: string) => {
    let coords: Coordinates = null;
    try {
      const WKT = new OlFormatWKT();
      const geom = WKT.readGeometry(wkt);
      //@ts-ignore
      coords = geom.getCoordinates()[0];
    } catch (err) {
      // pass
    }
    return coords;
  };

  const getClip = (coords: Coordinates) => {
    // let clip: number[] | null = null;
    const { width, height } = record;
    if (coords) {
      let minX = width * 2;
      let minY = height * 2;
      let maxX = -minX;
      let maxY = -minY;
      coords.forEach(([x, y]) => {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
      });

      const dx = maxX - minX;
      const dy = maxY - minY;
      let pad = Math.max(dx, dy);
      if (2 * pad < MIN_WIDTH) {
        pad = MIN_WIDTH - pad;
      }
      const bx = minX - pad / 2;
      const by = minY - pad / 2;
      const ex = maxX + pad / 2;
      const ey = maxY + pad / 2;

      // pan rect inside the image
      const incX = bx < 0 ? -bx : 0;
      const incY = by < 0 ? -by : 0;
      const decX = ex > width ? ex - width : 0;
      const decY = ey > height ? ey - height : 0;

      // or dont pan
      // const incX = 0;
      // const incY = 0;
      // const decX = 0;
      // const decY = 0;

      return [bx, by, ex, ey].map((n, i) => {
        if (i % 2 === 0) {
          // x
          return Math.round(n + incX - decX);
        } else {
          // y
          return Math.round(n + incY - decY);
        }
      }) as Clip;
    }

    const { objpx, objpy } = record;
    if (objpx && objpy) {
      const ratio = height / width;
      const sz = width / 8;
      const bx = objpx - sz;
      const by = objpy - sz * ratio;
      const ex = objpx + sz;
      const ey = objpy + sz * ratio;

      const incX = bx < 0 ? -bx : 0;
      const incY = by < 0 ? -by : 0;
      const decX = ex > width ? ex - width : 0;
      const decY = ey > height ? ey - height : 0;

      return [bx, by, ex, ey].map((n, i) => {
        if (i % 2 === 0) {
          // x
          return Math.round(n + incX - decX);
        } else {
          // y
          return Math.round(n + incY - decY);
        }
      }) as Clip;
    }

    return [0, 0, width, height] as Clip;
  };

  const getRatio = (clip: Clip, w: number, h: number) => {
    const [bx, by, ex, ey] = clip;
    const width = ex - bx;
    const height = ey - by;
    const wRatio = w / width;
    const hRatio = h / height;
    return [wRatio, hRatio] as Ratio;
  };

  const drawPolygon = (ctx: CanvasRenderingContext2D, w: number, h: number) => {
    const { objpoly /*width, height*/ } = record;
    // console.log(objpoly)
    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.strokeStyle = "#00FF00";

    if (!record.objpoly) {
      return;
    }
    const coords = getCoordinates(objpoly);
    if (!coords) {
      return;
    }
    const clip = getClipNew();
    const [bx, by, ex, ey] = clip;
    const [wRatio, hRatio] = getRatio(clip, w, h);

    for (let i = 0; i < coords.length; ++i) {
      const [_u, _v] = coords[i];
      const u = (_u - bx) * wRatio;
      const v = (_v - by) * hRatio;
      if (i === 0) {
        ctx.moveTo(u, v);
      }
      // ctx.setLineDash([5, 15]);
      ctx.lineTo(u, v);
    }

    ctx.closePath();
    ctx.stroke();
  };

  const drawPoint = (ctx: CanvasRenderingContext2D, w: number, h: number) => {
    const { objpx, objpy, objpoly } = record;
    // console.log(record)
    const coords = getCoordinates(objpoly);
    const clip = getClipNew();
    const [bx, by, ex, ey] = clip;
    const [wRatio, hRatio] = getRatio(clip, w, h);

    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#FF0000";
    ctx.fillStyle = "#FF0000";

    const u = (objpx - bx) * wRatio;
    const v = (objpy - by) * hRatio;
    ctx.arc(u, v, PT_RADIUS, 0, 2 * Math.PI);

    ctx.fill();
  };

  const onImgLoad = () => {
    let _ctx: CanvasRenderingContext2D | null = null;
    const hasCanvas = canvasRef && canvasRef.current;
    if (hasCanvas) {
      _ctx = canvasRef.current.getContext("2d");
    }
    if (_ctx === null) {
      return;
    }
    const hasImg = imgRef && imgRef.current;
    const ctx: CanvasRenderingContext2D = _ctx as CanvasRenderingContext2D;
    if (hasCanvas && hasImg) {
      canvasRef.current.width = imgRef.current.width;
      canvasRef.current.height = imgRef.current.height;
      ctx.drawImage(imgRef.current, 0, 0);

      drawPolygon(ctx, imgRef.current.width, imgRef.current.height);
      drawPoint(ctx, imgRef.current.width, imgRef.current.height);
    }
  };

  // useEffect(() => {
  //   const { id: imageId, objpoly, width, height } = record;
  //   const coords = getCoordinates(objpoly);
  //   const clip: Clip = getClipNew();
  //   imgService.getObjImage(baseRecordPath, baseRecordId, imageId, IMAGE_WIDTH, clip).then(resp => {
  //     if (resp.success) {
  //       //@ts-ignore
  //       setB64(resp.data.image);
  //     }
  //   });
  // }, []);

  /***************************************************************** New functions ************************************************************************/
  const handleZoomInitial = () => {
    if (record.id !== null) {
      //@ts-ignore
      const { objpoly } = record;
      const coords = getCoordinates(objpoly);

      setZoomClip(calculateClip(coords));
    }
  };

  const handleZoomOut = () => {
    if (record) {
      setZoomClip([0, 0, record.width as number, record.height as number]);
    }
  };

  const getClipNew = () => {
    if (zoomClip !== null) {
      return zoomClip;
    } else if (record !== null) {
      return [0, 0, record.width, record.height] as Clip;
    } else {
      return [0, 0, 0, 0] as Clip;
    }
  };

  const calculateClip = (coords: Coordinates) => {
    // calculate based on polygon of the object (i.e. coords)

    //@ts-ignore
    const { width, height } = record;
    const ratio = height / width;

    // THIS IS JUST A TEMPORARY FIX until Jan fixes objpoly on Brestovac (proj_id = 25)
    if (coords && userContext?.projektId !== 25) {
      let minX = width * 2;
      let minY = height * 2;
      let maxX = -minX;
      let maxY = -minY;
      coords.forEach(([x, y]) => {
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x);
        maxY = Math.max(maxY, y);
      });

      const dx = maxX - minX;
      const dy = maxY - minY;
      let pad = Math.max(dx, dy);
      if (2 * pad < MIN_WIDTH) {
        pad = MIN_WIDTH - pad;
      }
      let bx = minX - pad / 2;
      let by = minY - pad / 2;
      let ex = maxX + pad / 2;
      let ey = maxY + pad / 2;

      //fix ratio
      const sizeX = ex - bx;
      const sizeY = ey - by;
      const maxSize = Math.max(sizeX, sizeY);
      if (maxSize === sizeX) {
        const correctHeight = sizeX * ratio;
        const padHeight = Math.floor((correctHeight - sizeY) / 2);
        by = by - padHeight;
        ey = ey + padHeight;
      } else if (maxSize === sizeY) {
        const correctWidth = sizeY / ratio;
        const padWidth = Math.floor((correctWidth - sizeX) / 2);
        bx = bx - padWidth;
        ex = ex + padWidth;
      }

      // pan rect inside the image
      const incX = bx < 0 ? -bx : 0;
      const incY = by < 0 ? -by : 0;
      const decX = ex > width ? ex - width : 0;
      const decY = ey > height ? ey - height : 0;

      // or dont pan
      // const incX = 0;
      // const incY = 0;
      // const decX = 0;
      // const decY = 0;

      return [bx, by, ex, ey].map((n, i) => {
        if (i % 2 === 0) {
          // x
          return Math.round(n + incX - decX);
        } else {
          // y
          return Math.round(n + incY - decY);
        }
      }) as Clip;
    }

    // else: calculate based on red dot

    //@ts-ignore
    const { objpx, objpy } = record;
    if (objpx && objpy) {
      const sz = width / 8;
      const bx = objpx - sz;
      const by = objpy - sz * ratio;
      const ex = objpx + sz;
      const ey = objpy + sz * ratio;

      // pan rect inside the image
      const incX = bx < 0 ? -bx : 0;
      const incY = by < 0 ? -by : 0;
      const decX = ex > width ? ex - width : 0;
      const decY = ey > height ? ey - height : 0;

      return [bx, by, ex, ey].map((n, i) => {
        if (i % 2 === 0) {
          // x
          return Math.round(n + incX - decX);
        } else {
          // y
          return Math.round(n + incY - decY);
        }
      }) as Clip;
    }

    // else: take the whole image

    return [0, 0, width, height] as Clip;
  };

  const calculateRealCoordinates = (
    x: number,
    y: number,
    w: number,
    h: number,
    currentZoomClip: Clip,
    img: DCRecord
  ) => {
    if (currentZoomClip && img && img.width && img.height) {
      // console.log(x, y, w, h, image.width, image.height, IMAGE_WIDTH, IMAGE_HEIGHT);

      const [bx, by, ex, ey] = currentZoomClip;
      const clip_width = ex - bx;
      const clip_height = ey - by;

      // CROP relative coordinates
      const zoom_start_x = Math.floor(x);
      const zoom_start_y = Math.floor(y);
      const zoom_end_x = Math.floor(x + w);
      const zoom_end_y = Math.floor(y + h);

      // CLIP relative coordinates
      const clip_start_x = Math.floor(
        (zoom_start_x / IMAGE_WIDTH) * (clip_width as number)
      );
      const clip_start_y = Math.floor(
        (zoom_start_y / IMAGE_HEIGHT) * (clip_height as number)
      );
      const clip_end_x = Math.floor(
        (zoom_end_x / IMAGE_WIDTH) * (clip_width as number)
      );
      const clip_end_y = Math.floor(
        (zoom_end_y / IMAGE_HEIGHT) * (clip_height as number)
      );

      // IMAGE coordinates
      const real_start_x = bx + clip_start_x;
      const real_start_y = by + clip_start_y;
      const real_end_x = bx + clip_end_x;
      const real_end_y = by + clip_end_y;

      return [real_start_x, real_start_y, real_end_x, real_end_y] as Clip;
    } else {
      return null;
    }
  };

  const handleStartDrawing = () => {};

  const handleDrawZoomBox = (x: number, y: number, w: number, h: number) => {
    if (zoomClip && record) {
      const newZoomClip = calculateRealCoordinates(
        x,
        y,
        w,
        h,
        zoomClip,
        record
      );
      if (newZoomClip) {
        setZoomClip(newZoomClip);
      }
    }
  };

  useEffect(() => {
    if (record.id !== null) {
      //@ts-ignore
      const { objpoly } = record;
      const coords = getCoordinates(objpoly);
      setZoomClip(calculateClip(coords));
      setFullB64("");
      setInitialB64("");
    }
  }, [imageId]);

  useEffect(() => {
    if (baseRecordId && record && record.id && zoomClip !== null) {
      const reqClip = getClipNew();
      const isFullImage =
        reqClip[0] === 0 &&
        reqClip[1] === 0 &&
        reqClip[2] === record.width &&
        reqClip[3] === record.height
          ? true
          : false;

      const { objpoly } = record;
      //@ts-ignore
      const coords = getCoordinates(objpoly);
      const initialClip = calculateClip(coords);
      const isInitialImage =
        reqClip[0] === initialClip[0] &&
        reqClip[1] === initialClip[1] &&
        reqClip[2] === initialClip[2] &&
        reqClip[3] === initialClip[3]
          ? true
          : false;

      if (isFullImage && fullB64 !== "") {
        setB64(fullB64);
      } else if (isInitialImage && initialB64 !== "") {
        setB64(initialB64);
      } else if (
        (isFullImage && fullB64 === "") ||
        (isInitialImage && initialB64 === "") ||
        (!isFullImage && !isInitialImage)
      ) {
        setIsLoading(true);
        imgService
          .getObjImage(
            baseRecordPath,
            baseRecordId,
            record.id as number,
            IMAGE_WIDTH,
            reqClip
          )
          .then((resp) => {
            if (resp.success) {
              //@ts-ignore
              setB64(resp.data.image);
              if (isFullImage) {
                setFullB64(resp.data.image);
              }
              if (isInitialImage) {
                setInitialB64(resp.data.image);
              }
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [zoomClip]);

  const zoomedOutFully =
    record &&
    zoomClip &&
    zoomClip[0] === 0 &&
    zoomClip[1] === 0 &&
    zoomClip[2] === record.width &&
    zoomClip[3] === record.height;

  const imageLoaded = !isLoading && record && b64.length ? true : false;

  return (
    <DraggableDialog
      open={true}
      maxWidth={"md"}
      onClose={handleClose}
      PaperProps={{ sx: { width: IMAGE_WIDTH } }}
    >
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
          <DialogToolbarHeading>{t("titles.image")}</DialogToolbarHeading>
          <ToolbarFillContent />

          <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogContent sx={{ p: 0, width: IMAGE_WIDTH }}>
        {imageLoaded ? (
          <Fragment>
            <img
              ref={imgRef}
              src={`data:image/png;base64, ${b64}`}
              style={{ display: "none" }}
              onLoad={() => onImgLoad()}
            />
            {/*<canvas ref={canvasRef} style={{ margin: "16px" }}/>*/}
            <Canvas
              ref={canvasRef}
              baseDraw={onImgLoad}
              onStartDrawing={handleStartDrawing}
              onDrawZoomBox={handleDrawZoomBox}
              onDrawCropBox={/*handleDrawCropBox*/ () => {}}
              onWheelDown={handleZoomOut}
              onCanvasClick={/*handleCanvasClick*/ () => {}}
              viewOtherObjects={false}
              drawClip={zoomClip}
              containerWidth={IMAGE_WIDTH}
              containerHeight={IMAGE_HEIGHT}
            />

            <Tooltip title={t("buttons.zoom_object") as string}>
              <Fab
                color="primary"
                size="small"
                sx={{ position: "absolute", top: 96, right: 24 }}
                onClick={handleZoomInitial}
              >
                <CenterFocusWeakIcon />
              </Fab>
            </Tooltip>
            <Tooltip title={t("buttons.zoom_out") as string}>
              <Fab
                color="primary"
                size="small"
                sx={{ position: "absolute", bottom: 76, right: 24 }}
                onClick={handleZoomOut}
                disabled={Boolean(zoomedOutFully)}
              >
                <ZoomOutIcon />
              </Fab>
            </Tooltip>
          </Fragment>
        ) : (
          <Skeleton
            variant="rectangular"
            width={IMAGE_WIDTH}
            height={IMAGE_HEIGHT}
            animation="wave"
            style={{ display: "inline-block" }}
          />
        )}
      </DialogContent>
      <DialogActions>
        {imageLoaded ? (
          <Typography variant="caption">{t("messages.how_to_zoom")}</Typography>
        ) : null}
        <DialogActionButtonClose variant="outlined" onClick={handleClose} />
      </DialogActions>
    </DraggableDialog>
  );
};

export default PhotoCentricImageDialog;
