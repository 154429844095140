import React, { useContext, useRef } from "react";

//Custom Components
import DataCentricPage from "@/views/DataCentric/DataCentricPage";
import modelKomunalniObveznici from "@/models/komunalni_obveznici";
import modelObjekti from "@/models/objekti";
import { Permissions as P } from "@/lib/permissions";
import { DialogContextType } from "@/@types/ui/DialogContext";
import DialogContext from "@/ui/DialogContext/DialogContext";
import dataController from "@/lib/dataController";
import modelStatusi from "@/models/obveznici_statusi";
import { DCRecord } from "@/@types/lib/dataController";
import StatusDialog from "./KomunalneStatusDialog";


type KomunalniObvezniciDataCentricPageParams = {};

function KomunalniObvezniciDataCentricPage(
  props: KomunalniObvezniciDataCentricPageParams
) {
  const dialogContext = useContext<DialogContextType>(DialogContext);

  const showStatusDialog = () => {
    let dcStatusi = new dataController(
      modelStatusi,
      "komunalni-obveznici/statusi"
    );
    dcStatusi
      .GetData()
      .then((resp) => {
        if (
          resp.success &&
          resp.data &&
          resp.data !== null &&
          Array.isArray(resp.data)
        ) {
          // statusFields.current = resp.data.slice();
          // console.log(resp.data);
          dialogContext.showDialog(StatusDialog, {
            fields: resp.data as DCRecord[],
          });
        }
      })
      .catch((resp) => console.log(resp));
  };

  const handleCustomAction = (action: string, id: number, record?: { [key: string]: any }) => {
    switch (action) {
      case "show_photos":
        if (id && record?.glavniobj_id) {
          const url = location.origin + "/fotodokumentacija-objekata/?rec_id=" + record?.glavniobj_id;
          window.open(url, "_blank");
        }
    }
  };

  return (
    <DataCentricPage
      needPermission={[P.ManageKN]}
      primaryModel={modelKomunalniObveznici}
      primaryTitleToken="titles.komunalne_obveze"
      primaryRecordLocationField="lokacija_id"
      onCustomPrimaryTableAction={handleCustomAction}
      disableControlsPrimary={false}
      allowFilterTogglePrimary={true}
      secondaryModel={modelObjekti}
      secondaryTitleToken="titles.objekti_na_lokaciji"
      baseRecordPath={modelKomunalniObveznici.apiPath}
      childRecordRelativePath="objekti"
      geomRelativePath="objekti-geom"
      allowShowImages={true}
      mapId={21}
      mapModel={modelObjekti}
      filterByLocation={true}
      allowLegend={true}
      onLegendClick={showStatusDialog}
    />
  );
}

export default KomunalniObvezniciDataCentricPage;
