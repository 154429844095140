import React, { useContext} from "react";

//Custom
import MapContext from "@/components/Map/MapContext";
import TileLayer from "@/components/Map/Layers/TileLayer";
import ImageLayer from "@/components/Map/Layers/ImageLayer";

//OL
import OlBaseLayer from "ol/layer/Base";
import OlTileLayer from "ol/layer/Tile";
import OlImageLayer from "ol/layer/Image";
import OlImageTile from "ol/ImageTile";

import OlTileWMSSource from "ol/source/TileWMS";
import OlWMTSTileGrid from "ol/tilegrid/WMTS";
import OlWMTSSource from "ol/source/WMTS";
import OlTMSSource from "ol/source/XYZ";

//Types
import TileState from "ol/TileState";


const gs_url = process.env.REACT_APP_GEOSERVERPATH;

function GeoPortalGSLayer(props) {
  const { id, layer, ...otherProps } = props;
  const mapContext = useContext(MapContext);

  const checkIfLayerVisible = () =>  {
    if (props.visible !== undefined) {
      return props.visible;
    } else {
      const mapLayerVisibility = mapContext.getLayerVisibility() || {};
      if (mapLayerVisibility.hasOwnProperty(id)) {
        return mapLayerVisibility[id];
      } else {
        return true;
      }
    }
  }

  // const customLoader = (tile, src) => {
  //   const token = localStorage.getItem('auth_token')
  //   // console.log(tile.getTileCoord());
  //   var xhr = new XMLHttpRequest();
  //   xhr.responseType = 'blob';
  //   xhr.addEventListener('loadend', function (evt) {
  //     var data = this.response;
  //     if (data !== undefined) {
  //       tile.getImage().src = URL.createObjectURL(data);
  //     } else {
  //       tile.setState(TileState.ERROR);
  //     }
  //   });
  //   xhr.addEventListener('error', function () {
  //     tile.setState(TileState.ERROR);
  //   });
  //   xhr.open('GET', src);
  //   if (token) {
  //     xhr.setRequestHeader("Authorization", "Bearer " + token)
  //   }
  //   xhr.send();
  // }

  const visible = checkIfLayerVisible();

  const useCache = layer.get("use_cache");

  const isTileLayer = layer instanceof OlTileLayer;
  const isImageLayer = layer instanceof OlImageLayer;

  const isTileWMSSource = isTileLayer ? layer.getSource() instanceof OlTileWMSSource : false;
  const isTileWMTSSource = isTileLayer ? layer.getSource() instanceof OlWMTSSource : false;
  const isTileTMSSource = isTileLayer ? layer.getSource() instanceof OlTMSSource : false;


  return isTileLayer ?
    <TileLayer
      id={id}
      {...otherProps}
      visible={visible}
      gsLayer={layer}
      zIndex={layer.get("z_index")}
      extent={layer.get("extent")}
      wms={ isTileWMSSource ? {
        url: gs_url,
        params: {
          LAYERS: layer.get("layer"),
          FORMAT: layer.get("format"),
          TILED: layer.get("tiled"),
          STYLES: layer.get("styles"),
          VIEWPARAMS:  useCache ? undefined : ("token:" + localStorage.getItem('auth_token')),
          authkey: localStorage.getItem('auth_token')
        },
        // tileLoadFunction: customLoader
      } : undefined }
      wmts={
        isTileWMTSSource
          ? {
              url: layer.get("url"),
              layer: layer.get("layer"),
              style: layer.get("styles"),
              format: layer.get("format"),
              matrixSet: (layer.getSource()).getMatrixSet(),
              tileGrid: (layer.getSource()).getTileGrid(),
              tileLoadFunction: (imageTile, src) => {
                imageTile.getImage().src = src + "&authkey=" + localStorage.getItem("auth_token");
              }
            }
          : undefined
      }
      tms={
        isTileTMSSource
          ? {
              url: layer.get("url")
            }
          : undefined
      }
    />
    : isImageLayer ?
    <ImageLayer
      id={id}
      opacity={id === "app-objekti" ? 0.7 : 1}
      visible={visible}
      zIndex={layer.get("z_index")}
      extent={layer.get("extent")}
      wms={{
        url: gs_url,
        serverType: "geoserver",
        ratio: 1.2,
        params: {
          LAYERS: layer.get("layer"),
          FORMAT: layer.get("format"),
          STYLES: layer.get("styles"),
          authkey: localStorage.getItem('auth_token')
        }
      }}
    />
    : null
}

export default GeoPortalGSLayer;
