import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Typography, Stack } from "@mui/material";

// MUI Icons
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

// Custom Components
import { GridContainer, GridItem } from "@/ui/Grid";
import MapCardActionButton from "@/ui/MapCard/MapCardActionButton";
import FormContent from "@/components/FormContent";
import useFormController from "@/components/useFormController";
import { FormControllerProps } from "@/@types/components/formController";

import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import kuceZaOdmorService from "@/services/kuceZaOdmorService";

function KucaZaOdmorForm(props: FormControllerProps) {

  const snackbarContext = useContext(SnackbarContext);

  const { t } = useTranslation();

  const {
    validation,
    dataChanged,
    fields,
    onFieldChange,
    record
  } = useFormController(props);

  const { onClose } = props;

  const handleClickYes = (evt: SyntheticEvent) => {
    updateRecordWithStatus(1);
  }

  const handleClickNo = (evt: SyntheticEvent) => {
    updateRecordWithStatus(-1);
  }

  const handleClickUnknown = (evt: SyntheticEvent) => {
    updateRecordWithStatus(0);
  }

  const updateRecordWithStatus = (status: number) => {
    kuceZaOdmorService.updateKucaZaOdmor(record.id as number, status, record.komentar as string).then((result) => {
      if (result.success) {
        onClose({ dataChanged: true, action: "update" });
      } else if (result.error) {
        const msg = "Greška prilikom ažuriranja podataka! " + result.error;
        snackbarContext.showNotification(msg, "error");
      } else if (result.validationPass === false) {
        //do nothing
      }
    });
  }

  return (
    <>
      <GridContainer spacing={0}>
        <FormContent
          fields={fields}
          fieldNames={["komentar"]}
          onFieldChange={onFieldChange}
          columns={1}
          mode="update"
          record={record}
          validation={validation}
        />
      </GridContainer>
      <Stack direction="column">
        <Typography variant="body2" paragraph>{t("kuce_za_odmor.question")}</Typography>
        <Stack direction="row">
          <MapCardActionButton
            startIcon={<CheckIcon />}
            onClick={handleClickYes}
            variant="contained"
          >
            {t("common.yes")}
          </MapCardActionButton>
          <MapCardActionButton
            startIcon={<CloseIcon />}
            onClick={handleClickNo}
            variant="contained"
          >
            {t("common.no")}
          </MapCardActionButton>
          <MapCardActionButton
            startIcon={<QuestionMarkIcon />}
            onClick={handleClickUnknown}
            variant="outlined"
          >
            {t("common.unknown")}
          </MapCardActionButton>
        </Stack>
      </Stack>
    </>
  );
}

export default KucaZaOdmorForm;