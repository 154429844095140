import * as React from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

//Custom Components
import { GridContainer, GridItem } from "@/ui/Grid";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const textVariant = "caption";
  const version = process.env.REACT_APP_APPVERSION;
  const language = i18n.language;
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer">
      <Toolbar
        disableGutters={false}
        sx={{
          borderTop: "2px solid #000",
          backgroundColor: "#fff"
        }}
      >
        <GridContainer spacing={0} justifyContent="flex-end">
          <GridItem xs={1} textAlign="right">
            <Typography variant="caption">v1.1.19</Typography>
          </GridItem>
        </GridContainer>
      </Toolbar>
    </footer>
  );
};

export default Footer;
