import { IModel } from "../@types/models/model";
import { commonFields } from "./helpers/commonFields";
import { coreFields } from "./helpers/coreFields";

const Comment: IModel = {
  title: "Comment photos",
  apiPath: "maps/1/comments",
  forms : {
    "default": {
      fields: ["image"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", ""]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      type: "numeric",
      idattr: true,
      readonly: true
    },
    {
      title: "Photos",
      source: "photos",
      ttoken: "comment.photos",
      type: "images",
      maxFiles: 3
    },
    {
        title: "Comment id",
        source: "comment_id",
        ttoken: "comment.comment_id",
        type: "numeric",
        idattr: true,
    },
    {
        title: "Image name",
        source: "image_name",
        ttoken: "comment.image_name",
        type: "numeric",
        idattr: true,
    },
    {
        title: "Image size",
        source: "image_size",
        ttoken: "comment.image_size",
        type: "numeric",
        idattr: true,
    },
    {
        title: "Image type",
        source: "image_type",
        ttoken: "comment.image_type",
        type: "numeric",
        idattr: true,
    }
  ]
}

export default Comment;