import * as React from "react";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

//MUI Icons
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

//Custom Components
import DialogActionButton from "@/ui/Dialog/DialogActionButton";

//Types
import { IDialogActionButtonDetail } from "@/@types/ui/Dialog";

const DialogActionButtonDelete: FunctionComponent<IDialogActionButtonDetail> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogActionButton startIcon={<DeleteForeverIcon />} {...props} ariaLabel="delete" ariaControls="delete">
      {t("buttons.delete")}
    </DialogActionButton>
  );
};

export default DialogActionButtonDelete;
