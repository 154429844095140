import api from "./api";
import { helpers as H } from "./helpers";

const downloadFile = (typeOrUrl: string, key?: string, fileName?: string) => {
  const _fileName = fileName ? fileName : fileName = `${H.hash(typeOrUrl)}.pdf`;
  const apiInstance = new api();
  apiInstance.Download(typeOrUrl, key).then(resp => {
    if (resp.success && resp.data) {
      resp.data.then(data => {
        const fileBlob = new Blob([data]);
        const blobUrl = URL.createObjectURL(fileBlob);
        const link = document.createElement("a"); // Or maybe get it from the current document
        // link.style = "display: none";
        link.setAttribute("style", "display: none")
        link.href = blobUrl;
        link.download = _fileName;
        document.body.appendChild(link); // Or append it whereever you want
        link.click();
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
      })
    }
  })
}

export {
  downloadFile
}