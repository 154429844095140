import * as React from 'react'
import { useTranslation } from "react-i18next";
import { FunctionComponent, useEffect, useState } from "react";

//Custom components
import ChartistGraph from "react-chartist";
import { IChartistAnimations, ILineChartOptions, Svg } from "chartist";

//Types
import { IChart, IChartDrawLineData, DrawLineFn, ListenerFn } from "@/@types/ui/Charts";

const LineGraph: FunctionComponent<IChart> = (props) => {
  const { t } = useTranslation();

  const [isEdge, setIsEdge] = useState<boolean>(false);
  const [isMozilla, setIsMozilla] = useState<boolean>(false);

  const { color, data } = props;

  useEffect(() => {
    setIsEdge(window.navigator.userAgent.toLowerCase().indexOf("edge") >= 0);
    setIsMozilla(window.navigator.userAgent.toLowerCase().indexOf("firefox") >= 0);
  }, [data, window.navigator.userAgent]) //TODO: probably not the best way since react uses virtual DOM

  const chartListener: ListenerFn<DrawLineFn> = () => {

    return {
      draw: function (data) {
        if (data.type === 'line') {
          // Get the total path length in order to use for dash array animation
          // Set a dasharray that matches the path length as prerequisite to animate dashoffset
          let pathLength = -1;
          if (!isEdge) {
            // this bullshit is necessary since _node is defined as HTMLElement which doesn't have getTotalLength as property
            // so we first cast it to unknown and then to SVGLineElement because otherwise ts complains that casting isn't straight forward
            const node = data.element._node;
            pathLength = node.getTotalLength();
            data.element.attr({
              'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
            });
          }

          // Create animation definition while also assigning an ID to the animation for later sync usage
          const animationDefinition: IChartistAnimations = {
            "stroke-dashoffset": {
              id: "anim" + data.index,
              dur: 2000,
              from: pathLength + "px",
              to: "0px",
              easing: Svg.Easing.easeOutQuint,
              // We need to use `fill: 'freeze'` otherwise our animation will fall back to initial (not visible)
              fill: "freeze",
              begin: undefined
            }
          };

          // If this was not the first slice, we need to time the animation so that it uses the end sync event of the previous animation
          if (data.index !== 0) {
            animationDefinition['stroke-dashoffset'].begin = 'anim' + (data.index - 1) + '.end';
          }

          // We need to set an initial value before the animation starts as we are not in guided mode which would do that for us
          data.element.attr({
            'stroke-dashoffset': -pathLength + 'px'
          });
          data.element.attr({ style: 'stroke: ' + color });

          // We can't use guided mode as the animations need to rely on setting begin manually
          // See http://gionkunz.github.io/chartist-js/api-documentation.html#chartistsvg-function-animate
          data.element.animate(animationDefinition, false, {emit: () => {}});
        }
        else if (data.type === 'label') {
          // adjust label size
          data.element._node.setAttribute('style', 'font-size: 2em; fill:' + color);
        }
      }
    }
  }

  const options : ILineChartOptions = {
    height: 250,
    axisX: {
      labelInterpolationFnc: (label: string) => t(label)
    }
  };

  return (
    <ChartistGraph
      type="Line"
      data={data}
      options={options}
      listener={chartListener()}
    />
  );
};

export default LineGraph;
