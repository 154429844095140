import * as React from "react";

import AdminPage from "UI/AppPage/AdminPage";
import UsersTable from "./UsersTable";

import Box from '@mui/material/Box';

function UsersPage() {
  return (
    <AdminPage>
      <Box m={2}>
        <UsersTable />
      </Box>
    </AdminPage>
  );
}

export default UsersPage;
