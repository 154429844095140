import { IModel } from "../@types/models/model";
import { commonFields } from "./helpers/commonFields";
import { coreFields } from "./helpers/coreFields";

const Comment: IModel = {
  title: "Comments",
  apiPath: "maps/1/comments",
  forms : {
    "default": {
      fields: ["note"]
    },
    "insert": {
      fields: ["note", "photos"]
    },
    "insert_admin": {
      fields: ["note", "rijeseno", "photos"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "author", "note", "show_on_map"]
    },
    "admin": {
      fields: ["id", "author", "note", "rijeseno", "show_on_map"]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      type: "numeric",
      idattr: true,
      readonly: true
    },
    {
      title: "author",
      source: "author",
      ttoken: "comment.author",
      type: "text",
      sort: true,
      filter: true,
      // validation: {
      //   required: true
      // }
    },
    {
      title: "author_id",
      source: "user_id",
      ttoken: "common.id",
      type: "numeric",
      readonly: true
    },
    {
      title: "note",
      source: "note",
      ttoken: "comment.note",
      type: "multiline",
      rows: 3,
      maxRows: 5,
      // validation: {
      //   required: true
      // }
    },
    {
      title: "geometry",
      source: "geom",
      ttoken: "common.objectid",
      type: "wkt",
      readonly: true
    },
    {
      title: "extent",
      source: "extent",
      ttoken: "common.objectid",
      type: "text",
      readonly: true
    },
    {
      title: "",
      source: "show_on_map",
      tooltip: "buttons.show_on_map",
      type: "button",
      ttoken: "",
      icon: "fmd_good",
      action: "show_on_map",
      padding: "none",
      sort: false
    },
    {
      title: "Photos",
      source: "photos",
      ttoken: "comment.photos",
      type: "images",
      maxFiles: 3
    },
    {
      title: "Riješeno",
      source: "rijeseno",
      ttoken: "comment.rijeseno",
      type: "boolean",
      items: {
        labels: ["common.ne", "common.da"],
        values: [false, true],
        default: true
      },
      translate: true,
    },
  ]
}

export default Comment;