import React, { useContext, useEffect, useState, useCallback } from "react";
import BarControl from "../OpenLayersExt/Bar/BarControl";
import ButtonControl from "../OpenLayersExt/ButtonControl";
import MapContext from "@/components/Map/MapContext";

//Types
import { MapContextType } from "@/@types/context/MapContext";
import { IMapView } from "@/@types/common";
import MapEvent from "ol/MapEvent";
import { EventsKey } from "ol/events";
import { useTranslation } from "react-i18next";
import { ViewHistoryState } from "@/@types/components/Map/Controls/Custom";
import UserContext from "@/components/UserContext/UserContext";

function ViewHistoryControl() {
  const userContext = useContext(UserContext);

  const [ready, setReady] = useState(false);

  const [eventKey, setEventKey] = useState<EventsKey | null>(null);

  const [viewsState, setViewsState] = useState<ViewHistoryState>({
    views: [],
    currentViewIndex: 0,
    viewSwitchClicked: false,
    navigateTo: undefined
  });

  const mapContext = useContext<MapContextType | null>(MapContext);

  const { t } = useTranslation();

  const handlePrevious = () => {
    setViewsState((prevState) => {
      if (prevState.currentViewIndex === 0) {
        return prevState;
      } else {
        return {
          views: prevState.views,
          currentViewIndex: prevState.currentViewIndex - 1,
          viewSwitchClicked: true,
          navigateTo: prevState.views[prevState.currentViewIndex - 1 -1]
        };
      }
    });
  };

  const handleNext = () => {
    setViewsState((prevState) => {
      if (prevState.currentViewIndex === prevState.views.length) {
        return prevState;
      } else {
        return {
          views: prevState.views,
          currentViewIndex: prevState.currentViewIndex + 1,
          viewSwitchClicked: true,
          navigateTo: prevState.views[prevState.currentViewIndex - 1 + 1]
        };
      }
    });
  };

  const navigateToView = (view: IMapView) => {
    if (mapContext?.map) {
      const v = mapContext.map.getView();
      v.animate({
        center: view.center,
        zoom: view.zoom,
        duration: 500
      })
    }
  };

  const handleMoveEnd = useCallback(
    (evt: MapEvent) => {
      if (evt?.map) {
        const view = evt.map.getView();
        const center = view.getCenter();
        const zoom = view.getZoom();
        if (center && zoom) {
          const newView: IMapView = {
            center: center as [number, number],
            zoom: zoom
          };
          setViewsState((prevState) => {
            if (prevState.viewSwitchClicked) {
              return {
                ...prevState,
                viewSwitchClicked: false
              };
            } else {
              // History limit of 50 states
              if(prevState.views.length >= 50) {
                return {
                  views: prevState.views.slice(1, prevState.currentViewIndex).concat([newView]),
                  currentViewIndex: prevState.currentViewIndex,
                  viewSwitchClicked: false,
                  navigateTo: undefined
                };
              }
              return {
                views: prevState.views.slice(0, prevState.currentViewIndex).concat([newView]),
                currentViewIndex: prevState.currentViewIndex + 1,
                viewSwitchClicked: false,
                navigateTo: undefined
              };
            }
          });
        }
      }
    },
    [viewsState.viewSwitchClicked]
  );

  // localStorage key of each map is in the format: viewsState_username_projektId
  useEffect(() => {
    const viewsData = localStorage.getItem('viewsState_' + userContext?.username + '_' + userContext?.projektId);
    if (viewsData !== null && viewsData !== undefined) {
      setViewsState(JSON.parse(viewsData));
    }
    // console.log(localStorage.getItem('viewsState_' + userContext?.username + '_' + userContext?.projektId));
  }, []);

  useEffect(() => {
    localStorage.setItem('viewsState_' + userContext?.username + '_' + userContext?.projektId, JSON.stringify(viewsState));
    // console.log(localStorage.getItem('viewsState_' + userContext?.username + '_' + userContext?.projektId));
  }, [viewsState]);

  useEffect(() => {
    if (!ready) {
      if (mapContext?.map !== null) {
        setReady(true);
      }
    }
  }, [mapContext?.map]);

  useEffect(() => {
    if (mapContext?.map) {
      const evKey = mapContext.map.on("moveend", handleMoveEnd);
      setEventKey(evKey);
    }

    return () => {
      if (mapContext?.map && eventKey) {
        mapContext.map.un("moveend", eventKey.listener);
      }
    };
  }, [mapContext?.map]);

  useEffect(() => {
    if (viewsState.navigateTo) {
      navigateToView(viewsState.navigateTo);
    }
  }, [viewsState.navigateTo]);

  return ready ? (
    <BarControl id="bar-history" position="top">
      <ButtonControl
        id="history-prev"
        title={t("map:controls.history_prev")}
        name="prev_view"
        html={'<i class="fas fa-caret-square-left"></i>'}
        handleClick={handlePrevious}
      />
      <ButtonControl
        id="history-next"
        title={t("map:controls.history_next")}
        name="next_view"
        html={'<i class="fas fa-caret-square-right"></i>'}
        handleClick={handleNext}
      />
    </BarControl>
  ) : null;
}

export default ViewHistoryControl;
