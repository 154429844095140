import React, { useContext, useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import UserContext from "@/components/UserContext/UserContext";
import Table from "@/ui/Table/Table";
import tableGenerator from "@/lib/tableGenerator";
import dataControllerSubModel from "@/lib/dataControllerSubModel";
import dataController from "@/lib/dataController";

import radio from "@/models/submodels/radio";
import checkbox from "@/models/submodels/checkbox";
import picker from "@/models/submodels/picker";

import {
  // DateColumnFilter
  ElementPropertyColumnFilter,
  MultiColumnFilter,
  NoFilter,
  SelectColumnFilter,
  TextColumnFilter,
  NumericColumnFilter,
} from "@/ui/Table/Filters";
import { dateColumnFilter } from "@/ui/Table/Filters/DateColumnFilter";
import {
  sortDateTime,
  sortBasic,
  sortElementProperty,
  sortFavorite,
  sortLink,
  sortMulti,
} from "@/lib/Table/sort";
import {
  filterCustom,
  filterDate,
  filterElementProperty,
  filterMulti,
  filterNumeric,
} from "@/lib/Table/filter";
import { getHeader } from "@/lib/Table/helpers";
import {
  IModelTableProps,
  SetFilterFn,
  ISubModelsWithData,
  IColumnInfoState,
  RowField,
} from "@/@types/ui/Table";
import { IFieldPickerModel } from "@/@types/models/model";
import { Row } from "react-table";

function ModelTable(props: React.PropsWithChildren<IModelTableProps>) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const [subModelsWithData, setSubModelsWithData] =
    useState<ISubModelsWithData>({});
  const [columnInfo, setColumnInfo] = useState<IColumnInfoState>({
    columns: [],
    hiddenColumnNames: [],
  });
  const [data, setData] = useState([]);

  const {
    dc,
    viewName,
    records,
    children,
    allowAdd,
    allowColumnPicker,
    allowEdit = true,
    allowExport,
    allowFilter,
    allowInc = false,
    allowRowAction,
    allowSearchBar = true,
    allowSelection,
    allowRowsPerPageSelection = true,
    showControlSwitch = true,
    onRowAction,
    onRowClick,
    onDoubleClick,
    handleRefresh,
    // selectedRowId,
    // topAlignedSearch,
    tableName,
    // pagination = true,
    addLabel,
    handleAdd,
    onEdit,
    title,
    subTitle,
    disableControls,
    displayHeader = true,
    initialPageSize,
    rowSelectedCustom,
    RowTooltip,
    trackTableState,
    customFn,
    unselectRow,
    getRowStyle,
    paginationPosition,
    globalFilterIntersection,
    onGlobalFilterChange,
    customRecordCount,
    gotoFirstPageOnSelect,
    smallButtons,
    allowLegend,
    onLegendClick,
    allowFilterToggle,
    onFilterToggle,
    isFiltered
  } = props;

  const viewFields = dc.getViewFields(viewName);
  const src = dc.getSource();
  const identifier = src + viewName;

  const tg = new tableGenerator(t, onRowAction, allowRowAction);

  function setFilterLocalStorage<ValueType>(
    id: string,
    filter: ValueType | null | undefined,
    setFilter: SetFilterFn<ValueType>
  ): void {
    if (userContext) {
      userContext.setRecentFilters(identifier, id, filter ? filter : "");
    }
    // Imamo neke vrijednosti za filter koji su 0, a to ne prolazi na filter || undefined
    // uvijek će to bacit na undefined, pa nek provjeri if statement da li je jedan od ovih vrijednosti osim 0
    if (
      filter !== undefined &&
      filter !== null &&
      (typeof filter === "string" || Array.isArray(filter) || typeof filter === "boolean") &&
      filter !== ""
    ) {
      setFilter(filter);
    } else {
      setFilter(undefined);
    }
  }

  useEffect(() => {
    const fieldsWithModel = viewFields.filter(
      (f) =>
        [
          "picker",
          "checkbox",
          "radio",
          "boolean",
          "active",
          "iconStatus",
        ].indexOf(f.type) !== -1 && f.hasOwnProperty("subModel")
    ) as Array<IFieldPickerModel>;
    const subModels: ISubModelsWithData = {};

    fieldsWithModel.forEach((f) => {
      const modelType =
        f.type === "picker"
          ? picker
          : f.type === "radio"
          ? radio
          : f.type === "checkbox"
          ? checkbox
          : // not existing for now
            // : f.type === "link"
            // ? require(`Models/${f.subModel.name}`)
            null;

      if (modelType !== null) {
        Object.assign(subModels, {
          [f.source]: new dataControllerSubModel(modelType, f),
        });
        // not existing for now
        // if (f.type === "link") {
        //   Object.assign(subModels, {
        //     [f.source]: new dataController(modelType, f)
        //   });
        // } else {
        // }
      }
    });

    const getDataPromises = Object.keys(subModels).map((f) =>
      subModels[f].GetData()
    );
    Promise.all(getDataPromises).then((data) => {
      setSubModelsWithData(subModels);
    });
  }, []);

  useEffect(() => {
    const viewFields = dc.getViewFields(viewName);
    const columns = viewFields.map((f) => {
      const canSort =
        f.hasOwnProperty("sort") && f.sort === false ? false : true;
      const sortType = canSort
        ? f.type === "date" || f.type === "datetime"
          ? sortDateTime
          : "alphanumeric"
        : undefined;
      const header = f.ttoken ? t(f.ttoken) : t(f.title);
      let obj = {
        // Header: f.type === "numeric" ? () => <div style={{ textAlign: "end" }}>{header}</div> : header,
        Header: () => getHeader(f, t),
        accessor: f.source,
        Cell: tg.generateRenderFunction(f, subModelsWithData, customFn),
        disableSortBy: !canSort,
        sortType: sortType,
        disableFilters: !f.filter && f.filter !== undefined,
        Filter:
          f.type === "text"
            ? TextColumnFilter(setFilterLocalStorage)
            : f.type === "radio" ||
              f.type === "boolean" ||
              // TODO: Remove f.items
              // f.items should be found in subModelsWithData
              f.type === "picker"
            ? SelectColumnFilter(
                setFilterLocalStorage,
                subModelsWithData,
                f.items
              )
            : f.type === "date" || f.type === "datetime"
            ? dateColumnFilter(setFilterLocalStorage)
            : f.type === "checkbox" ||
              f.type === "active" ||
              f.type === "iconStatus"
            ? MultiColumnFilter(setFilterLocalStorage, subModelsWithData)
            : f.type === "numeric"
            ? NumericColumnFilter(setFilterLocalStorage)
            : NoFilter,
        filter: (
          arrValues: Array<Row>,
          filterField: Array<string>,
          currValue: any
        ) => {
          const [fField] = filterField;
          return arrValues.filter((field) => {
            const { original } = field as RowField;
            return f.type === "radio" ||
              f.type === "boolean" ||
              f.type === "picker"
              ? original[fField] === currValue
              : f.type === "active"
              ? currValue === "all" || original[fField] === currValue
              : f.type === "checkbox" || f.type === "iconStatus"
              ? filterMulti(original[fField], currValue)
              : f.type === "date" || f.type === "datetime"
              ? filterDate(original[fField], currValue)
              : f.type === "numeric"
              ? filterNumeric(original[fField] ?? null, currValue)
              : (original[fField] + "")
                  .toUpperCase()
                  .indexOf(currValue.toUpperCase()) >= 0;
          });
        },
        disableGlobalFilter: f.search !== undefined ? !f.search : false,
        padding: f.type === "button" && f.padding ? f.padding : "normal",
        style:
          f.type === "numeric" || f.type === "wholenum" || f.type === "currency"
            ? {
                flexDirection: "row-reverse",
                textAlign: f.align ? f.align : "center",
              }
            : undefined,
        align: f.align,
      };

      return obj;
    });

    const hiddenColumnNames = dc.getHiddenViewFieldsNames(viewName);

    setColumnInfo({
      columns: columns,
      hiddenColumnNames: hiddenColumnNames,
    });
  }, [viewName, t, subModelsWithData]);

  if (columnInfo.columns.length == 0) return null; // Makes sure that Table doesn't render without data on first render, which can cause issues

  return (
    <Table
      title={title}
      subTitle={subTitle}
      // topAlignedSearch={topAlignedSearch}
      records={records}
      columns={columnInfo.columns}
      hiddenColumnNames={columnInfo.hiddenColumnNames}
      showControlSwitch={showControlSwitch}
      allowColumnPicker={allowColumnPicker}
      allowSelection={allowSelection}
      allowFilter={allowFilter}
      allowExport={allowExport}
      allowAdd={allowAdd}
      allowEdit={allowEdit}
      allowInc={allowInc}
      allowSearchBar={allowSearchBar}
      allowRowsPerPageSelection={allowRowsPerPageSelection}
      addLabel={addLabel}
      handleAdd={handleAdd}
      onEdit={onEdit}
      dc={dc}
      tableName={tableName}
      onRowClick={onRowClick}
      onDoubleClick={onDoubleClick}
      onRefresh={handleRefresh}
      // selectedRowId={selectedRowId}
      identifier={identifier}
      // pagination={pagination}
      disableControls={disableControls}
      displayHeader={displayHeader}
      initialPageSize={initialPageSize}
      rowSelectedCustom={rowSelectedCustom}
      RowTooltip={RowTooltip}
      trackTableState={trackTableState}
      unselectRow={unselectRow}
      getRowStyle={getRowStyle}
      paginationPosition={paginationPosition ? paginationPosition : "top"}
      onGlobalFilterChange={onGlobalFilterChange}
      customRecordCount={customRecordCount}
      gotoFirstPageOnSelect={gotoFirstPageOnSelect}
      smallButtons={smallButtons}
      allowLegend={allowLegend !== undefined ? allowLegend : false}
      onLegendClick={onLegendClick ? onLegendClick : undefined}
      allowFilterToggle={allowFilterToggle !== undefined ? allowFilterToggle : false}
      onFilterToggle={onFilterToggle}
      isFiltered={isFiltered}
    >
      {children}
    </Table>
  );
}

ModelTable.defaultProps = {
  viewName: "default",
  allowColumnPicker: true,
  allowSelection: "one",
  allowFilter: false,
  allowExport: false,
  onEdit: () => {},
};

ModelTable.propTypes = {
  records: PropTypes.array,
  dc: PropTypes.object,
  viewName: PropTypes.string,
  allowSelection: PropTypes.oneOf(["none", "one", "many"]),
  allowFilter: PropTypes.bool,
  allowExport: PropTypes.bool,
};
export default ModelTable;
