import React from "react";

//Custom Components
import PhotoCentricPage from "@/views/PhotoCentric/PhotoCentricPage";
import modelRasvjetnaMjesta from "@/models/rasvjetna_mjesta";
import { Permissions as P } from "@/lib/permissions";

type RasvjetaPhotoCentricPageParams = {}

function RasvjetaPhotoCentricPage(props: RasvjetaPhotoCentricPageParams) {
  return (
    <PhotoCentricPage
      needPermission={[P.Rasvjeta]}
      titleToken={"titles.rasvjetna_mjesta"}
      model={modelRasvjetnaMjesta}
      mapId={53}
      />
  );
}

export default RasvjetaPhotoCentricPage;
