import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

//Material-UI Core Components

//Material-UI Icons
import EditIcon from "@mui/icons-material/Edit";

//Custom Components
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import MapCardActions from "@/ui/MapCard/MapCardActions";
import MapCardActionButton from "@/ui/MapCard/MapCardActionButton";
import dataController from "@/lib/dataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";
import DialogContext from "@/ui/DialogContext/DialogContext";
import UserContext from "@/components/UserContext/UserContext";
import { GridContainer, GridItem } from "@/ui/Grid";
import FormContent from "@/components/FormContent";
import modelComments from "@/models/comments"
import CommentDialog from "./CommentDialog"
import ImageSlider from "@/components/ImageSlider"
import { Permissions as P } from "@/lib/permissions";


//Types
import { CommentInfoCardProps } from "@/@types/views/GIS/map";
import { DCRecord } from "@/@types/lib/dataController";
import { CircularProgress, Typography } from "@mui/material";

function CommentInfoCard(props: CommentInfoCardProps) {
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [loadStatus, setLoadStatus] = useState({ isLoading: true, isLoaded: false });
  const [comment, setComment] = useState<any>(null);

  const dialogContext = useContext(DialogContext);

  const dc = new dataController(modelComments);

  const { featureData, onClose, onDelete, refreshLayers, refreshPhotos, photosInfo } = props;

  const imageStyles = {
    width: "100px",
    height: "100px",
    borderRadius: "5px",
    cursor: "pointer"
  }

  const refreshComment = () => {
    // @ts-ignore 
    setLoadStatus({ isLoading: true, isLoaded: false });
    dc.GetDataSingle(Number(featureData.feature.getId())).then((resp) => {
      if(resp && resp.data) {
        setComment(resp.data)
      } else {
        // setComments(null)
      }
    })
    .finally(() => {
      setLoadStatus({ isLoading: false, isLoaded: true });
    })
  }

  useEffect(() => {
    refreshComment();
  }, [featureData])

  const faIconClass = "fas fa-comment ";


  const handleCommentDialogClose = (result: any) => {
    if (result.dataChanged && result.action !== "delete") {
      if(refreshPhotos) {
        refreshPhotos(photosInfo.feature, photosInfo.evt);
      } else {
        refreshComment();
      }
    } else if (result.action == "delete") {
      if (onDelete) {
        onDelete()
      }
      onClose()
    } else {
      if(refreshPhotos) {
        refreshPhotos(photosInfo.feature, photosInfo.evt);
      }
    }
  };
  
  const form = "default";
  let updateForm = "insert"

  if (userContext) {
    if (userContext.hasAnyPermission([P.Administration])) {
      updateForm = "insert_admin";
    }
  }
  
  const handleEditComment = () => {
    dialogContext.showDialog(CommentDialog, {
      dc: dc,
      mode: "update",
      form: updateForm,
      dataId: comment.id,
      onClose: handleCommentDialogClose,
      feature: featureData.feature,
      refreshLayers: () => {refreshLayers();},
      // @ts-ignore
      initialRecord: {note: comment.note, rijeseno: comment.rijeseno,
        photos: featureData.data && Array.isArray(featureData.data) && featureData.data?.length !== 0 ?
        featureData?.data : {} }
    });
    
  };

  return (
    <Fragment>
      <MapCard
        sx={{
          backgroundColor: "background.default",
          minWidth: "200px"
        }}
      >
        {
          loadStatus.isLoaded ?
        <>
        <MapCardHeader
          title={comment?.author}
          subheader={t("common.id") + ": " + comment?.id}
          subheaderTypographyProps={{ color: "initial" }}
          avatar={<MapCardAvatar ariaLabel="info" icon={<i className={faIconClass}></i>} />}
          action={<MapCardHeaderButtonClose onClick={onClose} />}
        />
        <MapCardContent>
          <GridContainer spacing={0}>
            <GridItem xs={12} container>
                <GridContainer spacing={0}>
                <FormContent
                  fields={dc.getFormFields(form)}
                  fieldNames={dc.getFormFieldsNames(form)}
                  columns={1}
                  mode="simpleView"
                  record={comment as DCRecord}
                />
                
                {featureData.data && Array.isArray(featureData.data) && featureData.data?.length !== 0 ? (
                  <ImageSlider images={featureData.data.map(image => image.photos)} />
                  ) : (
                  <Typography variant="caption">{t("common.no_photos")}</Typography>
                )}
                </GridContainer>
            </GridItem>
          </GridContainer>
        </MapCardContent>
        <MapCardActions>
          <MapCardActionButton startIcon={<EditIcon />} onClick={handleEditComment}>
            {t("buttons.edit_comment")}
          </MapCardActionButton>
        </MapCardActions>
          </>
          :
          <CircularProgress />
        }
      </MapCard>
    </Fragment>
  );
}

export default CommentInfoCard;
