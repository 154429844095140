import * as React from "react";
import { Fragment, FunctionComponent } from "react";

import { focus as OlConditionFocus } from "ol/events/condition";

import DragRotateInteraction from "./OpenLayers/DragRotateInteraction";
import DoubleClickZoomInteraction from "./OpenLayers/DoubleClickZoomInteraction";
import DragPanInteraction from "./OpenLayers/DragPanInteraction";
import PinchRotateInteraction from "./OpenLayers/PinchRotateInteraction";
import PinchZoomInteraction from "./OpenLayers/PinchZoomInteraction";
import KeyboardPanInteraction from "./OpenLayers/KeyboardPanInteraction";
import KeyboardZoomInteraction from "./OpenLayers/KeyboardZoomInteraction";
import MouseWheelZoomInteraction from "./OpenLayers/MouseWheelZoomInteraction";
import DragZoomInteraction from "./OpenLayers/DragZoomInteraction";
import { getDefinedOptions } from "@/lib/olHelpers";
import { DefaultsOptions } from "ol/interaction";

const DefaultInteractions: FunctionComponent<any> = (props) => {
  const options = {
    altShiftDragRotate: true,
    onFocusOnly: false,
    doubleClickZoom: true,
    keyboard: true,
    mouseWheelZoom: true,
    shiftDragZoom: true,
    dragPan: true,
    pinchRotate: true,
    pinchZoom: true,
    zoomDelta: undefined,
    zoomDuration: undefined
  };

  let allOptions = Object.assign(options, props);
  let definedOptions: DefaultsOptions = getDefinedOptions(allOptions);

  //note: order is important!!!
  return (
    <Fragment>
      {definedOptions.altShiftDragRotate === true ? (
        <DragRotateInteraction />
      ) : null}
      {definedOptions.doubleClickZoom === true ? (
        <DoubleClickZoomInteraction
          delta={definedOptions.zoomDelta}
          duration={definedOptions.zoomDuration}
        />
      ) : null}
      {definedOptions.dragPan === true ? (
        <DragPanInteraction
          condition={definedOptions.onFocusOnly ? OlConditionFocus : undefined}
        />
      ) : null}
      {definedOptions.pinchRotate === true ? <PinchRotateInteraction /> : null}
      {definedOptions.pinchZoom === true ? (
        <PinchZoomInteraction duration={definedOptions.zoomDuration} />
      ) : null}
      {definedOptions.keyboard === true ? <KeyboardPanInteraction /> : null}
      {definedOptions.keyboard === true ? (
        <KeyboardZoomInteraction
          delta={definedOptions.zoomDelta}
          duration={definedOptions.zoomDuration}
        />
      ) : null}
      {definedOptions.mouseWheelZoom === true ? (
        <MouseWheelZoomInteraction
          duration={definedOptions.zoomDuration}
          condition={definedOptions.onFocusOnly ? OlConditionFocus : undefined}
        />
      ) : null}
      {definedOptions.shiftDragZoom === true ? (
        <DragZoomInteraction duration={definedOptions.zoomDuration} />
      ) : null}
    </Fragment>
  );
};

export default DefaultInteractions;

