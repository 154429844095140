import { IModel } from "@/@types/models/model";

import { commonFields } from "./helpers/commonFields";
import { buttonFields } from "./helpers/buttonFields";
import { coreFields } from "./helpers/coreFields";

const KuceZaOdmor: IModel = {
  title: "Kuće za odmor",
  source: "kuce_za_odmor",
  apiPath: "kuce-za-odmor",
  forms: {
    default: {
      fields: [
        "obveznik",
        "ko_ime",
        "kc",
        "adresa_objekta",
        "adresa_obveznika",
        "vlasnik",
        "povrsina",
        "komentar",
        "status"
      ],
    },
    update: {
      fields: ["komentar", "status"],
    },
  },
  listViews: {
    default: {
      fields: [
        "obveznik",
        "ko_ime",
        "kc",
        "adresa_objekta",
        "naselje_objekta",
        "adresa_obveznika",
        "vlasnik",
        "povrsina",
        "status",
        "komentar",
        "btn_show_object",
        "btn_show_photos",
        "btn_show_3d"
      ],
      hidden: ["id"],
    },
    secondary: {
      fields: [
        "obveznik",
        "ko_ime",
        "kc",
        "adresa_objekta",
        "adresa_obveznika",
        "vlasnik",
        "povrsina",
        "status",
        "komentar"
      ],
      hidden: ["id"],
    },
  },
  fields: [
    coreFields.id,
    {
      title: "Komunalni obveznik",
      source: "obveznik",
      ttoken: "kuce_za_odmor.obveznik",
      type: "text",
      search: true,
    },
    {
      title: "Komunalna općina",
      source: "ko_ime",
      ttoken: "kuce_za_odmor.opcina",
      type: "text",
      search: true,
    },
    {
      title: "Katastarska čestica",
      source: "kc",
      ttoken: "kuce_za_odmor.cestica",
      type: "text",
      search: true,
    },
    {
      title: "Adresa objekta",
      source: "adresa_objekta",
      ttoken: "common.adresa_objekta",
      type: "text",
      search: true,
    },
    {
      title: "Naselje objekta",
      source: "naselje_objekta",
      ttoken: "common.naselje_objekta",
      type: "text",
      search: true,
    },
    {
      title: "Adresa obveznika",
      source: "adresa_obveznika",
      ttoken: "common.adresa_obveznika",
      type: "text",
      search: true,
    },
    {
      title: "Vlasnik",
      source: "vlasnik",
      ttoken: "kuce_za_odmor.vlasnik",
      type: "text",
      search: true,
    },
    {
      title: "Površina",
      source: "povrsina",
      ttoken: "kuce_za_odmor.povrsina",
      type: "numeric",
      search: true,
    },
    {
      title: "Status",
      source: "status",
      ttoken: "common.status",
      type: "picker",
      items: {
        labels: ["common.yes", "common.no", "common.unknown"],
        values: [1, -1, 0],
      },
    },
    {
      title: "Komentar",
      source: "komentar",
      ttoken: "kuce_za_odmor.komentar",
      type: "text",
      // rows: 3,
      // maxRows: 5,
      validation: {
        maxLength: 1000
      },
      search: true,
    },
    {
      title: "cx",
      source: "cx",
      ttoken: "",
      type: "numeric",
      sort: true,
      search: false
    },
    {
      title: "cy",
      source: "cy",
      ttoken: "",
      type: "numeric",
      sort: true,
      search: false
    },
    {
      title: "cz",
      source: "cz",
      ttoken: "",
      type: "numeric",
      sort: true,
      search: false
    },
    {
      title: "Lokacija ID",
      source: "lokacija_id",
      ttoken: "",
      type: "numeric",
      readonly: true
    },
    {
      title: "Objekt ID",
      source: "objekt_id",
      ttoken: "",
      type: "numeric",
      readonly: true
    },
    commonFields.wkt,
    buttonFields.show_3d,
    buttonFields.show_object,
    buttonFields.show_photos
  ],
};

export default KuceZaOdmor;
