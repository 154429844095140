import React from "react";

//Custom Components
import DataCentricPage from "@/views/DataCentric/DataCentricPage";
import modelRasvjetnaMjesta from "@/models/rasvjetna_mjesta";
import modelRasvjetnaTijela from "@/models/rasvjetna_tijela";
import { Permissions as P } from "@/lib/permissions";

type RasvjetaDataCentricPageParams = {}

function RasvjetaDataCentricPage(props: RasvjetaDataCentricPageParams) {
  return (
    <DataCentricPage
      needPermission={[P.Rasvjeta]}
      
      primaryModel={modelRasvjetnaMjesta}
      primaryTitleToken="titles.rasvjetna_mjesta"
      primaryRecordLocationField="id"

      secondaryModel={modelRasvjetnaTijela}
      secondaryTitleToken="titles.rasvjetna_tijela"

      baseRecordPath={modelRasvjetnaMjesta.apiPath}
      childRecordRelativePath="rasvjetna-tijela"
      geomRelativePath="geoms"

      mapId={51}
      mapModel={modelRasvjetnaMjesta}

      allowShowImages={false}
      />
  );
}

export default RasvjetaDataCentricPage;
