import React from "react"
import { useTranslation } from "react-i18next";

// MUI
import { Box, Toolbar } from "@mui/material";

//Custom Components
import DraggableDialog from "@/ui/Dialog/DraggableDialog";
import DialogHeader from "@/ui/Dialog/DialogHeader";
import DialogBody from "@/ui/Dialog/DialogBody";
import DialogToolbarHeading from "@/ui/Dialog/DialogToolbarHeading";
import ToolbarFillContent from "@/ui/Toolbar/ToolbarFillContent";
import DialogToolbarButtonClose from "@/ui/Dialog/ToolbarButtons/DialogToolbarButtonClose";

interface ImageDialogProps {
  image: any,
  onClose: () => void
}

const imageStyles: React.CSSProperties = {
  maxWidth: '65vw',
  maxHeight: "75vh",
  borderRadius: "5px",
  objectFit: "cover"
};

function ImageDialog(props: ImageDialogProps) {
  const { image, onClose } = props
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  }

  return (
    <DraggableDialog 
      open={true} 
      onClose={handleClose} 
      PaperProps={{ 
        style: { 
            maxWidth: '80vw',
            maxHeight: "90vh",
            marginTop: "10vh"
        }, 
  }}>
      <DialogHeader>
        <Toolbar variant="dense" disableGutters={true}>
            <DialogToolbarHeading>{t("titles.photos")}</DialogToolbarHeading>
            <ToolbarFillContent />
            <DialogToolbarButtonClose onClick={handleClose} />
        </Toolbar>
      </DialogHeader>
      <DialogBody>
        <Box m={2} display="flex" justifyContent="center" alignItems="center">
          {image ? <img src={`data:image/jpeg;base64, ${image}`} style={imageStyles} /> : null}
        </Box>
      </DialogBody>
    </DraggableDialog>
  )


}

export default ImageDialog