import React from "react";

// Custom Components
import dataController from "@/lib/dataController";
import modelKuceZaOdmor from "@/models/kuce_za_odmor";
import KucaZaOdmorForm from "./KucaZaOdmorForm";

type KucaZaOdmorPanelProps = {
  recordId: number,
  onClose: () => void
}

function KucaZaOdmorPanel(props: KucaZaOdmorPanelProps) {

  const dcKuceZaOdmor = new dataController(modelKuceZaOdmor);

  const { recordId, onClose } = props;

  return (
    <KucaZaOdmorForm
      dc={dcKuceZaOdmor}
      mode="update"
      form="update"
      recordId={recordId}
      onClose={onClose}
    />
  );
}

export default KucaZaOdmorPanel;
