//Crop rectangular class
var Crop = function(originX, originY, width, height) {
    this._clickX = originX;
    this._clickY = originY;
    this._width = width;
    this._height = height;

    let absWidth = Math.abs(width);
    let absHeight = Math.abs(height);

    this.x = width < 0 ? originX - absWidth : originX;
    this.y = height < 0 ? originY - absHeight : originY;
    this.w = absWidth;
    this.h = absHeight;

    //enlarge selection so it is square (based on bigger side)
    this.makeSquare = function() {
        let size = Math.max(this.w, this.h);            
        this.w = size;
        this.h = size;
        if (this.x < this._clickX) {
            this.x = this._clickX - size;
        }
        if (this.y < this._clickY) {
            this.y = this._clickY - size;
        }
    }

    //enlarge selections so it fits ratio (based on bigger side)
    this.keepRatio = function(ratio) {
        let size = Math.max(this.w, this.h);
        if (this.w === size) {
            this.w = size;
            this.h = size / ratio;
        } else if (this.h === size) {
            this.w = size * ratio;
            this.h = size;
        }         
        if (this.x < this._clickX) {
            this.x = this._clickX - this.w;
        }
        if (this.y < this._clickY) {
            this.y = this._clickY - this.h;
        }
    }

    //make sure that crop is not outside canvas boundaries
    this.shrinkToFit = function(canvasX, canvasY) {            
        let maxWidth = this.w;
        let maxHeight = this.h;

        let dirWidth = this._width < 0 ? -1 : 1; //width direction (-1 or 1)
        let dirHeight = this._height < 0 ? -1 : 1; //height direction (-1 or 1)

        let endX = this._clickX + dirWidth * this.w;
        if (endX < 0 || endX > canvasX) {
            maxWidth = Math.min(canvasX - this._clickX, this._clickX);
        }

        let endY = this._clickY + dirHeight * this.h;
        if (endY < 0 || endY > canvasY) {
            maxHeight = Math.min(canvasY - this._clickY, this._clickY);
        }            

        let maxSize = Math.min(maxWidth, maxHeight);

        this.w = maxSize;
        this.h = maxSize;

        if (dirWidth > 0 && dirHeight > 0) {
            //do nothing
        } else if (dirWidth > 0 && dirHeight < 0) {
            this.y = this._clickY - maxSize;
        } else if (dirWidth < 0 && dirHeight > 0) {
            if (endX < 0) {
                this.x = 0;
            } else {
                this.x = this._clickX - maxSize;
            }

        } else if (dirWidth <0 &&  dirHeight < 0) {
            if (endX < 0) {
                this.x = 0;
            } else {
                this.x = this._clickX - maxSize;
            }

            this.y = this._clickY - maxSize;

        }
    }

    this.getCursorStyle = function() {
        return this._width * this._height > 0 ? 'nwse-resize' : 'nesw-resize';
    }

    //print data in comma-separated string
    this.csv = function() {
        let arr = [this.x, this.y, this.w, this.h];
        return arr.join(",");   
    }
}

export default Crop;
