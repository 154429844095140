import * as React from 'react'
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

//Custom components
import { Svg, IBarChartOptions } from 'chartist'
import ChartistGraph from "react-chartist";

//Types
import { IChart, DrawBarFn, ListenerFn, IChartDrawBarData } from "@/@types/ui/Charts";

const BarChart: FunctionComponent<IChart> = (props) => {
  const { t } = useTranslation();

  const [isEdge, setIsEdge] = useState<boolean>(false);
  const [isMozilla, setIsMozilla] = useState<boolean>(false);

  const { color, data } = props;

  useEffect(() => {
    setIsEdge(window.navigator.userAgent.toLowerCase().indexOf("edge") >= 0);
    setIsMozilla(window.navigator.userAgent.toLowerCase().indexOf("firefox") >= 0);
  }, [data, window.navigator.userAgent])//TODO: probably not the best way since react uses virtual DOM

  const getAttributes = (data: IChartDrawBarData) => {
    const barVerticalCenter = data.y2 - 5;
    const barHorizontalCenter = isMozilla || isEdge ? data.x1 : (data.x1 + (data.element.width() * .5));

    return {
      x: barHorizontalCenter,
      y: barVerticalCenter,
      "text-anchor": "middle",
      style: "font-family: 'proxima-nova-alt', Helvetica, Arial, sans-serif; font-size: 12px; fill: black"
    };
  }

  const chartListener: ListenerFn<DrawBarFn> = () => {

    return {
      draw: function (data) {
        if (data.type === 'bar') {
          data.element.attr({ style: 'stroke: ' + color + '; stroke-width: ' + Math.floor(1 / data.series.length * 40) + '%'});

          const labelText = data.series[data.index];
          const label = new Svg("text");
          label.text(String(labelText.value));

          const attributes = getAttributes(data);
          label.attr(attributes);

          // add the new custom text label to the bar
          data.group.append(label);

          // Get the total path length in order to use for dash array animation
          let pathLength = -1;

          if (!isEdge) {
            pathLength = data.element._node.getTotalLength();
          }


          // Set a dasharray that matches the path length as prerequisite to animate dashoffset
          if (!isEdge) {
            data.element.attr({
              'stroke-dasharray': pathLength + 'px ' + pathLength + 'px'
            });
          }

          // Create animation definition while also assigning an ID to the animation for later sync usage
          const animationDefinition = {
            "stroke-dashoffset": {
              id: "anim" + data.index,
              dur: 200,
              begin: 200 * data.index,
              from: pathLength + "px",
              to: "0px",
              easing: Svg.Easing.easeOutQuint,
              // We need to use `fill: 'freeze'` otherwise our animation will fall back to initial (not visible)
              fill: "freeze"
            }
          };

          // We need to set an initial value before the animation starts as we are not in guided mode which would do that for us
          data.element.attr({
            'stroke-dashoffset': -pathLength + 'px'
          });

          // We can't use guided mode as the animations need to rely on setting begin manually
          // See http://gionkunz.github.io/chartist-js/api-documentation.html#chartistsvg-function-animate
          data.element.animate(animationDefinition, false, {emit: () => {}});

        } else if (data.type === 'label') {
          data.element._node.setAttribute('style', 'font-size: 2em; fill:' + color);
        }
      }
    }
  }

  const options : IBarChartOptions = {
    height: 250,
    axisX: {
      labelInterpolationFnc: (label: string) => t(label)
    }
  };

  return (
    <ChartistGraph
      type="Bar"
      data={data}
      options={options}
      listener={chartListener()}
    />
  );
};

export default BarChart;
