import { IModel } from "@/@types/models/model";

import { coreFields } from "./helpers/coreFields";
import { commonFields } from "./helpers/commonFields";
import { buttonFields } from "./helpers/buttonFields";

const ParkiralisnaMjestaModel: IModel = {
  title: "Parkirališna mjesta",
  source: "parkiralisna_mjesta",
  apiPath: "parkiralista/parkiralisna-mjesta",
  forms: {
    default: {
      fields: [
        "naziv_parkiralista",
        "naplata",
        "vrsta",
        "povrsina",
        "opseg",
        "polozaj",
        "smjer",
        "podloga",
        "sirina",
        "duzina",
      ],
    },
  },
  listViews: {
    default: {
      fields: [
        "id",
        "naziv_parkiralista",
        "naplata",
        "vrsta",
        "povrsina",
        "opseg",
        "polozaj",
        "smjer",
        "podloga",
        "sirina",
        "duzina",
      ],
      hidden: [],
    },
    sidebar: {
      fields: ["id", "naziv_parkiralista", "show_on_map"],
    },
    attribute_table: {
      fields: ["id", "naziv_parkiralista", "deselect"],
      hidden: [],
    },
  },
  fields: [
    coreFields.id,
    {
      title: "Naziv",
      source: "naziv_parkiralista",
      ttoken: "parkiralista.naziv",
      type: "text",
    },
    {
      title: "Naplata",
      source: "naplata",
      ttoken: "parkiralista.naplata",
      type: "picker",
      items: {
        labels: ["parkiralista.da", "parkiralista.ne"],
        values: [true, false],
      },
    },
    {
      title: "Vrsta",
      source: "vrsta",
      ttoken: "parkiralista.vrsta",
      type: "text",
    },
    {
      title: "Površina",
      source: "povrsina",
      ttoken: "parkiralista.povrsina",
      type: "numeric",
    },
    {
      title: "Opseg",
      source: "opseg",
      ttoken: "parkiralista.opseg",
      type: "numeric",
    },
    {
      title: "Položaj",
      source: "polozaj",
      ttoken: "parkiralista.polozaj",
      type: "text",
    },
    {
      title: "Smjer",
      source: "smjer",
      ttoken: "parkiralista.smjer",
      type: "text",
    },
    {
      title: "Podloga",
      source: "podloga",
      ttoken: "parkiralista.podloga",
      type: "text",
    },
    {
      title: "Dužina",
      source: "duzina",
      ttoken: "parkiralista.duzina",
      type: "numeric",
    },
    {
      title: "Širina",
      source: "sirina",
      ttoken: "parkiralista.sirina",
      type: "numeric",
    },
    {
      title: "",
      source: "show_on_map",
      ttoken: "",
      tooltip: "buttons.show_on_map",
      type: "button",
      icon: "fmd_good",
      action: "show_on_map",
      padding: "none",
      sort: false,
    },
    commonFields.wkt,
    commonFields.proj,
    buttonFields.deselect,
  ],
};

export default ParkiralisnaMjestaModel;
