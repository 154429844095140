import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//MUI Components
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//MUI Icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LayersIcon from '@mui/icons-material/Layers'


//Custom Components
import MapCard from "@/ui/MapCard/MapCard";
import MapCardHeader from "@/ui/MapCard/MapCardHeader";
import MapCardAvatar from "@/ui/MapCard/MapCardAvatar";
import MapCardContent from "@/ui/MapCard/MapCardContent";
import MapCardActions from "@/ui/MapCard/MapCardActions";
import MapCardActionButton from "@/ui/MapCard/MapCardActionButton";
import dataController from "@/lib/dataController";
import MapCardHeaderButtonClose from "@/ui/MapCard/MapCardHeaderButtonClose";
import { GridContainer, GridItem } from "@/ui/Grid";
import ModelTable from "@/ui/Table/ModelTable";
// import { dataService } from "@/services/dataService";
import FormContent from "@/components/FormContent";
import KucaZaOdmorPanel from "./KucaZaOdmorPanel";

// import modelPregled from "Models/pregledi";
// import ImageSlider from "@/components/ImageSlider";

// Models
import modelData from "@/models/data";
import modelDKP from "@/models/dkp";
import modelKB from "@/models/adrese";
import modelNC from "@/models/nc";
import modelDKP2 from "@/models/dkp2";
import modelDKP2Zgrade from "@/models/dkp2zgrade";
import modelPosjednici from "@/models/posjednici";
import modelVlasnici from "@/models/zk_vlasnici";
import modelRasvjetaMjesta from "@/models/rasvjetna_mjesta";
import modelLokacije from "@/models/lokacije";
import modelParkiralista from "@/models/parkiralisna_mjesta";
import modelOdvoz from "@/models/odvoz_otpada";
import modelKuceZaOdmor from "@/models/kuce_za_odmor";
import modelDSM from "@/models/dsm";
import modelDEM from "@/models/dem";

//Types
import { DCRecord } from "@/@types/lib/dataController";

export type CardPosition = [number, number];

import { GFIFeatureType } from "@/services/gsService";
import RenderFeature from "ol/render/Feature";
import { Geometry } from "ol/geom";
import { Feature } from "ol";
import { CardOverlayType, ICardOverlayData, IMultiCardOverlayData } from "@/@types/views/GIS/map";
import { Button, Pagination, Stack } from "@mui/material";

export interface GSInfoCardProps {
  featureData: IMultiCardOverlayData;
  onClose: any;
  expandedVlasnici: boolean;
  setExpandedVlasnici: React.Dispatch<React.SetStateAction<boolean>>;
  expandedPosjednici: boolean;
  setExpandedPosjednici: React.Dispatch<React.SetStateAction<boolean>>;
  expandedKuceZaOdmor: boolean;
  setExpandedKuceZaOdmor: React.Dispatch<React.SetStateAction<boolean>>;
  setIndex: React.Dispatch<React.SetStateAction<any>>
  currentIndex: any
  handlePopoverOverlayClick: any
  layerPickerDisabled?: boolean
}

function GSInfoCard(props: GSInfoCardProps) {
  const { t } = useTranslation();

  const initialRecod = props.featureData.record ? props.featureData.record : props.featureData.features[0].properties;

  const [record, setRecord] = useState(initialRecod);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [loadStatus, setLoadStatus] = useState({
    isLoading: true,
    isLoaded: false,
  });

  const [recordsPosjednici, setRecordsPosjednici] = useState<DCRecord[]>([]);
  const [recordsVlasnici, setRecordsVlasnici] = useState<DCRecord[]>([]);

  const [page, setPage] = useState<number>(1);

  const {
    featureData,
    onClose,
    expandedPosjednici,
    setExpandedPosjednici,
    expandedVlasnici,
    setExpandedVlasnici,
    expandedKuceZaOdmor,
    setExpandedKuceZaOdmor,
    setIndex,
    currentIndex,
    handlePopoverOverlayClick,
    layerPickerDisabled
  } = props;

  const { type } = featureData;

  const getModel = () => {
    switch (type) {
      case "dkp":
        return modelDKP;
      case "kb":
        return modelKB;
      case "nc":
        return modelNC;
      case "dkp2":
        return modelDKP2;
      case "dkp2zgrade":
        return modelDKP2Zgrade;
      case "rasvjeta":
        return modelRasvjetaMjesta;
      case "lok":
        return modelLokacije;
      case "parking":
        return modelParkiralista;
      case "odvoz":
        return modelOdvoz;
      case "kuce_za_odmor":
        return modelKuceZaOdmor;
      case "dsm":
        return modelDSM;
      case "dem":
        return modelDEM;
      default:
        return modelData;
    }
  };

  const model = getModel();

  const dc = new dataController(model);
  const dcPosjednici = new dataController(modelPosjednici);
  const dcVlasnici = new dataController(modelVlasnici);

  const faIconClass = "fas fa-info ";

  const recordId = record ? record.id : null;

  const handleRefresh = function () {
    setRefreshCounter(refreshCounter + 1);

    setLoadStatus({ isLoading: true, isLoaded: false });
  };

  const handleTogglePosjednici = function () {
    const newValue = !expandedPosjednici;
    setExpandedPosjednici(newValue);
    if (newValue) {
      setExpandedVlasnici(false);
    }
  };

  const handleToggleVlasnici = function () {
    const newValue = !expandedVlasnici;
    setExpandedVlasnici(newValue);
    if (newValue) {
      setExpandedPosjednici(false);
    }
  };

  const handleToggleKuceZaOdmor = function () {
    const newValue = !expandedKuceZaOdmor;
    setExpandedKuceZaOdmor(newValue);
  };

  const loadPosjedniciDKP2 = () => {
    if (record) {
      dcPosjednici
        .GetData(`data/dkp2/${record.id}/posjednici/`)
        .then((resp) => {
          if (resp.success && Array.isArray(resp.data)) {
            setRecordsPosjednici(resp.data);
          }
        });
    }
  };

  const loadPosjedniciDKP = () => {
    if (record) {
      dcPosjednici.GetData(`data/dkp/${record.id}/posjednici/`).then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          setRecordsPosjednici(resp.data);
        }
      });
    }
  };

  const loadVlasniciDKP = () => {
    if (record) {
      dcVlasnici.GetData(`data/dkp/${record.id}/zk-vlasnici/`).then((resp) => {
        if (resp.success && Array.isArray(resp.data)) {
          setRecordsVlasnici(resp.data);
        }
      });
    }
  };

  const handleOdvozLocationRedirect = () => {
    const locId =
      initialRecod.lokacija_i === null
        ? ""
        : `&loc_id=${initialRecod.lokacija_i}`;
    if (locId !== "") {
      const url =
        location.origin +
        "/komunalne-obveze/?" +
        locId.toString() +
        "&open_first=true";
      window.open(url, "_blank");
    } else {
      const url = location.origin + "/komunalne-obveze";
      window.open(url, "_blank");
    }
  };

  const handleKomunaneObvezeRedirect = () => {
    const locId =
    initialRecod.lokacija_id === null
    ? ""
    : `&loc_id=${initialRecod.lokacija_id}`;
    if (locId !== "") {
      const url =
        location.origin +
        "/komunalne-obveze/?" +
        locId.toString() +
        "&open_first=true";
      window.open(url, "_blank");
    } else {
      const url = location.origin + "/komunalne-obveze";
      window.open(url, "_blank");
    }
  };

  const handleFotodokumentacijaRedirect = () => {
    const objektId =
      initialRecod.objekt_id === null
        ? ""
        : `${initialRecod.objekt_id}`;
    console.log(initialRecod)
    if (objektId !== "") {
      const url =
        location.origin +
        "/fotodokumentacija-objekata/?rec_id=" + objektId.toString();
      window.open(url, "_blank");
    } else {
      const url = location.origin + "/fotodokumentacija-objekata";
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    if (recordId) {
      if (type === "dkp") {
        loadPosjedniciDKP();
        loadVlasniciDKP();
      } else if (type === "dkp2") {
        loadPosjedniciDKP2();
      }
    }
  }, [recordId]);

  useEffect(() => {
    //console.log("Feature data", featureData)
    if (featureData) {
      if (featureData.record) {
        setRecord(featureData.record);
      } else if (featureData.features[currentIndex[type]]) {
        setRecord(featureData.features[currentIndex[type]].properties);
      } else if (featureData.features[0]) {
        setIndex((prev:any) => {
          let obj= {...prev}
          obj[type] = 0;
          return obj;
        })
        setRecord(featureData.features[0].properties);
      }
    }
    setRecord;
  }, [featureData, currentIndex]);

  useEffect(()=>{
    if (currentIndex && Object.keys(currentIndex).length>0) {
      setPage(currentIndex[type]+1); 
    }
  },[type])

  const form = "default";
  const title = record.naziv
    ? record.naziv
    : type
    ? t("map:layer." + type)
    : "Info";

  const hasVlasniciButton = type === "dkp" ? true : false;
  const hasPosjedniciButton = type === "dkp" || type === "dkp2" ? true : false;
  const hasOdvozLocationRedirectButton = type === "odvoz" ? true : false;
  const hasKuceZaOdmorButton = type === "kuce_za_odmor" ? true : false;

  const hasActions =
    hasVlasniciButton ||
    hasPosjedniciButton ||
    hasOdvozLocationRedirectButton ||
    hasKuceZaOdmorButton;

  return (
    <Fragment>
    <MapCard
      sx={{
        backgroundColor: "background.default",
        minWidth: expandedPosjednici || expandedVlasnici ? "580px" : featureData.features.length<=1?"380px":"450px"
      }}
    >
      <MapCardHeader 
        disableTypography
        avatar={<MapCardAvatar ariaLabel="info" icon={<i className={faIconClass}></i>} />}
        action={
        <MapCardHeaderButtonClose sx={{ml:1}} onClick={onClose} />
        }
        title={
          <div style={{display: "flex",justifyContent: "space-between",flexDirection: "row",width:"100%"}}>

            <Stack justifyContent={"center"} color={"white"}>
              <Typography variant="body1">{title}</Typography>
              {type === "dsm" || type === "dem" ? null
              : <Typography variant="body2">{t("common.id") + ": " + record.id}</Typography>}
            </Stack>
            <Stack alignItems={"center"}>

              { !layerPickerDisabled &&
              <Button 
                variant="outlined" 
                aria-label="close" 
                aria-controls="close" 
                onClick={handlePopoverOverlayClick}
                color="inherit"
              >
                {/*type.toUpperCase()*/}
                <LayersIcon sx={{p:0}}/>
              </Button>
              }
              <Pagination
                hidden={featureData.features.length<=1}
                size="small"
                shape={"rounded"}
                count={featureData.features.length}
                defaultPage={1}
                siblingCount={0}
                boundaryCount={0}
                sx={{
                  mt:1
                }}
                page={page}
                onChange={(event,page)=>{
                  setPage(page);
                  setIndex((prev:any) => {
                    let obj= {...prev}
                    obj[type] = page - 1;
                    return obj;
                    });
                }}
              />
            </Stack>
          </div>
        }
      > 
      </MapCardHeader>
      <MapCardContent>
        <GridContainer spacing={0}>
          <GridItem xs={12} container>
            {/* <Form dc={dc} mode="view" form={form} record={record}> */}
            <GridContainer spacing={0}>
              {type === "dsm" || type === "dem" ?
              <FormContent
                // dc={dc}
                // form={form}
                fields={dc.getFormFields(form)}
                fieldNames={dc.getFormFieldsNames(form)}
                columns={1}
                mode="simpleView"
                record={record}
              />
              : 
              <FormContent
                // dc={dc}
                // form={form}
                fields={dc.getFormFields(form)}
                fieldNames={dc.getFormFieldsNames(form)}
                columns={1}
                mode="simpleView"
                record={record}
              />}
            </GridContainer>
            {/* </Form> */}
          </GridItem>
          {/* <GridItem xs={6}>Image Placeholder</GridItem> */}
        </GridContainer>
      </MapCardContent>
      {hasActions ? (
        <MapCardActions>

          {hasPosjedniciButton ? (
            <MapCardActionButton
              startIcon={<AssignmentIndIcon />}
              endIcon={
                <ExpandMoreIcon
                  sx={[
                    {
                      transform: 'rotate(0deg)',
                      transition: (theme) => theme.transitions.create('all', {
                        duration: theme.transitions.duration.shortest,
                      })
                    },
                    expandedPosjednici && {
                      transform: 'rotate(180deg)'
                    }
                  ]}
                />
              }
              onClick={handleTogglePosjednici}
              aria-expanded={expandedPosjednici}
              aria-label="expand"
              disabled={recordsPosjednici.length === 0}
            >
              {t("buttons.posjednici")}
            </MapCardActionButton>
          ) : null}

          {hasVlasniciButton ? (
            <MapCardActionButton
              startIcon={<AssignmentIndIcon />}
              endIcon={
                <ExpandMoreIcon
                  sx={[
                    {
                      transform: 'rotate(0deg)',
                      transition: (theme) => theme.transitions.create('all', {
                        duration: theme.transitions.duration.shortest,
                      })
                    },
                    expandedVlasnici && {
                      transform: 'rotate(180deg)'
                    }
                  ]}
                />
              }
              onClick={handleToggleVlasnici}
              aria-expanded={expandedVlasnici}
              aria-label="expand"
              disabled={recordsVlasnici.length === 0}
            >
              {t("buttons.vlasnici")}
            </MapCardActionButton>
          ) : null}

          {hasKuceZaOdmorButton ? (
            <MapCardActionButton
              startIcon={<AssignmentIndIcon />}
              endIcon={
                <ExpandMoreIcon
                  sx={[
                    {
                      transform: "rotate(0deg)",
                      transition: (theme) =>
                        theme.transitions.create("all", {
                          duration: theme.transitions.duration.shortest,
                        }),
                    },
                    expandedKuceZaOdmor && {
                      transform: "rotate(180deg)",
                    },
                  ]}
                />
              }
              onClick={handleToggleKuceZaOdmor}
              aria-expanded={expandedKuceZaOdmor}
              aria-label="expand"
              //disabled={recordsKuceZaOdmor.length === 0}
            >
              {t("buttons.edit")}
            </MapCardActionButton>
          ) : null}

          {hasKuceZaOdmorButton ? (
            <MapCardActionButton
              startIcon={<RequestPageIcon />}
              onClick={handleKomunaneObvezeRedirect}
              aria-label="kuce-za-odmor"
              variant="outlined"
            >
              {t("buttons.show_object_short")}
            </MapCardActionButton>
          ) : null}

          {hasKuceZaOdmorButton ? (
            <MapCardActionButton
              startIcon={<PhotoCameraIcon />}
              onClick={handleFotodokumentacijaRedirect}
              aria-label="kuce-za-odmor"
              variant="outlined"
            >
              {t("buttons.show_photos_short")}
            </MapCardActionButton>
          ) : null}

          {hasOdvozLocationRedirectButton ? (
            <MapCardActionButton
              startIcon={<AssignmentIndIcon />}
              onClick={handleOdvozLocationRedirect}
            >
              {t("buttons.lokacija")}
            </MapCardActionButton>
          ) : null}   

        </MapCardActions>
      ) : null}
       {hasPosjedniciButton || hasVlasniciButton || hasKuceZaOdmorButton ? (
        <Collapse in={expandedPosjednici || expandedVlasnici || expandedKuceZaOdmor} timeout="auto" unmountOnExit sx={{ maxHeight: 400, overflowX: 'scroll' }}>
          { expandedPosjednici ? 
          <ModelTable 
            title=" " 
            tableName="posjednici"
            viewName="default"
            records={recordsPosjednici || []} 
            dc={dcPosjednici} 
            allowSelection="none" 
            allowAdd={false}
            allowRowAction={(() => { return false; })}
            allowRowsPerPageSelection={false}
            onRowAction={() => {}} 
            smallButtons={true}
            disableControls
            /> : null }
            { expandedVlasnici ?
              <ModelTable 
              title=" " 
              tableName="vlasnici"
              viewName="default"
              records={recordsVlasnici || []} 
              dc={dcVlasnici} 
              allowSelection="none" 
              allowAdd={false}
              allowRowAction={(() => { return false; })}
              allowRowsPerPageSelection={false}
              onRowAction={() => {}} 
              smallButtons={true}
              disableControls
              />
            : null }
            {expandedKuceZaOdmor ? (
              <Box m={2}>
                <KucaZaOdmorPanel recordId={recordId} onClose={() => {onClose()}} />
              </Box>
            ) : null}
        </Collapse>
      ) : null}    
    </MapCard>
  </Fragment>
  );
}

export default GSInfoCard;
