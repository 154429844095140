// @ts-nocheck

import React, { useState, useEffect } from "react";

import Dropzone, { DropEvent, FileRejection } from 'react-dropzone'

//import "react-dropzone-uploader/dist/styles.css";
//import { IFileWithMeta } from "react-dropzone-uploader";
//  https://react-dropzone-uploader.js.org/docs/props

import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";

import {nanoid} from "nanoid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faFilePdf, faFileWord, faFile } from "@fortawesome/free-solid-svg-icons";
import api from "@/lib/api";
import arrayBufferToBase64 from "@/lib/arrayBufferToBase64";

// Materil UI Icons
import CloseIcon from "@mui/icons-material/Close";

// Material UI Core
import { Typography, useTheme } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from '@mui/material/Box';
import Card from "@mui/material/Card";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";

//Types
import { IFileControlProps } from "@/@types/controls/controls";
import { DCFile } from "@/@types/lib/dataController";
import { IFieldFile } from "@/@types/models/model";

const customStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  fontFamily: "Helvetica, sans-serif",
  fontSize: "20px",
  fontWeight: 600,
  cursor: "pointer",
  backgroundColor: "#FFF",
  margin: 0
};

function FileControl(props: IFileControlProps) {
  const [fileIds, setFileIds] = useState<Array<string>>([]);
  const [fileNumber, setFileNumber] = useState(0);
  const [firstEntry, setFirstEntry] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [activeImage, setActiveImage] = useState<DCFile | null>(null);
  const [fileValidation, setFileValidation] = useState<string | null>(null);

  const { t } = useTranslation();
  const theme = useTheme();
  
  const apiInstance = new api();

  const { field, value, onChange, controlMode, accept, validation } = props;

  const isReadOnly = controlMode === "view" || (field && field.readonly) ? true : false;
  const isRequired = field.validation && field.validation.required ? true : false;
  const hasError = validation && validation.valid === false ? false : true;
  const hasValue = value && Array.isArray(value) ? true : false;

  const hasMaxFiles = field.hasOwnProperty("maxFiles") ? true : false;
  const maxFiles: number = field.hasOwnProperty("maxFiles") ? ((field as IFieldFile).maxFiles || 3) : 3;
  const valueLength = value && Array.isArray(value) ? value.length : 0;
  const maxFilesCalculated = maxFiles - valueLength;

  const label = t(field.ttoken);
  const newStyle = Object.assign({ color: theme.palette.primary.dark }, customStyle);

  const valueArrays = Array.isArray(value) && value.length > 5 ? [value.slice(0, 5), value.slice(5)] : [value];

  useEffect(() => {
    const fIds: Array<string> = [];
    let firstEntry = false;
    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (val.uuid) {
          fIds.push(val.uuid as string);
        }
      });
      firstEntry = true;
    } else if (value && value.uuid) {
      fIds.push(value.uuid as string);
      firstEntry = true;
    }

    setFileIds(fIds);
    setFirstEntry(firstEntry);
  }, []);

  useEffect(() => {
    if(value !== null && value !== undefined && Array.isArray(value)) {
      for (let i in value) {
        // TODO: Better checking if photo is previously uploaded, this works for now
        if (!value[i].hasOwnProperty('uuid') && !value[i].hasOwnProperty('name')) {
          const createdFile = convertBase64ToImage(value[i]);
          value[i] = createdFile;
        }
      }
    }

    if (!firstEntry) {
      if (Array.isArray(value)) {
        setFileIds(value.map((x) => x.uuid));
        setFirstEntry(false);
      } else if (value && typeof value === "object" && value.hasOwnProperty("uuid")) {
        if (value.uuid !== null) {
          setFileIds([value.uuid as string]);
          setFirstEntry(true);
        }
      }
    }
  }, [value]);

  const convertBase64ToImage = (image: any) => {
    // Encoding the image object so that it has the right structure to be "loaded"
    const file = {
      content: image.photos,
      id: image.id,
      name: image.image_name,
      size: image.image_size,
      type: image.image_type,
      uuid: ""
    }

    return file;
  };
  
  const handleOpenFile = (file: DCFile) => {
    if (field.type === "images") {
      if (file?.uuid == '') { //file.hasOwnProperty("image") 
        console.log("Opened file: ", file);
        setActiveImage(file);
      } else if (file.hasOwnProperty("uuid")) {
        apiInstance.Download(field.type, file.uuid).then((resp) => {
          (resp as Response).arrayBuffer().then((data) => {
            const imageB64 = arrayBufferToBase64(data);
            const imageObj = Object.assign({}, file);
            Object.assign(imageObj, { image: imageB64 });

            setActiveImage(imageObj);
          });
        });
      }
    } else if (file.type.indexOf("image") >= 0 && file.hasOwnProperty("uuid")) {
      apiInstance.Download(field.type, file.uuid).then((resp) => {
        (resp as Response).arrayBuffer().then((data) => {
          const imageB64 = arrayBufferToBase64(data);
          const imageObj = Object.assign({}, file);
          Object.assign(imageObj, { image: imageB64 });

          setActiveImage(imageObj);
        });
      });
    } else {
      if (file.uuid) {
        const path = "dokumenti";
        apiInstance.Download(path, file.uuid).then((resp) => {
          (resp as Response).blob().then((data) => {
            const fileBlob = new Blob([data], { type: file.type });
            FileSaver.saveAs(fileBlob, file.name);
          });
        });
      }else if(file.id && file.content){
        const fileBlob = new Blob([window.atob(file.content)], { type: file.type });
        FileSaver.saveAs(fileBlob, file.name);
      }
    }
  };

  const handleRemoveFile = (file: DCFile) => {
    const fileId = file.uuid || file.id;
    const pos = fileIds.indexOf(fileId);
    const fileList = Array.isArray(value) ? (value as Array<DCFile>).filter((val) => (val.uuid || val.id) !== fileId) : [];

    if (pos >= 0) {
      setFileIds(fileIds.filter((x, i) => i !== pos));
    }

    if (activeImage && fileId === (activeImage.id || activeImage.uuid)) {
      setActiveImage(null);
    }

    if (onChange) {
      onChange(fileList, field.source);
    }
  };

  
  const handleDrop = <T extends File>(acceptedFiles:T[], rejectedFiles:FileRejection[], event:DropEvent)=>{
    
    let rejectedFileNames:string ="Rejected: ";
    for(let rejected of rejectedFiles){
      console.log("Rejected file " + rejected.file.name + ", ", rejected.errors);
      rejectedFileNames+=rejected.file.name + " (";
      rejected.errors.forEach((error: any, i: any) =>{
        rejectedFileNames += error.code + (i < rejected.errors.length ? ", " : "");
      });
      rejectedFileNames+="), ";
    }
    if(rejectedFiles.length>0) setFileValidation(rejectedFileNames);
    else( setFileValidation(null))

    let fileList: Array<DCFile> = value ? (Array.isArray(value) ? [...value] : [value]) : [];

      setFileNumber(acceptedFiles.length);

      const filePromise = acceptedFiles.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
              const newFileB64 = arrayBufferToBase64(reader.result as ArrayBuffer);
              let fileObj:DCFile = {
                content: newFileB64,
                name: file.name,
                size: file.size,
                type: file.type,
                id: nanoid(),
                uuid: ''
              };
              // if(file.type.includes("image")) fileObj.image=newFileB64;
              fileList.push(fileObj);
            };
            reader.onerror = (e) => {
              console.log("ERROR ===>");
              console.error(e);
            };
            reader.onloadend = () => {
              resolve(true);
            };
            reader.readAsArrayBuffer(file);
          })
      );

      Promise.all(filePromise).then(() => {
        if (onChange) {
          onChange(fileList, field.source);
        }
      });
  }

  const handleHover = (hovered: boolean) => {
    setHovered(hovered);
  };

  const deactivateImage = () => {
    setActiveImage(null);
  };

  // const handleSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
  //   // console.log(files.map((f) => f.meta));
  //   // allFiles.forEach(f => f.remove())
  // };
  
  return (
    <FormControl margin="none" fullWidth required={isRequired} error={hasError}>
      {/**
      <FormLabel>
        {`${label} (${t("titles.max")} ${maxFiles} ${
          maxFiles === 1
            ? t("titles.files_sg")
            : maxFiles % 10 >= 2 && maxFiles % 10 < 5
            ? t("titles.files_pl2")
            : t("titles.files_pl1")
        })`}
      </FormLabel>
      */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        
          <Box sx={{}}>
            <Dropzone
              accept={accept}
              multiple={true}
              disabled={isReadOnly || valueLength==maxFiles}
              maxFiles={maxFilesCalculated > 0 ? maxFilesCalculated : 0}
              maxSize={5 * 1024 * 1024}
              onDrop={handleDrop}>
              {({
                // @ts-ignore
                getRootProps, getInputProps, isDragActive
               }) => (
                <section>
                  <Box {...getRootProps()} 
                    id="dropzone-box"
                    sx = {
                      {
                        border: "2px dashed #ccc",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderColor: isDragActive ? theme.palette.primary.main : "#ccc",
                        height: '75px',
                        color: isDragActive ? theme.palette.primary.main : "#ccc",
                        backgroundColor: theme.palette.background.default,
                        ":hover": valueLength < maxFiles ? {
                          cursor: "pointer",
                          borderColor: theme.palette.primary.main,
                          color: theme.palette.primary.main
                        } : {}
                      }
                    }
                    >
                    <input {...getInputProps()} />
                    <Typography id="dropzone-text" sx={{ textAlign: 'center' }}>{t("comment.dropzone_initial_msg")} </Typography>
                  </Box>
                </section>
              )}
            </Dropzone>
          </Box>
          <Box
            style={{
              border: "2px solid #d9d9d9",
            }}
          >
            <p style={{ position: "absolute", top: 0, right: 0, padding: "4px" }}>{`${valueLength}/${maxFiles}`}</p>
            {Array.isArray(valueArrays)
              ? valueArrays.map((arr, j) => {
                  return (
                    <div
                      key={j}
                      style={{
                        display: "flex"
                      }}
                    >
                      {Array.isArray(arr)
                        ? arr.map((val, i) => {
                            let type = val && val.type ? val.type : "";
                            const name =
                              val && val.name
                                ? val.name.length > 13
                                  ? val.name.substring(0, 14) + "..."
                                  : val.name
                                : "";
                            // if (type === undefined || type === null)  {
                            //   type = '';
                            // }
                            const icon =
                              type.indexOf("pdf") >= 0
                                ? faFilePdf
                                : type.indexOf("image") >= 0
                                ? faImage
                                : type.indexOf("docx") >= 0
                                ? faFileWord
                                : faFile;
                            return (
                              <span key={"div" + i} style={{ margin: 5, textAlign: "center" }}>
                                <FontAwesomeIcon
                                  icon={icon}
                                  color="grey"
                                  size="2x"
                                  key={"icon" + i}
                                  style={{ margin: "auto" }}
                                  onClick={() => handleOpenFile(val)}
                                />
                                {!isReadOnly ? (
                                  <Badge
                                    key={"badge" + i}
                                    badgeContent={<CloseIcon fontSize="inherit" />}
                                    color="primary"
                                    onClick={() => handleRemoveFile(val)}
                                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                                  />
                                ) : null}
                                <label key={"label" + i} style={{ display: "block" }}>
                                  {name}
                                </label>
                              </span>
                            );
                          })
                        : null}
                    </div>
                  );
                })
              : null}
          </Box>
       
      </div>
      {activeImage && activeImage.hasOwnProperty("image") ? (
        <Card onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
          {hovered ? (
            <Fab
              size="small"
              style={{ position: "absolute", backgroundColor: "#E52E2E", bottom: 0 }}
              onClick={deactivateImage}
            >
              <CloseIcon />
            </Fab>
          ) : null}
          <img
            src={`data:image/jpeg;base64, ${activeImage.image}`}
            style={{ display: "block", maxWidth: "100%", maxHeight: "100%" }}
          />
        </Card>
      ) : null}
      <FormHelperText id={field.source + "-helper"} error={hasError || fileValidation ? true : false}>
        {hasError && validation ? validation.msg : ""}
        {fileValidation ? fileValidation : ""}
      </FormHelperText>
    </FormControl>
  );
}

export default FileControl;











// Old commented code



// import React, { useState, useEffect } from "react";
// import "react-dropzone-uploader/dist/styles.css";
// import Dropzone from "react-dropzone-uploader";
// //  https://react-dropzone-uploader.js.org/docs/props
// import { useTranslation } from "react-i18next";
// import FileSaver from "file-saver";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faImage, faFilePdf, faFileWord, faFile } from "@fortawesome/free-solid-svg-icons";
// import api from "@/lib/api";
// import arrayBufferToBase64 from "@/lib/arrayBufferToBase64";

// // Materil UI Icons
// import CloseIcon from "@mui/icons-material/Close";

// // Material UI Core
// import { useTheme } from "@mui/material";
// import Badge from "@mui/material/Badge";
// import Box from '@mui/material/Box';
// import Card from "@mui/material/Card";
// import Fab from "@mui/material/Fab";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import FormHelperText from "@mui/material/FormHelperText";

// //Types
// import { IFileControlProps } from "@/@types/controls/controls";
// import { DCFile } from "@/@types/lib/dataController";
// import { IFieldFile } from "@/@types/models/model";
// import { IDropzoneProps} from "react-dropzone-uploader";
// //TODO: refactor > move to react-dropzone

// const customStyle = {
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   position: "absolute",
//   top: 0,
//   bottom: 0,
//   left: 0,
//   right: 0,
//   fontFamily: "Helvetica, sans-serif",
//   fontSize: "20px",
//   fontWeight: 600,
//   cursor: "pointer",
//   backgroundColor: "#FFF",
//   margin: 0
// };

// function FileControl(props: IFileControlProps) {
//   const [fileIds, setFileIds] = useState<Array<string>>([]);
//   const [fileNumber, setFileNumber] = useState(0);
//   const [firstEntry, setFirstEntry] = useState(false);
//   const [hovered, setHovered] = useState(false);
//   const [activeImage, setActiveImage] = useState<DCFile | null>(null);
//   const [fileValidation, setFileValidation] = useState<string | null>(null);

//   const { t } = useTranslation();
//   const theme = useTheme();

//   const apiInstance = new api();

//   const { field, value, onChange, controlMode, accept, validation } = props;

//   useEffect(() => {
//     const fIds: Array<string> = [];
//     let firstEntry = false;
//     if (Array.isArray(value)) {
//       value.forEach((val) => {
//         if (val.uuid) {
//           fIds.push(val.uuid as string);
//         }
//       });
//       firstEntry = true;
//     } else if (value && value.uuid) {
//       fIds.push(value.uuid as string);
//       firstEntry = true;
//     }

//     setFileIds(fIds);
//     setFirstEntry(firstEntry);
//   }, []);

//   useEffect(() => {
//     if (!firstEntry) {
//       if (Array.isArray(value)) {
//         setFileIds(value.map((x) => x.uuid));
//         setFirstEntry(false);
//       } else if (value && typeof value === "object" && value.hasOwnProperty("uuid")) {
//         if (value.uuid !== null) {
//           setFileIds([value.uuid as string]);
//           setFirstEntry(true);
//         }
//       }
//     }
//   }, [value]);

//   const handleOpenFile = (file: DCFile) => {
//     if (field.type === "images") {
//       if (file.hasOwnProperty("image")) {
//         setActiveImage(file);
//       } else if (file.hasOwnProperty("uuid")) {
//         apiInstance.Download(field.type, file.uuid).then((resp) => {
//           (resp as Response).arrayBuffer().then((data) => {
//             const imageB64 = arrayBufferToBase64(data);
//             const imageObj = Object.assign({}, file);
//             Object.assign(imageObj, { image: imageB64 });

//             setActiveImage(imageObj);
//           });
//         });
//       }
//     } else if (file.type.indexOf("image") >= 0 && file.hasOwnProperty("uuid")) {
//       apiInstance.Download(field.type, file.uuid).then((resp) => {
//         (resp as Response).arrayBuffer().then((data) => {
//           const imageB64 = arrayBufferToBase64(data);
//           const imageObj = Object.assign({}, file);
//           Object.assign(imageObj, { image: imageB64 });

//           setActiveImage(imageObj);
//         });
//       });
//     } else {
//       if (file.uuid) {
//         const path = "dokumenti";
//         apiInstance.Download(path, file.uuid).then((resp) => {
//           (resp as Response).blob().then((data) => {
//             const fileBlob = new Blob([data], { type: file.type });
//             FileSaver.saveAs(fileBlob, file.name);
//           });
//         });
//       }
//     }
//   };

//   const handleRemoveFile = (file: DCFile) => {
//     const fileId = file.uuid || file.id;
//     const pos = fileIds.indexOf(fileId);
//     const fileList = Array.isArray(value) ? (value as Array<DCFile>).filter((val) => (val.uuid || val.id) !== fileId) : [];

//     if (pos >= 0) {
//       setFileIds(fileIds.filter((x, i) => i !== pos));
//     }

//     if (activeImage && fileId === (activeImage.id || activeImage.uuid)) {
//       setActiveImage(null);
//     }

//     if (onChange) {
//       onChange(fileList, field.source);
//     }
//   };

//   const handleChangeStatus: IDropzoneProps['onChangeStatus'] = ({ meta }, status, files) => {
//     const pos = fileIds.indexOf(meta.id); // position of the id in the fileIds array
//     let fileList: Array<DCFile> = [];

//     /*
//      ** if status is 'removed' id is spliced from the array
//      ** this has to be done because ( not sure about this part ) of the asinchronism of the removal of the file from the dropbox
//      ** if you remove the file from the dropbox it will not be removed from the files array from some reason
//      **
//      ** the idea is that it is faster to search array of 15-ish long strings than the array of 24kB big b64 strings
//      */
//     if (status === "error_file_size") {
//       files.pop();
//       setFileValidation(t("validation.file_too_big"));
//     } else if (status === "rejected_file_type") {
//       files.pop();
//       setFileValidation(t("validation.rejected_file_type"));
//     } else {
//       setFileValidation(null);
//     }

//     if (status === "done" && !firstEntry) {
//       setFirstEntry(true);
//     }

//     if (pos >= 0 && status == "removed") {
//       fileIds.splice(pos, 1);
//       setFileIds(prevState => prevState.filter((x,i) => i !== pos));
//       setFileNumber(prevState => prevState - 1);
//     } else if (status === "done") {
//       setFileIds(prevState => prevState.concat([meta.id]));
//       setFileNumber(prevState =>  prevState + 1);
//     }

//     // if more files is uploaded at once
//     const diff = fileIds.length - (Array.isArray(value) ? value.length : 0);

//     // if status is removed then the id of the image will not be in fileIds and this piece of code ensures it is not in the fileList
//     if (Array.isArray(value)) {
//       fileList = value.filter((val) => fileIds.indexOf(val.id || val.uuid) >= 0);
//     } else if (status === "removed") {
//       fileList = [];
//     }

//     // if status is done the last item in files is the latest image added
//     if (status === "done" && files.length === fileNumber + 1) {
//       // files.pop() would be better but then you remove it from the dropbox
//       const newImages = [];
//       for (let i = 1; i <= diff; i++) {
//         newImages.push(files[files.length - i]);
//       }

//       const filePromise = newImages.map(
//         (newImage) =>
//           new Promise((resolve) => {
//             const reader = new FileReader();
//             reader.onload = () => {
//               const newFileB64 = arrayBufferToBase64(reader.result as ArrayBuffer);
//               const fileObj = {
//                 content: newFileB64,
//                 name: newImage.meta.name,
//                 size: newImage.meta.size,
//                 type: newImage.meta.type,
//                 id: newImage.meta.id,
//                 uuid: '',
//                 width: newImage.meta.width,
//                 height: newImage.meta.height
//               };
//               fileList.push(fileObj);
//             };
//             reader.onerror = (e) => {
//               console.log("ERROR ===>");
//               console.error(e);
//             };
//             reader.onloadend = () => {
//               resolve(true);
//             };
//             reader.readAsArrayBuffer(newImage.file);
//           })
//       );

//       Promise.all(filePromise).then(() => {
//         if (onChange) {
//           onChange(fileList, field.source);
//         }
//       });
//     } else if (status === "removed") {
//       // if (onChange) {
//       //   onChange(fileList, value)
//       // }
//     }
//   };

//   const handleHover = (hovered: boolean) => {
//     setHovered(hovered);
//   };

//   const deactivateImage = () => {
//     setActiveImage(null);
//   };

//   const handleSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
//     // console.log(files.map((f) => f.meta));
//     // allFiles.forEach(f => f.remove())
//   };

//   // accept is a string with allowed input MIME types (comma separated)

//   const isReadOnly = controlMode === "view" || (field && field.readonly) ? true : false;
//   const isRequired = field.validation && field.validation.required ? true : false;
//   const hasError = validation && validation.valid === false ? false : true;
//   const hasValue = value && Array.isArray(value) ? true : false;

//   const hasMaxFiles = field.hasOwnProperty("maxFiles") ? true : false;
//   const maxFiles: number = field.hasOwnProperty("maxFiles") ? ((field as IFieldFile).maxFiles || 3) : 3;
//   const valueLength = value && Array.isArray(value) ? value.length : 0;
//   const maxFilesCalculated = fileNumber + maxFiles - valueLength;

//   const label = t(field.ttoken);
//   const newStyle = Object.assign({ color: theme.palette.primary.dark }, customStyle);

//   const valueArrays = Array.isArray(value) && value.length > 5 ? [value.slice(0, 5), value.slice(5)] : [value];
//   return (
//     <FormControl margin="none" fullWidth required={isRequired} error={hasError}>
//       <FormLabel>
//         {`${label} (${t("titles.max")} ${maxFiles} ${
//           maxFiles === 1
//             ? t("titles.files_sg")
//             : maxFiles % 10 >= 2 && maxFiles % 10 < 5
//             ? t("titles.files_pl2")
//             : t("titles.files_pl1")
//         })`}
//       </FormLabel>
//       <div style={{ display: "flex" }}>
//         <Dropzone
//           onChangeStatus={handleChangeStatus}
//           onSubmit={handleSubmit}
//           styles={{
//             dropzone: { minHeight: 56, overflow: "auto", width: "50%" },
//             preview: { display: "none" },
//             inputLabel: newStyle,
//             inputLabelWithFiles: newStyle
//           }}
//           inputWithFilesContent={t("components.dropzone.msg")}
//           inputContent={t("components.dropzone.msg")}
//           SubmitButtonComponent={null}
//           accept={accept}
//           maxFiles={maxFilesCalculated}
//           multiple
//           maxSizeBytes={5 * 1024 * 1024} // 1024*1024 is 1MB
//           disabled={isReadOnly}
//         />
//         <Box
//           style={{
//             width: "50%",
//             border: "2px solid #d9d9d9"
//           }}
//         >
//           <p style={{ position: "absolute", top: 0, right: 0, padding: "4px" }}>{`${valueLength}/${maxFiles}`}</p>
//           {Array.isArray(valueArrays)
//             ? valueArrays.map((arr, j) => {
//                 return (
//                   <div
//                     key={j}
//                     style={{
//                       display: "flex"
//                     }}
//                   >
//                     {Array.isArray(arr)
//                       ? arr.map((val, i) => {
//                           let type = val && val.type ? val.type : "";
//                           const name =
//                             val && val.name
//                               ? val.name.length > 13
//                                 ? val.name.substring(0, 14) + "..."
//                                 : val.name
//                               : "";
//                           // if (type === undefined || type === null)  {
//                           //   type = '';
//                           // }
//                           const icon =
//                             type.indexOf("pdf") >= 0
//                               ? faFilePdf
//                               : type.indexOf("image") >= 0
//                               ? faImage
//                               : type.indexOf("docx") >= 0
//                               ? faFileWord
//                               : faFile;
//                           return (
//                             <span key={"div" + i} style={{ margin: 5, textAlign: "center" }}>
//                               <FontAwesomeIcon
//                                 icon={icon}
//                                 color="grey"
//                                 size="2x"
//                                 key={"icon" + i}
//                                 style={{ margin: "auto" }}
//                                 onClick={() => handleOpenFile(val)}
//                               />
//                               {!isReadOnly ? (
//                                 <Badge
//                                   key={"badge" + i}
//                                   badgeContent={<CloseIcon fontSize="inherit" />}
//                                   color="primary"
//                                   onClick={() => handleRemoveFile(val)}
//                                   anchorOrigin={{ horizontal: "left", vertical: "top" }}
//                                 />
//                               ) : null}
//                               <label key={"label" + i} style={{ display: "block" }}>
//                                 {name}
//                               </label>
//                             </span>
//                           );
//                         })
//                       : null}
//                   </div>
//                 );
//               })
//             : null}
//         </Box>
//       </div>
//       {activeImage && activeImage.hasOwnProperty("image") ? (
//         <Card onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)}>
//           {hovered ? (
//             <Fab
//               size="small"
//               style={{ position: "absolute", backgroundColor: "#E52E2E", bottom: 0 }}
//               onClick={deactivateImage}
//             >
//               <CloseIcon />
//             </Fab>
//           ) : null}
//           <img
//             src={`data:image/jpeg;base64, ${activeImage.image}`}
//             style={{ display: "block", maxWidth: "100%", maxHeight: "100%" }}
//           />
//         </Card>
//       ) : null}
//       <FormHelperText id={field.source + "-helper"} error={hasError || fileValidation ? true : false}>
//         {hasError && validation ? validation.msg : ""}
//         {fileValidation ? fileValidation : ""}
//       </FormHelperText>
//     </FormControl>
//   );
// }

// export default FileControl;
