import api from "../lib/api";

export interface IKuceZaOdmorService {
  updateKucaZaOdmor: (id: number, status: number, komentar: string) => Promise<any>;
}

const KuceZaOdmorService: IKuceZaOdmorService = {
  updateKucaZaOdmor
};

function updateKucaZaOdmor(id: number, status: number, komentar: string) {
  const apiInstance = new api();
  const url = "kuce-za-odmor/" + id.toString()

  const data = {
    status: status,
    komentar: komentar
  }

  return apiInstance.Call(url, "put", data);
}

export default KuceZaOdmorService;