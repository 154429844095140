import React from "react";

//Material-UI Core Components
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";

//Custom Components
import PublicPage from "UI/PublicPage/PublicPage";
import RegistrationForm from "Views/Auth/RegistrationForm";

import model from "Models/user";
import dataController from "Lib/dataController";

function RegistrationPage() {

  const dc = new dataController(model)

  return (
    <PublicPage>
      <Box m={2}>
        <Container maxWidth={"sm"}>
          <RegistrationForm dc={dc} form="register" mode="update" onClose={() => {}} />
        </Container>
      </Box>
    </PublicPage>
  )
}
export default RegistrationPage;
