import api from "@/lib/api";
import arrayBufferToBase64 from "@/lib/arrayBufferToBase64";

export interface IImgService {
  getImage: (baseRecordPath: string, baseRecordId: number, imgNo: number, imgSize?: number, objExtent?: string) => Promise<any>;
  getObjImage: (baseRecordPath: string, baseRecordId: number, imgId: number, width: number, clip?: [number, number, number, number]) => Promise<any>;
  getRecordImageThumb: (baseRecordPath: string, baseRecordId: number, imageId: number) => Promise<any>;
}


const imgService: IImgService = {
  getImage,
  getObjImage,
  getRecordImageThumb
};

function getImage(baseRecordPath: string, baseRecordId: number, imgNo: number, imgSize?: number, objExtent?: string): Promise<any> {
  const apiInstance = new api();
  const endsWithSlash = baseRecordPath.lastIndexOf('/') === baseRecordPath.length - 1 ? true : false;
  const url = `${baseRecordPath}${endsWithSlash ? "" : "/"}${baseRecordId}/images?img_number=${imgNo}&img_size=${imgSize ? imgSize : 190}&extent=${objExtent ? objExtent : ""}`;
  // const url = baseRecordPath + baseRecordId.toString() 
  //   + "/images?img_number=" + imgNo.toString()
  //   + "&img_size=" + (imgSize ? imgSize : 190).toString()
  //   + "&extent=" + (objExtent ? objExtent : "");

  return apiInstance.Call(url, "get");
}

function getObjImage(baseRecordPath: string, baseRecordId: number, imgId: number, width: number, clip?: [number, number, number, number]): Promise<any> {
  const apiInstance = new api();
  const endsWithSlash = baseRecordPath.lastIndexOf('/') === baseRecordPath.length - 1 ? true : false;
  const clipParam = clip ? `&clip=${clip.join(',')}` : null
  const url = `${baseRecordPath}${endsWithSlash ? "" : "/"}${baseRecordId}/images/${imgId}?width=${width || 0}${clipParam ? clipParam : ''}`;

  return apiInstance.Call(url, "get");
}

// Returns thumbnail for the record's image
function getRecordImageThumb(baseRecordPath: string, baseRecordId: number, imageId: number) {
  const apiInstance = new api();

  const endsWithSlash = baseRecordPath.lastIndexOf('/') === baseRecordPath.length - 1 ? true : false;
  const url = `${baseRecordPath}${endsWithSlash ? "" : "/"}${baseRecordId}/images/${imageId}/thumb`;

  return apiInstance.Download(url).then((resp) => {
    if (resp.success && resp.data) {
      return resp.data.then((data: Blob) => {
        return data.arrayBuffer().then((arrBuffer: ArrayBuffer) => {
          return arrayBufferToBase64(arrBuffer);
        })
      });
    } else {
      return Promise.reject(null);
    }
  })
}

export default imgService;