import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment, { Moment } from "moment";
//MUI
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

// d.ts
import {
  DrawingType,
  CoordinateSystemType,
  BoolSettings,
  ViewOptionsType,
  IMapView,
} from "@/@types/common/index";
import {
  CardOverlayType,
  CardPosition,
  ICardOverlay,
  ICardOverlayData,
  IMultiCardOverlayData,
  IMapProps,
  IMapState,
  IMultiCardOverlay,
} from "@/@types/views/GIS/map";

// OpenLayers
import Geometry from "ol/geom/Geometry";
import proj4 from "proj4";
import { register as OlRegister } from "ol/proj/proj4";
import { get as OlGetProjection } from "ol/proj";
import { transform } from "ol/proj";
import Projection from "ol/proj/Projection";
import OlLayerGroup from "ol/layer/Group";
import OlSourceVector from "ol/source/Vector";
import Map from "@/components/Map/Map";
import MapEvent from "ol/MapEvent";
import { DrawEvent } from "ol/interaction/Draw";
import BaseEvent from "ol/events/Event";
import OlMapBrowserEvent from "ol/MapBrowserEvent";
import OlBaseLayer from "ol/layer/Base";
import OlFeature from "ol/Feature";
import OlCollection from "ol/Collection";
import OlPolygon from "ol/geom/Polygon";
import OlMultiPolygon from "ol/geom/MultiPolygon";
import OlPoint from "ol/geom/Point";
import OlFormatWKT from "ol/format/WKT";
import { Extent as OlExtent, getCenter as OlGetCenter } from "ol/extent";
import OlVectorLayer from 'ol/layer/Vector';

// Custom
import { Permissions as P } from "@/lib/permissions";
import {
  Controls,
  CoordZoomStatusControl,
  DefaultControls,
  FullScreenControl,
  MeasureControls,
  RotateControl,
  ScaleLineControl,
  ScaleRatioControl,
  StatusControl,
  ZoomControl,
  ZoomToExtentControl,
  GeoLocateControl,
  ZoomSliderControl,
  CenterMarkerControl,
  AttributeTableControl,
  LayerTree,
  ViewHistoryControl,
} from "@/components/Map/Controls";

import AttributeTable from "@/components/Map/Controls/AttributeTable/AttributeTable";

import {
  SidebarContent,
  SidebarControl,
  SidebarHeading,
  SidebarPane,
  SidebarTabs,
  SidebarTabList,
  SidebarTabListItem,
} from "@/components/Map/Controls/Sidebar";

import {
  InfoPane,
  LayersPane,
  MeasuresPane,
  DataPane,
  LegendPane,
} from "@/components/MapSidebarPanes";
import {
  Layers,
  TileLayer,
  VectorLayer,
  GroupLayer,
} from "@/components/Map/Layers";
import {
  Overlays,
  PopupOverlay,
  MeasureTooltipOverlay,
} from "@/components/Map/Overlays";
import {
  Interactions,
  DefaultInteractions,
  DrawInteraction,
} from "@/components/Map/Interactions";
import {
  measurementsStyle,
  measuringStyle,
  selectedRecordStyle,
  hoveringRecordStyle,
  commentsStyle,
  commentingStyle,
  highlightedVectorStyle,
} from "@/components/Map/mapStyles";
import OlStyle from "ol/style/Style";
import UserConsumer from "@/components/UserContext/UserConsumer";
import UserContext from "@/components/UserContext/UserContext";
import SnackbarContext from "@/ui/SnackbarContext/SnackbarContext";
import { flattenLayers } from "@/lib/olHelpers";

import GeoAPILayers from "@/components/Map/Layers/GEO/GeoAPILayers";
import GeoBaseLayerSwitcher from "@/components/Map/Controls/GEO/GeoBaseLayerSwticher";
import GSInfoCard from "./GSInfoCard";

import model from "@/models/objekti";
import modelAdrese from "@/models/adrese";
import modelDKP from "@/models/dkp";
import modelNC from "@/models/nc";
import modelRasvjetnaMjesta from "@/models/rasvjetna_mjesta";
import modelParkiralisnaMjesta from "@/models/parkiralisna_mjesta";
import modelKuceZaOdmor from "@/models/kuce_za_odmor";
import dataController from "@/lib/dataController";

import DialogContext from "@/ui/DialogContext/DialogContext";
import CommentDialog from "./CommentDialog";

//Services
import mapService from "@/services/mapService";
import gsService, { GFIGeometry } from "@/services/gsService";
import { authService } from "@/services/authService";
import { DCRecord } from "@/@types/lib/dataController";
import { GFIFeatureType } from "@/services/gsService";
import { getCalendarPickerSkeletonUtilityClass } from "@mui/lab";
import CommentsPane from "@/components/MapSidebarPanes/CommentsPane";
import modelComments from "@/models/comments";
import modelCommentPhotos from "@/models/comment_photos";
import CommentInfoCard from "./CommentInfoCard";
import RenderFeature from "ol/render/Feature";
import { MultiPolygon, Point, Polygon } from "ol/geom";
import { Popover, Tab, Tabs } from "@mui/material";

import useWindowSize, { Size } from "@/components/useWindowSize";

function MainMap(props: IMapProps) {
  const userContext = useContext(UserContext);
  const dialogContext = useContext(DialogContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);
  const [expandedPosjednici, setExpandedPosjednici] = useState(false);
  const [expandedVlasnici, setExpandedVlasnici] = useState(false);
  const [expandedKuceZaOdmor, setExpandedKuceZaOdmor] = useState(false);
  const wkt = new OlFormatWKT();

  const [mapInitialized, setMapInitialized] = useState(false);
  const [currentCommentFeature, setCurrentCommentFeature] = useState<any>({});

  // Default values
  let viewsState: IMapView = {
    center: userContext?.mapSettings
      ? userContext.mapSettings.initial_view_center
      : [1731757, 5581737],
    zoom: userContext?.mapSettings
      ? userContext.mapSettings.initial_view_zoom
      : 8,
  };

  // Values saved in localStorage
  const viewsStateJSON = localStorage.getItem(
    "viewsState_" + userContext?.username + "_" + userContext?.projektId
  );
  if (
    viewsStateJSON !== null &&
    viewsStateJSON !== undefined &&
    JSON.parse(viewsStateJSON).views.length > 1
  ) {
    const viewsStateHolder = JSON.parse(viewsStateJSON);
    // console.log(viewsStateHolder);
    viewsState = viewsStateHolder.views[viewsStateHolder.currentViewIndex - 1];
  }

  const [viewOptions, setViewOptions] = useState<ViewOptionsType>({
    center: viewsState.center,
    zoom: viewsState.zoom,
    extent: userContext?.mapSettings
      ? userContext.mapSettings.max_extent
      : undefined,
    //projection: wgs84PM,
    minZoom: 8,
    maxZoom: 21,
    constrainResolution: true,
    showFullExtent: true,
  });

  const [chosenCoordinateSystem, setChosenCoordinateSystem] =
    useState<CoordinateSystemType>("HTRS96/TM");
  const [layersCollection, setLayersCollection] = useState<
    OlCollection<OlBaseLayer> | undefined
  >(undefined);
  const [forceRefreshCounter, setForceRefreshCounter] = useState(0);
  const [drawType, setDrawType] = useState<DrawingType | undefined>(undefined);
  const [commentDrawType, setCommentDrawType] = useState<
    DrawingType | undefined
  >(undefined);
  const [feature, setFeature] = useState<OlFeature<Geometry> | undefined>(
    undefined
  );
  const [measuringFeature, setMeasuringFeature] = useState<
    OlFeature<Geometry> | undefined
  >(undefined);
  const [cardOverlay, setCardOverlay] = useState<ICardOverlay | undefined>(undefined);
  const [commentingFeature, setCommentingFeature] = useState<OlFeature<Geometry> | undefined>(undefined);
  const [overHighlightFeature, setOverlayHighlightFeature] = useState<OlFeature<Geometry> | undefined>(undefined);

  const [commentOverlay, setCommentOverlay] = useState<ICardOverlay | undefined>(undefined); // for comments
  const [multiCardOverlay, setMultiCardOverlay] = useState<IMultiCardOverlay[] | undefined>(undefined); // for all other layers
  const [overlayTabActive, setOverlayTabActive] = useState<number>(0);
  const [overlayFeatureIndex, setOverlayFeatureIndex] = useState<any>({});

  const [defaultExtent, setDefaultExtent] = useState(
    userContext?.mapSettings?.default_extent
  );

  const [workingRecords, setWorkingRecords] = useState<DCRecord[]>([]);
  const [workingSource, setWorkingSource] = useState<OlSourceVector<Geometry>>(
    new OlSourceVector({})
  );

  const [selectedRecords, setSelectedRecords] = useState<DCRecord[]>([]);
  const [selectedSource, setSelectedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));

  const [commentSource, setCommentSource] = useState<OlSourceVector<Geometry>>(
    new OlSourceVector({})
  );
  const [commentRecords, setCommentRecords] = useState<DCRecord[]>([]);
  const [overlaySource, setOverlaySource] = useState<OlSourceVector<Geometry>>(new OlSourceVector({}));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [drawingSource, setDrawingSource] = useState<OlSourceVector<Geometry>>(
    new OlSourceVector({})
  );
  const [measurementsSource, setMeasurementsSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));

  const [highlightedSource, setHighlightedSource] = useState<
    OlSourceVector<Geometry>
  >(new OlSourceVector({}));
  const [highlightedStyle, setHighlightedVectorStyle] = useState<OlStyle>(
    highlightedVectorStyle
  );

  const zoomToDefaultExtentElementRef = useRef<HTMLLIElement>(null);
  const zoomToSelectedExtentElementRef = useRef<HTMLLIElement>(null);
  const fullScreenElementRef = useRef<HTMLLIElement>(null);
  const geoLocateElementRef = useRef<HTMLLIElement>(null);
  const centerMarkerElementRef = useRef<HTMLLIElement>(null);

  const [recordsAdrese, setRecordsAdrese] = useState<DCRecord[]>([]);
  const [recordsDKP, setRecordsDKP] = useState<DCRecord[]>([]);
  const [recordsNC, setRecordsNC] = useState<DCRecord[]>([]);
  const [recordsRasvjetnaMjesta, setRecordsRasvjetnaMjesta] = useState<
    DCRecord[]
  >([]);
  const [recordsKuceZaOdmor, setRecordsKuceZaOdmor] = useState<DCRecord[]>([]);
  const [recordsParkiralisnaMjesta, setRecordsParkiralisnaMjesta] = useState<
    DCRecord[]
  >([]);

  // private layerSwitcherElementRef: React.RefObject<JSX.Element>;
  // private htrs96: Projection;
  // private wgs84: Projection;
  // private wgs84PM: Projection;
  // private defaultViewCenter: [number, number];
  // private initialDefaultExtent: [number, number, number, number];
  // private drawingSource: OlSourceVector<Geometry>;
  // private measurementsSource: OlSourceVector<Geometry>;

  // private fullScreenElementRef: React.RefObject<HTMLLIElement>;
  // private zoomToDefaultExtentElementRef: React.RefObject<HTMLLIElement>;

  const layerSwitcherElementRef = React.createRef<JSX.Element>();

  const dc = new dataController(
    model,
    "komunalni-obveznici/{lokacija_id}/objekti"
  );
  const dcAdrese = new dataController(modelAdrese);
  const dcDKP = new dataController(modelDKP);
  const dcNC = new dataController(modelNC);
  const dcRasvjetnaMjesta = new dataController(modelRasvjetnaMjesta);
  const dcParkiralisnaMjesta = new dataController(modelParkiralisnaMjesta);
  const dcKuceZaOdmor = new dataController(modelKuceZaOdmor);

  const MAP_ID = 1;

  //define proj
  proj4.defs(
    "EPSG:3765",
    "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
  );
  proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
  proj4.defs(
    "EPSG:31276",
    "+proj=tmerc +pm=greenwich +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=550.499,164.116,475.142,5.80967,2.07902,-11.62386,0.99999445824 +units=m +no_defs"
  );
  proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
  );
  OlRegister(proj4);

  const htrs96 = OlGetProjection("EPSG:3765");
  const wgs84 = OlGetProjection("EPSG:4326");
  const wgs84PM = OlGetProjection("EPSG:3857");

  // defaultViewCenter = [1731757, 5581737];
  // initialDefaultExtent = [1688674,5501166,1688774,5501266];
  const defaultViewCenter = userContext?.mapSettings?.initial_view_center;
  const initialDefaultExtent = userContext?.mapSettings?.default_extent;

  const commentsSource = useMemo(() => {
    const features = commentRecords.map((x) => {
      //@ts-ignore
      (x.geom as OlFeature).set('data_type', 'comment');
      return x.geom;
    });
    // @ts-ignore
    const commentsSource = new OlSourceVector({
      // @ts-ignore
      features: features as OlFeature<Geometry>[],
    });
    return commentsSource;
  }, [commentRecords]);

  const [commentsVisible, setCommentsVisible] = useState<boolean>(
    //@ts-ignore
    localStorage.getItem('maplayers') && localStorage.getItem('maplayers') !== null && JSON.parse(localStorage.getItem('maplayers')) ?
      //@ts-ignore
      JSON.parse(localStorage.getItem('maplayers'))['spatial-comments'] === true : false
  );

  // useEffect(() => {
  //   const features = commentRecords; // .map((x) => x.geom)
  //   let src = new OlSourceVector({});
  //   if (Array.isArray(features) && features.length > 0) {
  //     features.forEach((x) => {
  //       let ft = new OlFeature({
  //         id: x?.id,
  //         geom: wkt.readGeometry(x?.geom, {
  //           dataProjection: htrs96,
  //           featureProjection: wgs84PM
  //         })
  //       });
  //       if (ft) {
  //         ft.setProperties({ ...x, type: "commentinfo" });
  //         src.addFeature(ft);
  //       }
  //     })
  //   }

  //   const newCommentSource = new OlSourceVector({
  //     features: [
  //       ...src.getFeatures()
  //     ] as OlFeature<any>[],
  //   });

  //   setCommentSource(newCommentSource);
  //   console.log('Comments source: ');
  //   console.log(src);
  //   // console.log(src);
  //   // return src;

  //   //@ts-ignore
  //   // console.log(features as OlFeature<Geometry>[]);

  //   // TODO: Error handling if features has null as geometry
  //   //@ts-ignore
  //   // const commentsSource = new OlSourceVector({features: features as OlFeature<Geometry>[]})
  //   // return commentsSource
  //   // return null;
  // }, [commentRecords])

  // viewOptions = {
  //   center: defaultViewCenter,
  //   zoom: 8,
  //   //extent: defaultExtent,
  //   //projection: wgs84PM,
  //   minZoom: 8,
  //   maxZoom: 18
  // };
  const size: Size = useWindowSize();

  useEffect(() => {
    getLayers();

    if (userContext) {
      if (userContext.hasAnyPermission([P.ViewAdrese])) {
        getAdrese();
      }
      if (userContext.hasAnyPermission([P.ViewDKP])) {
        getDKP();
      }
      if (userContext.hasAnyPermission([P.ViewNC])) {
        getNC();
      }
      if (userContext.hasAnyPermission([P.Rasvjeta])) {
        getRasvjetnaMjesta();
      }
      if (userContext.hasAnyPermission([P.Parkiralista])) {
        getParkiralisnaMjesta();
      }
      if (userContext.hasAnyPermission([P.ManageKuceZaOdmor])) {
        getKuceZaOdmor();
      }
    }

    getComments();
  }, []);

  const getComments = () => {
    const dc = new dataController(modelComments);
    dc.GetData().then((resp) => {
      if (resp.success && resp.data) {
        if (Array.isArray(resp.data)) {
          const features = resp.data.map((x) => x.geom);
          setCommentRecords(resp.data as DCRecord[]);
        } else {
          setCommentRecords([]);
        }
      }
    });
  };

  const getPhotosForComment = (
    feature: RenderFeature | OlFeature<Geometry>,
    evt: OlMapBrowserEvent<any>
  ) => {
    let photos: any = [];

    const dc = new dataController(modelCommentPhotos);

    dc.GetDataSingle(
      Number(feature.getId()),
      "maps/1/comments/" + Number(feature.getId()) + "/photos"
    )
      .then((resp) => {
        if (resp.success && resp.data) {
          if (Array.isArray(resp.data) && resp.data.length > 0) {
            photos = resp.data.slice();
            showCardOverlay(
              "commentinfo",
              {
                position: evt.coordinate as CardPosition,
                feature: feature,
                data: photos,
                type: "commentinfo",
              },
              null
            );
          } else {
            showCardOverlay(
              "commentinfo",
              {
                position: evt.coordinate as CardPosition,
                feature: feature,
                type: "commentinfo",
              },
              null
            );
          }
        }
      })
      .catch(() => {
        showCardOverlay(
          "commentinfo",
          {
            position: evt.coordinate as CardPosition,
            feature: feature,
            type: "commentinfo",
          },
          null
        );
      });
  };

  const getLayers = () => {
    mapService.getLayers(MAP_ID).then((coll) => {
      const comments_layer = new OlVectorLayer({
        source: commentsSource,
        properties: {
          id: "spatial-comments",
          title: 'app-comments',
          ttoken: 'titles.spatial-comments',
          type: 'special',
          query: false,
          call_group: null,
          z_index: 900,
          style: commentsStyle
        }
      });

      coll.push(comments_layer);
      setLayersCollection(coll);
      setMapInitialized(true);
    });
  };

  const getAdrese = () => {
    dcAdrese.GetData().then((resp) => {
      if (resp.success) {
        setRecordsAdrese(resp.data as DCRecord[]);
      }
    });
  };

  const getDKP = () => {
    dcDKP.GetData().then((resp) => {
      if (resp.success) {
        setRecordsDKP(resp.data as DCRecord[]);
      }
    });
  };

  const getNC = () => {
    dcNC.GetData().then((resp) => {
      if (resp.success) {
        setRecordsNC(resp.data as DCRecord[]);
      }
    });
  };

  const getRasvjetnaMjesta = () => {
    if (userContext?.hasAnyPermission([P.Rasvjeta])) {
      dcRasvjetnaMjesta.GetData().then((resp) => {
        if (resp.success) {
          setRecordsRasvjetnaMjesta(resp.data as DCRecord[]);
        }
      });
    }
  };

  const getParkiralisnaMjesta = () => {
    if (userContext?.hasAnyPermission([P.Parkiralista])) {
      dcParkiralisnaMjesta.GetData().then((resp) => {
        if (resp.success) {
          setRecordsParkiralisnaMjesta(resp.data as DCRecord[]);
        }
      });
    }
  };

  const getKuceZaOdmor = () => {
    dcKuceZaOdmor.GetData().then((resp) => {
      if (resp.success) {
        setRecordsKuceZaOdmor(resp.data as DCRecord[]);
      }
    });
  };

  // getDefaultData() {
  //   mapService.getDefaultData().then((data) => {
  //     if (data) {
  //       const viewData = Array.isArray(data) ? Object.assign({}, data[0]) : Object.assign({}, data);
  //       setState((prevState) => {
  //         return {
  //           ...prevState,
  //           viewOptions: {
  //             ...prevState.viewOptions,
  //             zoom: viewData.initial_view_zoom,
  //             center: viewData.initial_view_center
  //           },
  //           zoomToExtent: viewData.default_extent,
  //           defaultExtent: viewData.default_extent
  //         };
  //       });
  //     }
  //   });
  // }

  const getFeatureType = (id: number) => {
    return "generic";
  };

  const setViewCenter = (view: { center: [number, number]; zoom: number }) => {
    setViewOptions((prevState) => {
      return {
        ...prevState,
        center: view.center,
        zoom: view.zoom,
      };
    });
  };

  const changeCoordinateSystemDisplay = (type: CoordinateSystemType) => {
    setChosenCoordinateSystem(type);
  };

  const generateCardOverlayData = (
    layer: GFIFeatureType[],
    featureType: CardOverlayType,
    pos: CardPosition
  ) => {
    return {
      position: pos,
      features: layer,
      type: featureType,
      record: undefined,
    } as IMultiCardOverlayData;
  };

  const handleClick = (evt: OlMapBrowserEvent<any>) => {
    let hit = false;

    if (userContext && userContext.expireTime) {
      checkToken(userContext.expireTime);
    }

    if (drawType) {
      return;
    }
    if (commentDrawType) {
      return;
    }

    const feature = evt.map.forEachFeatureAtPixel(
      evt.pixel,
      function (feature) {
        return feature;
      },
      {
        // layerFilter: (layer) => {
        //   const layerId = layer.get("id");
        //   return layerId !== null && layerId !== undefined && layerId.startsWith("objekti");
        // }
        hitTolerance: 10,
      }
    );

    if (feature && feature.get('data_type') === 'comment') {
      hit = true;
      closeOverlays(() => { });
      getPhotosForComment(feature, evt);
      setCurrentCommentFeature({ feature: feature, evt: evt });

      return;

    }

    if (!hit) {
      const item = localStorage.getItem("maplayers");
      const visibility = item
        ? (JSON.parse(item) as BoolSettings)
        : ({} as BoolSettings);
      const hiddenLayers = visibility
        ? Object.keys(visibility).filter((key) => visibility[key] === false)
        : [];
      const allLayers = layersCollection
        ? flattenLayers(layersCollection.getArray()).filter(
          (x) => !(x instanceof OlLayerGroup)
        )
        : [];
      const visibleLayers = allLayers.filter(
        (x) => hiddenLayers.indexOf(x.get("id")) === -1
      );
      const GSLayerNames = visibleLayers
        .filter((x) => x.get("query") === true)
        .map((x) => x.get("layer"));

      gsService
        .getFeatureInfo(evt.map, evt.pixel, GSLayerNames)
        .then((resp) => {
          closeOverlays(() => { });
          if (resp && Object.keys(resp).length != 0) {
            const layerKeys = Object.keys(resp);
            const odvozKey = layerKeys.find((x) => x.substring(4) === "Odvoz");
            const objektiKey = layerKeys.find(
              (x) => x.substring(4) === "objekti"
            );
            const kuceZaOdmorKey = layerKeys.find(
              (x) => x.substring(4) === "kuce_za_odmor"
            );
            const rasvjetaKey = layerKeys.find(
              (x) => x.substring(4) === "rasvjeta_mjesta"
            );
            const dkpKey = layerKeys.find((x) => x.substring(4) === "DKP");
            const kbKey = layerKeys.find((x) => x.substring(4) === "KB");
            const ncKey = layerKeys.find((x) => x.substring(4) === "NC");
            const dkp2Key = layerKeys.find(
              (x) => x.substring(4) === "Nova_izmjera_DKP"
            );
            const dkp2zgradeKey = layerKeys.find(
              (x) => x.substring(4) === "Nova_izmjera_Zgrade"
            );
            const lokacijeKey = layerKeys.find((x) => x.substring(4) === "LOK");
            const parkiralistaKey = layerKeys.find(
              (x) => x.substring(4) === "Parkiralisna_mjesta"
            );
            const dsmKey = layerKeys.find((x) => x === "dsm");
            const demKey = layerKeys.find((x) => x === "dem");

            const tempObject: any = {}
            if (rasvjetaKey) tempObject["rasvjeta"] = 0;
            if (dkpKey) tempObject["dkp"] = 0;
            if (kbKey) tempObject["kb"] = 0;
            if (ncKey) tempObject["nc"] = 0;
            if (dkp2Key) tempObject["dkp2"] = 0;
            if (dkp2zgradeKey) tempObject["dkp2zgrade"] = 0;
            if (lokacijeKey) tempObject["lok"] = 0;
            if (parkiralistaKey) tempObject["parking"] = 0;
            if (odvozKey) tempObject["Odvoz"] = 0;
            if (dsmKey) tempObject["dsm"] = 0;
            if (demKey) tempObject["dem"] = 0;
            setOverlayFeatureIndex(tempObject);

            const odvozFeatures = odvozKey ? resp[odvozKey] : [];
            const objektiFeatures = objektiKey ? resp[objektiKey] : [];
            const kuceZaOdmorFeautres = kuceZaOdmorKey
              ? resp[kuceZaOdmorKey]
              : [];
            const rasvjetaFeatures = rasvjetaKey ? resp[rasvjetaKey] : [];
            const dkpFeatures = dkpKey ? resp[dkpKey] : [];
            const kbFeatures = kbKey ? resp[kbKey] : [];
            const ncFeatures = ncKey ? resp[ncKey] : [];
            const dkp2Features = dkp2Key ? resp[dkp2Key] : [];
            const dkp2zgradeFeatures = dkp2zgradeKey ? resp[dkp2zgradeKey] : [];
            const lokacijeFeatures = lokacijeKey ? resp[lokacijeKey] : [];
            const parkiralistaFeatures = parkiralistaKey
              ? resp[parkiralistaKey]
              : [];
            const dsmFeatures = dsmKey ? resp[dsmKey] : [];
            const demFeatures = demKey ? resp[demKey] : [];

            const clickedPoint = {
              type: "Point",
              coordinates: evt.coordinate
            } as GFIGeometry;
            if(dsmKey) {
              dsmFeatures[0].geometry = clickedPoint;
            }
            if(demKey) {
              demFeatures[0].geometry = clickedPoint;
            }

            if (odvozKey && odvozFeatures.length > 0) {
              const data = generateCardOverlayData(
                resp[odvozKey],
                "odvoz",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("odvoz", data, null);
            }

            if (rasvjetaKey && rasvjetaFeatures.length > 0) {
              const data = generateCardOverlayData(
                resp[rasvjetaKey],
                "rasvjeta",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("rasvjeta", data, null);
            }

            if (parkiralistaKey && parkiralistaFeatures.length > 0) {
              const data = generateCardOverlayData(
                resp[parkiralistaKey],
                "parking",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("parking", data, null);
            }

            if (lokacijeKey && lokacijeFeatures.length > 0) {
              const data = generateCardOverlayData(
                resp[lokacijeKey],
                "lok",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("lok", data, null);
            }

            if (kbKey && kbFeatures.length > 0) {
              const data = generateCardOverlayData(
                resp[kbKey],
                "kb",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("kb", data, null);
            }

            if (kuceZaOdmorKey && kuceZaOdmorFeautres.length > 0) {
              const data = generateCardOverlayData(
                resp[kuceZaOdmorKey],
                "kuce_za_odmor",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("kuce_za_odmor", data, null);
            }
            
            if (dkp2zgradeKey && dkp2zgradeFeatures.length > 0) {
              const data = generateCardOverlayData(
                resp[dkp2zgradeKey],
                "dkp2zgrade",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("dkp2zgrade", data, null);
            }

            if (dkp2Key && dkp2Features.length > 0) {
              const data = generateCardOverlayData(
                resp[dkp2Key],
                "dkp2",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("dkp2", data, null);
            }
            
            if (ncKey && ncFeatures.length > 0) {
              const data = generateCardOverlayData(
                resp[ncKey],
                "nc",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("nc", data, null);
            }
            
            if (dkpKey && dkpFeatures.length > 0) {
              const data = generateCardOverlayData(
                resp[dkpKey],
                "dkp",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("dkp", data, null);
            }

            if(dsmKey && dsmFeatures.length > 0) {
              const data = generateCardOverlayData(
                resp[dsmKey],
                "dsm",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("dsm", data, null);
            }
            if(demKey && demFeatures.length > 0) {
              const data = generateCardOverlayData(
                resp[demKey],
                "dem",
                evt.coordinate as CardPosition
              );
              showMultiCardOverlay("dem", data, null);
            }

            addToTable(objektiFeatures);
            addToLayers(objektiFeatures);

            // addToTable(rasvjetaFeatures);
            // addToLayers(rasvjetaFeatures);
          } else {
            closeOverlays(undefined);
          }
        });
    }
  };

  const addToTable = (features: GFIFeatureType[]) => {
    const records = features.map((x) => {
      if (x.properties.objekt_id) {
        return {
          id: x.properties.objekt_id,
          obj_id: (x.properties.objekt_id as number) % 1000000,
          fishnet_id: x.properties.fishnet_id,
          projekt_id: x.properties.projekt_id,
          naselje: x.properties.naselje,
          ulica: x.properties.ulica,
          kb: x.properties.kb,
          adresa_full: x.properties.adresa_full,
          kn_zona: x.properties.kn_zona,
          pre_pt: x.properties.pre_pt,
          kn_koef: x.properties.kn_koef,
          lokacija_id: x.properties.lokacija_id,
          ko: x.properties.ko,
          kc: x.properties.kc,
          broj_katova: x.properties.broj_katova,
          cx: x.properties.cx,
          cy: x.properties.cy,
          cz: x.properties.cz,
        } as DCRecord;
      } else {
        return {
          id: x.properties.id,
          oznaka: x.properties.oznaka,
        } as DCRecord;
      }
    });

    setWorkingRecords((prevState) => {
      const currentIds = prevState.map((x) => x.id as number);
      const newRecords = records.filter(
        (x) => currentIds.find((r) => r === x.id) === undefined
      ) as DCRecord[];
      if (newRecords.length > 0) {
        return prevState.concat(newRecords);
      } else {
        return prevState;
      }
    });

    setSelectedRecords(records);
  };

  const addToLayers = (features: GFIFeatureType[]) => {
    const newOlFeatures = features.map((x) => {
      const newFeat = new OlFeature();
      const propId = x.properties.id || x.id;
      const id = typeof propId === "string" ? parseInt(propId, 10) : propId;
      newFeat.setId(id);
      Object.keys(x.properties).forEach((key) => {
        newFeat.set(key, x.properties[key]);
      });
      newFeat.setGeometryName(x.geometry_name);
      switch (x.geometry.type) {
        case "Polygon":
          newFeat.setGeometry(
            new OlPolygon(x.geometry.coordinates as number[])
          );
          break;
        case "MultiPolygon":
          newFeat.setGeometry(
            new OlMultiPolygon(x.geometry.coordinates as number[])
          );
          break;
        case "Point":
          newFeat.setGeometry(new OlPoint(x.geometry.coordinates as number[]));
          break;
        default:
          break;
      }
      return newFeat;
    });

    setWorkingSource((prevState) => {
      const currentFeatures = prevState.getFeatures();
      if (currentFeatures.length > 0) {
        return new OlSourceVector({
          features: [
            ...currentFeatures,
            ...newOlFeatures.filter(
              (x) => x.getId() && prevState.getFeatureById(x.getId() as number) === null
            ),
          ] as OlFeature<any>[],
        });
      } else {
        return new OlSourceVector({
          features: newOlFeatures,
        });
      }
    });

    setSelectedSource(new OlSourceVector({ features: newOlFeatures }));
  };

  const addOverlayedFatureToLayers = (features: GFIFeatureType[]) => {
    const newOlFeatures = features.map((x) => {
      const newFeat = new OlFeature();
      const propId = x.properties.id;
      const id = typeof propId === "string" ? parseInt(propId, 10) : propId;
      newFeat.setId(id);
      Object.keys(x.properties).forEach((key) => {
        newFeat.set(key, x.properties[key]);
        newFeat.setGeometryName(x.geometry_name);
        if (x.geometry.type === "Polygon") {
          newFeat.setGeometry(new OlPolygon(x.geometry.coordinates as number[]));
        } else if (x.geometry.type === "Point") {
          newFeat.setGeometry(new OlPoint(x.geometry.coordinates as number[]));
        } else if (x.geometry.type === "MultiPolygon") {
          //newFeat.setGeometry(new OlPolygon(x.geometry.coordinates[0] as number[]));
          newFeat.setGeometry(new MultiPolygon(x.geometry.coordinates as number[]))
        }

      });
      return newFeat;
    });

    setOverlaySource((prevState) => {
      return new OlSourceVector({
        features: newOlFeatures
      });
    });

    setSelectedSource(new OlSourceVector({ features: newOlFeatures }));
  };

  const handleSelectRecord = (id: number) => {
    const ft = workingSource.getFeatures().find((x) => x.getId() === id);
    if (ft) {
      setSelectedSource(
        new OlSourceVector({
          features: [ft],
        })
      );

      const extent = ft.getGeometry()?.getExtent();
      if (extent) {
        const [minx, miny, maxx, maxy] = extent;
        const center: [number, number] = [(minx + maxx) / 2, (miny + maxy) / 2];

        setViewOptions((prevState) => {
          return {
            ...prevState,
            center: center,
            animateDuration: 500,
          };
        });
      }
    }
  };

  const handleRemoveRecord = (id: number) => {
    setWorkingRecords((prevState) => {
      return prevState.filter((x) => x.id !== id);
    });
    setWorkingSource((prevState) => {
      return new OlSourceVector({
        features: [...prevState.getFeatures().filter((x) => x.getId() !== id)],
      });
    });
    setSelectedSource((prevState) => {
      if (prevState.getFeatureById(id) !== null) {
        return new OlSourceVector({});
      } else {
        return prevState;
      }
    });
  };

  const handleRemoveAll = () => {
    setWorkingRecords([]);
    setWorkingSource(new OlSourceVector({}));
    setSelectedSource(new OlSourceVector({}));
    setOverlaySource(new OlSourceVector({}));
  };

  const handleCustomAttributeTableAction = (
    action: string,
    id: number,
    record?: { [key: string]: any }
  ) => {
    let locationId: number | null = null;
    if (record && "lokacija_id" in record) {
      locationId = record["lokacija_id"] as number;
    }
    switch (action) {
      case "show_object":
        if (id) {
          const locId = locationId === null ? "" : `&loc_id=${locationId}`;
          const url =
            location.origin +
            "/komunalne-obveze/?obj_id=" +
            id.toString() +
            "&open_first=true" +
            locId;
          window.open(url, "_blank");
        }
        break;
      case "show_3d":
        if (id) {
          const ft = workingSource.getFeatures().find((x) => x.getId() === id);
          const extent = ft?.getGeometry()?.getExtent() as OlExtent;
          const [cx, cy] = transform(
            [record?.cx, record?.cy],
            "EPSG:3765",
            "EPSG:4326"
          ).map((x) => (x * Math.PI) / 180);

          //navigate("/3d", { state: { cx: cx, cy: cy, cz: record?.cz}}) //(opens 3d in the same tab)
          const url =
            location.origin +
            "/3d/?cx=" +
            cx.toString() +
            "&cy=" +
            cy.toString() +
            "&cz=" +
            record?.cz.toString();
          window.open(url, "_blank"); // opens 3d in a new tab
        }
        break;
      case "show_photos":
        if (id) {
          const url =
            location.origin +
            "/fotodokumentacija-objekata/?rec_id=" +
            id.toString();
          window.open(url, "_blank");
        }
        break;
    }
  };

  const showCardOverlay = ( // za komentare
    type: CardOverlayType,
    data: ICardOverlayData,
    ft: OlFeature<any> | null
  ) => {
    //TODO: refactor
    feature ? feature.setProperties({ selected: false }) : null;

    if (ft) {
      ft.setProperties({ selected: true });
    }
    setCommentOverlay({
      type: type,
      data: data,
    });
    if (ft) {
      setFeature(ft);
    } else {
      setFeature(undefined);
    }
  };


  const showMultiCardOverlay = (type: CardOverlayType, data: IMultiCardOverlayData, ft: OlFeature<any> | null) => {
    //TODO: refactor
    feature ? feature.setProperties({ selected: false }) : null;

    if (ft) {
      ft.setProperties({ selected: true });
    }

    // if (type === "commentinfo") {
    //   setCardOverlay({
    //     type: type,
    //     data: data,
    //   });

    setMultiCardOverlay(prev => prev === undefined ? [{ type: type, data: data }] : [...prev, { type: type, data: data }]);

    if (ft) {
      setFeature(ft);
    } else {
      setFeature(undefined);
    }
  };

  useEffect(() => {
    if (!multiCardOverlay) return;

    const activeLayer = multiCardOverlay[overlayTabActive];
    if (!activeLayer) return;

    let featureIndex = overlayFeatureIndex[activeLayer.type]

    if (!featureIndex) featureIndex = 0;

    const featureData = activeLayer.data.features[featureIndex];

    //@ts-ignore
    const coordinates: Coordinate[] = featureData.geometry.coordinates;
    const polygon = new Polygon([coordinates]);
    const newFeature = new OlFeature<Geometry>({ geometry: polygon });

    if (newFeature) setFeature(newFeature);

    addOverlayedFatureToLayers([featureData])

    // const map = mapContext?.map;

    // if(map){
    //   let overlayLayer = map.getLayers().getArray().find(layer => layer.getProperties()['id']=="popup_overlay");
    //   if(!overlayLayer){
    //     const layerSource = new OlSourceVector();
    //     layerSource.addFeature(newFeature)
    //     overlayLayer = new OlBaseLayer({
    //       visible: true,
    //     });
    //   }
    // }

  }, [multiCardOverlay, overlayFeatureIndex, overlayTabActive])

  const closeOverlays = (callback: any) => {
    setMultiCardOverlay(undefined);
    setCommentOverlay(undefined);
    setExpandedPosjednici(false);
    setExpandedVlasnici(false);
    setExpandedKuceZaOdmor(false);
    setOverlaySource(new OlSourceVector({}));
    if (callback !== undefined && typeof callback === "function") {
      callback();
    }
  };

  const handleViewChangeCenter = (evt: MapEvent) => {
    if (evt && evt.map) {
      // const res = evt.map.getView().getResolution();
      // console.log(res);
      const newView = {
        center: evt.map.getView().getCenter(),
        zoom: evt.map.getView().getZoom(),
      };
      setViewOptions(Object.assign(viewOptions, newView));
    }
  };

  const handleSidebarPaneChange = useCallback(
    (id) => {
      if(drawType && id && id !== 'measures') {
        setDrawType(undefined);
      } else if(drawType && id) {
        console.log(size);
        if(size && size.width && size.width > 768) {
          setDrawType(undefined);
        }
      }
    },
    [drawType]
  );

  const changeDrawType = (type: DrawingType) => {
    if (drawType !== type) {
      setDrawType(type);
      setMeasuringFeature(undefined);
    } else {
      setDrawType(undefined);
      setMeasuringFeature(undefined);
    }
  };

  const handleDrawMeasureStart = (evt: DrawEvent) => {
    if (drawingSource.getFeatures().length > 0) {
      setDrawingSource(new OlSourceVector({}));
    }

    setMeasuringFeature(evt.feature);
  };

  const handleDrawMeasureEnd = (evt: DrawEvent) => {
    setDrawingSource(new OlSourceVector({}));
    setMeasurementsSource(
      (prevState) =>
        new OlSourceVector({
          features: [...prevState.getFeatures(), evt.feature],
        })
    );

    setForceRefreshCounter((prevState) => (prevState ? prevState + 1 : 1));
  };

  const handleDrawMeasureChange = (evt: BaseEvent) => {
    // console.log("handleDrawChange", evt);
  };

  const handleEraseMeasurements = () => {
    setMeasurementsSource(new OlSourceVector({}));
  };

  const handleLayerVisibilityChange = () => {
    // setForceRefreshCounter((prevState) => prevState + 1);
  };

  // COMMENTING TOOL
  const handleDrawCommentsStart = (evt: DrawEvent) => {
    if (drawingSource.getFeatures().length > 0) {
      setDrawingSource(new OlSourceVector({}));
    }

    setCommentingFeature(evt.feature);
  };

  const handleDrawCommentsEnd = (evt: DrawEvent) => {
    setDrawingSource(new OlSourceVector({}));
    const newFeature = evt.feature;

    dialogContext.showDialog(CommentDialog, {
      mode: "insert",
      feature: newFeature,
      refreshLayers: getLayers,
      onClose: () => {
        setCommentDrawType(undefined);
        setCommentingFeature(undefined);
        getComments();
      },
    });

    setForceRefreshCounter((prevState) => (prevState ? prevState + 1 : 1));
  };

  const handleDrawCommentsChange = (evt: BaseEvent) => {
    // console.log("handleDrawChange", evt);
  };

  const handleDeleteComment = () => {
    getComments();
  };

  const changeCommentDrawType = useCallback(
    (type: DrawingType) => {
      if (commentDrawType !== type) {
        setCommentDrawType(type);
        setCommentingFeature(undefined);
      } else {
        setCommentDrawType(undefined);
        setCommentingFeature(undefined);
      }
    },
    [commentDrawType]
  );

  function checkToken(expireTime: Moment) {
    const isExpired = moment().isAfter(expireTime);
    if (isExpired) {
      authService.logout();
      snackbarContext.showNotification("messages.token_expired", "warning");
      navigate("/login");
    }
  }

  const handlePopoverOverlayClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  // const gsOverlay = cardOverlay && cardOverlay.type === "gsinfo" ? cardOverlay : undefined;
  const gsOverlay = commentOverlay ? commentOverlay : undefined;

  const fullTableHeight = 50 + 50 + 48 + 32 + workingRecords.length * 43;
  const constrainedTableHeight = fullTableHeight > 400 ? 400 : fullTableHeight;

  const attributeTableViewName = userContext?.hasAnyPermission([P.ThreeD])
    ? "attribute_table_with_3d"
    : "attribute_table";

  return (
    <Stack spacing={0} sx={{ display: "flex", height: "100%" }}>
      {userContext && layersCollection ? (
        <Box m={0} p={0} sx={{ flexGrow: 1 }}>
          <Map
            height={constrainedTableHeight}
            view={viewOptions}
            onClick={handleClick}
            onMoveend={handleViewChangeCenter}
            className="sidebar-map"
            id="main-map"
            zoomToExtentPadding={[50, 50, 50, 50]}
            initialized={mapInitialized}
            moveTolerance={10}
            maxTilesLoading={10}
          >
            <Controls>
              <CoordZoomStatusControl
                chosenCoordinateSystem={chosenCoordinateSystem}
              />
              <StatusControl
                changeCoordinateSystem={changeCoordinateSystemDisplay}
              />
              <ZoomControl
                zoomInTipLabel={t("map:controls.zoom_in")}
                zoomOutTipLabel={t("map:controls.zoom_out")}
              />
              <ZoomSliderControl />
              {/*<ScaleControl className="ol-control ol-scale-ratio ol-sidebar-sticky" ppi={96} />*/}
              <ScaleLineControl />
              {/* <ScaleRatioControl viewOptions={viewOptions} ppi={96} /> */}
              <FullScreenControl tipLabel={t("map:controls.full_screen")} />
              <RotateControl
                autoHide={false}
                tipLabel={t("map:controls.rotate")}
              />
              {fullScreenElementRef &&
                fullScreenElementRef.current &&
                mapInitialized ? (
                <FullScreenControl
                  tipLabel={t("map:controls.full_screen")}
                  target={fullScreenElementRef.current}
                  className="ol-sidebar-control"
                />
              ) : null}

              {zoomToDefaultExtentElementRef?.current && mapInitialized ? (
                <ZoomToExtentControl
                  id="zoom-extent-default"
                  target={zoomToDefaultExtentElementRef.current}
                  extent={defaultExtent}
                  tipLabel={t("map:controls.zoom_to_extent")}
                  className="ol-sidebar-control"
                />
              ) : null}
              {zoomToSelectedExtentElementRef?.current && mapInitialized ? (
                <ZoomToExtentControl
                  id="zoom-extent-selected"
                  target={zoomToSelectedExtentElementRef.current}
                  extent={
                    workingSource.getFeatures().length > 0
                      ? workingSource.getExtent()
                      : defaultExtent
                  }
                  tipLabel={t("map:controls.zoom_to_selected")}
                  className="ol-sidebar-control"
                  labelClass="fas fa-bullseye"
                />
              ) : null}
              {/* {geoLocateElementRef?.current && mapInitialized? (
              <GeoLocateControl
              id="geo-locate"
              tooltip={t("map:controls.geolocate")}
              target={geoLocateElementRef.current}
              className="ol-sidebar-control"
              />
              ) : null} */}
              {centerMarkerElementRef?.current && mapInitialized ? (
                <CenterMarkerControl
                  id="center-marker"
                  tooltip={t("map:controls.center_marker")}
                  target={centerMarkerElementRef.current}
                  className="ol-sidebar-control"
                />
              ) : null}
              <ViewHistoryControl />
              <GeoBaseLayerSwitcher allowNoLayer={true} mapId={MAP_ID} />
              <SidebarControl onTabChange={handleSidebarPaneChange}>
                <SidebarTabs>
                  <SidebarTabList>
                    <SidebarTabListItem
                      id="info"
                      title={t("map:sidebar.info")}
                      icon={<i className="fas fa-info-circle"></i>}
                    />
                    <SidebarTabListItem
                      id="layers"
                      title={t("map:sidebar.layers")}
                      icon={<i className="fas fa-layer-group"></i>}
                    />
                    <SidebarTabListItem
                      id="legend"
                      title={t("map:sidebar.legend")}
                      icon={<i className="fas fa-list-alt"></i>}
                      needPermission={[P.MapLegend]}
                    />
                    <SidebarTabListItem
                      id="adrese"
                      title={t("map:sidebar.adrese")}
                      icon={<i className="fas fa-address-book"></i>}
                      needPermission={[P.ViewAdrese]}
                    />
                    <SidebarTabListItem
                      id="dkp"
                      title={t("map:sidebar.dkp")}
                      icon={<i className="fas fa-globe"></i>}
                      needPermission={[P.ViewDKP]}
                    />
                    <SidebarTabListItem
                      id="nc"
                      title={t("map:sidebar.nc")}
                      icon={<i className="fas fa-bacon"></i>}
                      needPermission={[P.ViewNC]}
                    />
                    <SidebarTabListItem
                      id="rasvjeta"
                      title={t("map:sidebar.rasvjeta")}
                      icon={<i className="fas fa-lightbulb"></i>}
                      needPermission={[P.Rasvjeta]}
                    />
                    <SidebarTabListItem
                      id="measures"
                      title={t("map:sidebar.measures")}
                      icon={<i className="fas fa-pencil-ruler"></i>}
                    />
                    <SidebarTabListItem
                      id="comments"
                      title={t("map:sidebar.comments")}
                      icon={<i className="fas fa-comment"></i>}
                    />
                    <li ref={zoomToDefaultExtentElementRef}></li>
                    <li ref={zoomToSelectedExtentElementRef}></li>
                    <li ref={fullScreenElementRef}></li>
                    {/* <li ref={geoLocateElementRef}></li> */}
                    <li ref={centerMarkerElementRef}></li>
                  </SidebarTabList>
                </SidebarTabs>
                <SidebarContent>
                  <SidebarPane id="info">
                    <SidebarHeading title={t("map:sidebar.info")} />
                    <InfoPane />
                  </SidebarPane>
                  <SidebarPane id="layers">
                    <SidebarHeading title={t("map:sidebar.layers")} />
                    <LayerTree
                      ready={layersCollection ? true : false}
                      layersCollection={layersCollection}
                      onLayerVisibilityChange={handleLayerVisibilityChange}
                    />
                    {/* <LayersPane layersGroup={true} /> */}
                  </SidebarPane>
                  <SidebarPane id="legend" needPermission={[P.MapLegend]}>
                    <SidebarHeading title={t("map:sidebar.legend")} />
                    <LegendPane
                      // toggleDraw={handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                      layersCollection={layersCollection}
                    />
                  </SidebarPane>
                  <SidebarPane id="adrese" needPermission={[P.ViewAdrese]}>
                    <SidebarHeading title={t("titles.adrese")} />
                    <DataPane
                      dc={dcAdrese}
                      tableName="adrese"
                      viewName="sidebar"
                      records={recordsAdrese}
                      closeOverlays={closeOverlays}
                      setSelectedSource={setHighlightedSource}
                      setStyle={setHighlightedVectorStyle}
                    />
                  </SidebarPane>
                  <SidebarPane id="dkp" needPermission={[P.ViewDKP]}>
                    <SidebarHeading title={t("titles.dkp")} />
                    <DataPane
                      dc={dcDKP}
                      tableName="dkp_short"
                      viewName="sidebar"
                      records={recordsDKP}
                      closeOverlays={closeOverlays}
                      setSelectedSource={setHighlightedSource}
                      setStyle={setHighlightedVectorStyle}
                    />
                  </SidebarPane>
                  <SidebarPane id="nc" needPermission={[P.ViewNC]}>
                    <SidebarHeading title={t("titles.nerazvrstane_ceste")} />
                    <DataPane
                      dc={dcNC}
                      tableName="nc"
                      viewName="sidebar"
                      records={recordsNC}
                      closeOverlays={closeOverlays}
                      setSelectedSource={setHighlightedSource}
                      setStyle={setHighlightedVectorStyle}
                    />
                  </SidebarPane>
                  <SidebarPane id="rasvjeta" needPermission={[P.Rasvjeta]}>
                    <SidebarHeading title={t("titles.rasvjetna_mjesta")} />
                    <DataPane
                      dc={dcRasvjetnaMjesta}
                      tableName="rasvjetna_mjesta"
                      viewName="sidebar"
                      records={recordsRasvjetnaMjesta}
                      closeOverlays={closeOverlays}
                      setSelectedSource={setHighlightedSource}
                      setStyle={setHighlightedVectorStyle}
                    />
                  </SidebarPane>
                  <SidebarPane id="measures">
                    <SidebarHeading title={t("map:sidebar.measures")} onCollapse={() => { handleSidebarPaneChange('measures'); }} />
                    <MeasuresPane
                      // toggleDraw={handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                      changeDrawType={changeDrawType}
                      handleEraseMeasurements={handleEraseMeasurements}
                      drawType={drawType}
                    />
                  </SidebarPane>
                  <SidebarPane id="comments">
                    <SidebarHeading title={t("map:sidebar.comments")} />
                    <CommentsPane
                      // toggleDraw={handleToggleDrawMeasure} TODO: implement method handleToggleDrawMeasure in this component
                      changeDrawType={changeCommentDrawType} // changeCommentDrawType
                      drawType={commentDrawType} // commentDrawType
                      records={commentRecords} //commentRecords
                      setSelectedSource={setHighlightedSource}
                      setStyle={setHighlightedVectorStyle}
                    />
                  </SidebarPane>
                </SidebarContent>
              </SidebarControl>
              {/* <AttributeTableControl
                dc={dc}
                records={workingRecords}
                selectedRecords={selectedRecords}
                onSelectRecord={handleSelectRecord}
                onRemoveRecord={handleRemoveRecord}
                onCustomAction={handleCustomAttributeTableAction}
                onClose={handleRemoveAll}
                autohide={true}
              /> */}
            </Controls>
            {layersCollection ? (
              <Layers>
                <GeoAPILayers layersCollection={layersCollection} />
                <VectorLayer
                  id="selected"
                  source={workingSource}
                  style={selectedRecordStyle}
                  zIndex={950}
                />
                <VectorLayer
                  id="highlighted"
                  source={selectedSource}
                  style={selectedRecordStyle}
                  zIndex={960}
                />
                <VectorLayer
                  id="measurements"
                  source={measurementsSource}
                  style={measurementsStyle}
                  zIndex={900}
                // title="Temp measurements"
                />
                <VectorLayer
                  id="spatial-comments"
                  source={commentsSource}
                  style={commentsStyle}
                  zIndex={900}
                />
                <VectorLayer
                  id="highlightedInTable"
                  source={highlightedSource}
                  style={highlightedStyle}
                  zIndex={900}
                />
              </Layers>
            ) : null}

            <Overlays>
              <PopupOverlay
                id="feature-overlay"
                position={multiCardOverlay && multiCardOverlay[overlayTabActive] ? multiCardOverlay[overlayTabActive].data.position : commentOverlay ? commentOverlay.data.position : undefined}
                autoPan={true}
                onClose={() => { }}
              >

                {multiCardOverlay && multiCardOverlay.length > 1 ?
                  <Popover
                    id={'simple-popover'}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                  >
                    <Tabs

                      orientation="vertical"
                      variant="scrollable"

                      value={overlayTabActive}
                      onChange={(event: React.SyntheticEvent, newValue: number) => { setOverlayTabActive(newValue); setAnchorEl(null); }}
                    >
                      {multiCardOverlay.map((value, index) => <Tab label={t("map:layer." + value.type)} id={value.type} />)}
                    </Tabs>
                  </Popover>
                  : null
                }

                {multiCardOverlay && multiCardOverlay.length > overlayTabActive ?
                  <GSInfoCard
                    featureData={multiCardOverlay[overlayTabActive].data}//gsOverlay.data} 
                    layerPickerDisabled={multiCardOverlay.length <= 1}
                    onClose={closeOverlays}
                    expandedVlasnici={expandedVlasnici}
                    setExpandedVlasnici={setExpandedVlasnici}
                    expandedPosjednici={expandedPosjednici}
                    setExpandedPosjednici={setExpandedPosjednici}
                    expandedKuceZaOdmor={expandedKuceZaOdmor}
                    setExpandedKuceZaOdmor={setExpandedKuceZaOdmor}
                    setIndex={setOverlayFeatureIndex}
                    currentIndex={overlayFeatureIndex}
                    handlePopoverOverlayClick={handlePopoverOverlayClick}
                  />
                  : null}
                {commentOverlay && commentOverlay.type === "commentinfo" ?
                  <CommentInfoCard
                    featureData={commentOverlay.data}
                    onClose={closeOverlays}
                    onDelete={handleDeleteComment}
                    refreshLayers={getComments}
                    refreshPhotos={getPhotosForComment}
                    photosInfo={currentCommentFeature}
                  />
                 : null}
              </PopupOverlay>
              <MeasureTooltipOverlay
                id="measure-overlay"
                feature={measuringFeature}
                position={undefined}
              />
            </Overlays>
            <Interactions>
              <DefaultInteractions />
              {drawType ? (
                <DrawInteraction
                  source={drawingSource}
                  type={drawType}
                  style={measuringStyle}
                  onChange={handleDrawMeasureChange}
                  onDrawstart={handleDrawMeasureStart}
                  onDrawend={handleDrawMeasureEnd}
                />
              ) : null}
              {commentDrawType ? (
                <DrawInteraction
                  source={drawingSource}
                  type={commentDrawType}
                  style={commentingStyle}
                  onChange={handleDrawCommentsChange}
                  onDrawstart={handleDrawCommentsStart}
                  onDrawend={handleDrawCommentsEnd}
                />
              ) : null}
            </Interactions>
          </Map>
        </Box>
      ) : null}
      {workingRecords.length > 0 ? (
        <Box m={0} p={0}>
          <AttributeTable
            dcDictionary={{
              objekti: dc,
              rasvjetna_mjesta: dcRasvjetnaMjesta,
            }}
            records={workingRecords}
            selectedRecords={selectedRecords}
            onSelectRecord={handleSelectRecord}
            onRemoveRecord={handleRemoveRecord}
            onCustomAction={handleCustomAttributeTableAction}
            onClose={handleRemoveAll}
            autohide={true}
            viewName={attributeTableViewName}
          />
        </Box>
      ) : null}
    </Stack>
  );
}

export default MainMap;
