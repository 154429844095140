import { IModel } from "@/@types/models/model";

import { commonFields } from "./helpers/commonFields"; 
import { buttonFields } from "./helpers/buttonFields";
import { coreFields } from "./helpers/coreFields";

const KomunalniObveznici: IModel = {
  title: "Komunalni obveznici",
  source: "komunalni_obveznici",
  apiPath: "komunalni-obveznici",
  forms: {
    default: {
      fields: ["obv", "adrobv", "sifobv", "nasobv", "adrobj", "nasobj", "ulobj", "kbobj", "lokacija_id", "lokacija_id_short", "zap"],
    }
  },
  listViews: {
    default: {
      fields: [
        "id",
        "obv",
        "oib",
        "sifobv",
        "vrsta_prostora",
        "p_a",
        "p_b",
        "adrobv",
        "adrobj",
        "namjena",
        "pov_a",
        "pov_b",
        "zona",   
        "lokacija_id",
        "lokacija_id_short",
        "zapisnik_id",
        "status_obv",
        "active",
        "odstupanje",
        "zapisnik_dl",
        "btn_show_photos"
        // "download"
      ],
      hidden: ["id", "oib","adrobv", "lokacija_id"]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      type: "numeric",
      idattr: true,
      readonly: true,
      search: false
    },
    {
      title: "Obveznik",
      source: "obv",
      ttoken: "komunalni_obveznici.obv",
      type: "text",
      search: true
    },
    {
      title: "OIB",
      source: "oib",
      ttoken: "common.oib",
      type: "text",
      search: true
    },
    {
      title: "Status",
      source: "status_obv",
      ttoken: "komunalni_obveznici.status",
      type: "text",
      readonly: true
    },
    {
      title: "adrobv",
      source: "adrobv",
      ttoken: "komunalni_obveznici.adrobv",
      type: "text",
      search: true
    },
    {
      title: "sifobv",
      source: "sifobv",
      ttoken: "komunalni_obveznici.sifobv",
      type: "text",
      search: true
    },
    {
      title: "nasobv",
      source: "nasobv",
      ttoken: "komunalni_obveznici.nasobv",
      type: "text",
      search: false
    },
    {
      title: "sifobj",
      source: "sifobj",
      ttoken: "komunalni_obveznici.sifobj",
      type: "text",
      search: false
    },
    {
      title: "adrobj",
      source: "adrobj",
      ttoken: "komunalni_obveznici.adrobj",
      type: "text",
      search: true
    },
    {
      title: "nasobj",
      source: "nasobj",
      ttoken: "komunalni_obveznici.nasobj",
      type: "text",
      search: false
    },
    {
      title: "ulobj",
      source: "ulobj",
      ttoken: "komunalni_obveznici.ulobj",
      type: "text",
      search: false
    },
    {
      title: "kbobj",
      source: "kbobj",
      ttoken: "komunalni_obveznici.kbobj",
      type: "text",
      search: false
    },
    {
      title: "Lokacija ID",
      source: "lokacija_id",
      ttoken: "komunalni_obveznici.lok",
      type: "text",
      search: true
    },
    {
      title: "Lokacija ID short",
      source: "lokacija_id_short",
      ttoken: "komunalni_obveznici.lok",
      type: "text",
      search: true
    },
    {
      title: "Glavni Objekt ID",
      source: "glavniobj_id",
      ttoken: "komunalni_obveznici.lok",
      type: "text",
      search: false
    },
    {
      title: "zap",
      source: "zap",
      ttoken: "komunalni_obveznici.zap",
      type: "text",
      search: false
    },
    {
      title: "Broj zapisnika",
      source: "zapisnik_id",
      ttoken: "komunalni_obveznici.zapisnik_id",
      type: "text",
      search: true
    },
    {
      title: "zap",
      source: "zapisnik_dl",
      ttoken: "komunalni_obveznici.zapisnik_dl",
      type: "dokumenti",
      search: false
    },
    {
      title: "",
      source: "btn_show_photos",
      tooltip: "buttons.show_photos",
      ttoken: "",
      type: "button",
      icon: "photo_camera",
      action: "show_photos",
      padding: "none",
      sort: false,
      search: false
    },
    {
      title: "namjena",
      source: "namjena",
      ttoken: "komunalni_obveznici.namjena_poslije",
      type: "text",
      search: false
    },
    {
      title: "Povrsina A",
      source: "pov_a",
      ttoken: "komunalni_obveznici.pov_a",
      type: "numeric",
      search: false
    },
    {
      title: "Povrsina B",
      source: "pov_b",
      ttoken: "komunalni_obveznici.pov_b",
      type: "numeric",
      search: false
    },
    {
      title: "Zona",
      source: "zona",
      ttoken: "komunalni_obveznici.zona",
      type: "text",
      search: false
    },
    {
      title: "Katastarska opcina",
      source: "ko",
      ttoken: "komunalni_obveznici.ko",
      type: "text",
      search: true
    },
    {
      title: "Katastarska cestica",
      source: "kc",
      ttoken: "komunalni_obveznici.kc",
      type: "text",
      search: true
    },
    {
      title: "Vrsta prostora",
      source: "vrsta_prostora",
      ttoken: "komunalni_obveznici.namjena_prije",
      type: "text",
      search: false
    },
    {
      title: "Povrsina za naplatu",
      source: "p_a",
      ttoken: "komunalni_obveznici.p_a",
      type: "numeric",
      search: false
    },
    {
      title: "Povrsina za naplatu",
      source: "p_b",
      ttoken: "komunalni_obveznici.p_b",
      type: "numeric",
      search: false
    },
    {
      title: "Odstupanje",
      source: "odstupanje",
      ttoken: "komunalni_obveznici.odstupanje",
      type: "picker",
      items: {
        labels: ["komunalni_obveznici.blizu_originala", "komunalni_obveznici.povecanje", "komunalni_obveznici.smanjenje", ""],
        values: ["0", "1", "-1", "2"],
        icons: ["horizontal_rule", "keyboard_double_arrow_up", "keyboard_double_arrow_down", ""],
        iconColors: ["#2edaff", "#00b000", "#ff0000", "rgba(256,256,256,0)"]
      },
      filter: true
    },
    buttonFields.download,
    commonFields.wkt,
    coreFields.active
  ]
};

export default KomunalniObveznici;
