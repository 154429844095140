import { IModel } from "@/@types/models/model";

const DSM: IModel = {
  title: "DSM",
  apiPath: "dsm",
  forms : {
    "default": {
      fields: ["DSM"]
    }
  },
  listViews: {
    "default": {
      fields: ["DSM"]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      type: "numeric",
      idattr: true,
      readonly: true
    },
    {
      title: "GRAY_INDEX",
      source: "DSM",
      ttoken: "common.visina",
      type: "numeric",
      format: ".2m",
      readonly: true
    }
  ]
}

export default DSM;
