export const Permissions = {
  BasicAcces: {
    id: "basicAccess",
    code: 100,
    display: "permissions.basicAccess",
    shortDisplay: "BAC",
  },
  MapLegend: {
    id: "mapLegend",
    code: 101,
    display: "permissions.mapLegend",
    shortDisplay: "LEG",
  },
  ThreeD: {
    id: "threed",
    code: 150,
    display: "permissions.3d",
    shortDisplay: "3D",
  },
  ManageKN: {
    id: "manage-komunalni-obv",
    code: 300,
    display: "permissions.komunalni_obv",
    shortDisplay: "MKN",
  },
  ViewAdrese: {
    id: "view-adrese",
    code: 210,
    display: "permissions.view",
    shortDisplay: "VAD",
  },
  ViewDKP: {
    id: "view-dkp",
    code: 220,
    display: "permissions.view",
    shortDisplay: "VKC",
  },
  ViewNC: {
    id: "view-nc",
    code: 230,
    display: "permissions.view",
    shortDisplay: "VNC",
  },
  ViewDKP2: {
    id: "view-dkp2",
    code: 240,
    display: "permissions.view",
    shortDisplay: "VK2",
  },
  ManageKuceZaOdmor: {
    id: "manage-kuce-za-odmor",
    code: 310,
    display: "permissions.kuce_za_odmor",
    shortDisplay: "MKZO",
  },
  Rasvjeta: {
    id: "rasvjeta",
    code: 500,
    display: "permissions.rasvjeta",
    shortDisplay: "RAS",
  },
  Parkiralista: {
    id: "parkiralista",
    code: 600,
    display: "permissions.parkiralista",
    shortDisplay: "PAR",
  },
  Administration: {
    id: "administration",
    code: 900,
    display: "permissions.administration",
    shortDisplay: "ADM",
  },
};
