import React, { useContext } from "react";
import { useNavigate } from "react-router";

//Custom Components
import PhotoCentricPage from "@/views/PhotoCentric/PhotoCentricPage";
import modelObjekti from "@/models/objekti";
import { Permissions as P } from "@/lib/permissions";
import UserContext from "@/components/UserContext/UserContext";

import { transform } from "ol/proj";

type ObjektiPhotoCentricPageParams = {}

function ObjektiPhotoCentricPage(props: ObjektiPhotoCentricPageParams) {
  const userContext = useContext(UserContext);

  const navigate = useNavigate();

  const handleCustomAction = (action: string, id: number, record?: { [key: string]: any }) => {
    let locationId: number | null = null;
    if (record && 'lokacija_id' in record) {
      locationId = record['lokacija_id'] as number;
    }
    switch (action) {
      case "show_object":
        if (id) {
          const locId = locationId === null ? '' : `&loc_id=${locationId}`;
          const url = location.origin + "/komunalne-obveze/?obj_id=" + id.toString() + "&open_first=true" + locId;
          window.open(url, "_blank");
        }
        break;
      case "show_3d":
        if (id) {
          const [cx, cy] = transform([record?.cx, record?.cy], "EPSG:3765", "EPSG:4326").map(x => x * Math.PI / 180)

          //navigate("/3d", { state: { cx: cx, cy: cy, cz: record?.cz}}) //(opens in the same tab)
          const url = location.origin + "/3d/?cx=" + cx.toString() + "&cy=" + cy.toString() + "&cz=" + record?.cz.toString();
          window.open(url, "_blank"); // opens in a new tab
        }
        break;
      case "show_photos":
        if (id) {
          const url = location.origin + "/fotodokumentacija-objekata/?rec_id=" + id.toString();
          window.open(url, "_blank");
        }
        break;
    }
  };

  const viewName = userContext?.hasAnyPermission([P.ThreeD]) ? "processing_with_3d" : "processing";

  return (
    <PhotoCentricPage
      needPermission={[P.ManageKN]}
      titleToken={"titles.objekti"}
      model={modelObjekti}
      mapId={3}
      onCustomTableAction={handleCustomAction}
      viewName={viewName}
      />
  );
}

export default ObjektiPhotoCentricPage;
