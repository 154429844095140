import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { buffer } from "ol/extent";

// MUI
import Skeleton from "@mui/material/Skeleton";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

// Custom
import { GridContainer, GridItem } from "@/ui/Grid";
import imgService from "@/services/imgService";

// Types
import { DCRecord } from "@/@types/lib/dataController";
import { IImageData } from "@/@types/views/KomunalniObveznici";

interface IDataCentricImagesOverlayProps {
  selectedObjektId: number;
  closeOverlay: () => void;
  imgData: IImageData | null;
  objExtent: number[];

  baseRecordPath: string
}

// const imgs = [img1, img2];

function DataCentricImagesOverlay(props: IDataCentricImagesOverlayProps) {

  const { selectedObjektId, closeOverlay, imgData, objExtent } = props;
  const { baseRecordPath } = props;

  const [img1, setImg1] = useState<DCRecord | null>(null);
  const [img2, setImg2] = useState<DCRecord | null>(null);
  const [img3, setImg3] = useState<DCRecord | null>(null);
  const { t } = useTranslation();

//   const baseRecordPath = "rasvjeta/rasvjetna-mjesta"

  let mounted = false;

  useEffect(() => {
    mounted = true;
    return () => { mounted = false; }
  }, [])

  useEffect(() => {
    if (selectedObjektId) {
      setImg1(null);
      imgService.getImage(baseRecordPath, selectedObjektId, 1, 400).then(resp => {
        if (resp.success && resp.data) {
          // if (mounted) {
            setImg1(resp.data as DCRecord);
          // }
        }
      });
      setImg2(null);
      imgService.getImage(baseRecordPath, selectedObjektId, 2, 400).then(resp => {
        if (resp.success && resp.data) {
          // if (mounted) {
            setImg2(resp.data as DCRecord);
          // }
        }
      });
    }
  }, [selectedObjektId]);

  useEffect(() => {
    if (objExtent && objExtent.length === 4) {
      setImg3(null);
      const bufferValue = Math.max(objExtent[3] - objExtent[1], objExtent[2] - objExtent[0]) * 0.9;
      imgService.getImage(baseRecordPath, selectedObjektId, 3, 400, buffer(objExtent, bufferValue).join(',')).then(resp => {
        if (resp.success && resp.data) {
          // if (mounted) {
            setImg3(resp.data as DCRecord);
          // }
        }
      });
    }
  }, [objExtent]);

  return (
    <div style={{ position: "absolute", width: "100%", height: "100%", top: 0, backgroundColor: "#fafafa", overflow: "auto", zIndex: 2000 }}>
      <IconButton style={{ position: "absolute", top: 1, right: 1 }} color="primary" onClick={() => closeOverlay()}>
        <CloseIcon />
      </IconButton>
      <GridContainer p={5}>
        {
          new Array(3).fill(null).map((_, i) => (
            <GridItem key={i} sm={12} md={6} lg={4} p={0}>
              {
                i === 0 ?
                  `${t("objekti.id")}: ${selectedObjektId % 1000000}`
                  : i === 1 ?
                    `${t("objekti.kat_no")}: ${imgData && imgData[selectedObjektId] ? imgData[selectedObjektId].kat + 1 : ""}`
                    : i === 2 ?
                      `${t("objekti.ukupno")}: ${imgData && imgData[selectedObjektId] ? imgData[selectedObjektId].ukupno : ""} m²`
                      : ""
              }
            </GridItem>
          ))
        }
        {
          [img1, img2, img3].map((img, i) => (
            <GridItem key={i} sm={12} md={6} lg={4} p={2} style={{ textAlign: "center" }}>
              {
                img && img.image ?
                  <img
                    src={`data:image/jpeg;charset=utf-8;base64,${img.image as string}`}
                    style={{ display: "inline-block", maxWidth: "100%", width: "80%" }}
                  />
                  : <Skeleton animation="wave" variant="rectangular" width={400} height={400} />
              }
            </GridItem>
          ))
        }
      </GridContainer>
    </div>
  );
}

export default DataCentricImagesOverlay;