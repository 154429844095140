import { transform } from "ol/proj";

import OlStyle from "ol/style/Style";
import OlStroke from "ol/style/Stroke";
import OlCircle from "ol/style/Circle";
import OlFill from "ol/style/Fill";
import OlIcon from "ol/style/Icon";
import OlText from "ol/style/Text";

import OlGeomMultiPoint from "ol/geom/MultiPoint";
import OlGeomPoint from "ol/geom/Point";
import OlGeomPolygon from "ol/geom/Polygon";
import OlGeomLineString from "ol/geom/LineString";
import {toStringHDMS} from 'ol/coordinate';
import {createStringXY} from 'ol/coordinate';

import { formatArea, formatLength } from "@/lib/olHelpers";
import { Geometry } from "ol/geom";

export {
  drawStyle,
  measurementsStyle,
  measuringStyle,
  recordStyle,
  workingRecordStyle,
  selectedRecordStyle,
  hoveringRecordStyle,
  commentsStyle,
  commentingStyle,
  geoLocateStyle,
  highlightedVectorStyle,
  fadeOutHighlightedStyle

};


const recordStyle = () => {
  let style = new OlStyle({
    image: new OlCircle({
      radius: 7,
      stroke: new OlStroke({
        color: "rgba(255, 255, 255, 0.79)"
      }),
      fill: new OlFill({
        color: "rgba(26, 114, 158, 0.79)"
      })
    }),
    fill: new OlFill({
      color: "rgba(184, 184, 184, 0.4)"
    }),
    stroke: new OlStroke({
      color: "#f03b20",
      width: 3.25
    })
  });
  return style;
};

const workingRecordStyle = () => {
  return new OlStyle({
    image: new OlCircle({
      radius: 15,
      stroke: new OlStroke({
        color: "#FF00FF",
        width: 3.25
      })
    }),
    stroke: new OlStroke({
      color: "#FF00FF",
      width: 3.25
    }),
    fill: new OlFill({
      color: "rgba(255, 0, 255, 0.2)"
    })
  });
}

const selectedRecordStyle = (f: any) => {
  const id = f.getId();
  const iid = id ? (typeof id === "string" ? parseInt(id, 10) : id) : 0
  const label = id ? (iid % 1000000).toString() : '?';
  return new OlStyle({
    image: new OlCircle({
      radius: 15,
      stroke: new OlStroke({
        color: "#00FF00",
        width: 3.25
      })
    }),
    stroke: new OlStroke({
      color: "#00FF00",
      width: 3.25
    }),
    text: new OlText({
      text: label,
      overflow: true,
      fill: new OlFill({ color: "#000" }),
      stroke: new OlStroke({
        color: "#fff"
        , width: 8
      }),
    })
  });
};

const hoveringRecordStyle = (f: any) => {
  const id = f.getId();
  const iid = id ? (typeof id === "string" ? parseInt(id, 10) : id) : 0
  const label = id ? (iid % 1000000).toString() : '?';
  return new OlStyle({
    image: new OlCircle({
      radius: 15,
      stroke: new OlStroke({
        color: "#feb24c",
        width: 3.25
      })
    }),
    stroke: new OlStroke({
      color: "#feb24c",
      width: 3.25
    }),
    text: new OlText({
      text: label,
      overflow: true,
      fill: new OlFill({ color: "#000" }),
      stroke: new OlStroke({
        color: "#fff"
        , width: 8
      }),
    })
  });
};

const fadeOutHighlightedStyle = (opacity: number, setStyleFn: any) => {
  setStyleFn(new OlStyle({
    image: new OlCircle({
      radius: 20,
      stroke: new OlStroke({
        color: `rgba(0, 28, 255, 1)`, //#001cff
        width: 5
      }),
      fill: new OlFill({
        color: `rgba(21, 252, 235, ${opacity})` //#15fceb
      })
    }),
    stroke: new OlStroke({
      color: `rgba(0, 28, 255, 1)`,
      width: 5
    }),
    fill: new OlFill({
      color: `rgba(21, 252, 235, ${opacity})`
    })
  }));

  if(opacity > 0.03) setTimeout(()=>{fadeOutHighlightedStyle(opacity-0.03, setStyleFn)}, 1)
  else if(opacity > 0) setTimeout(()=>{fadeOutHighlightedStyle(0, setStyleFn)}, 1)
}

const drawStyle = new OlStyle({
  image: new OlCircle({
    radius: 7,
    stroke: new OlStroke({
      color: "rgba(255, 255, 255, 0.79)"
    }),
    fill: new OlFill({
      color: "rgba(26, 114, 158, 0.79)"
    })
  }),
  fill: new OlFill({
    color: "rgba(255,255,255,0.4)"
  }),
  stroke: new OlStroke({
    color: "#3399CC",
    width: 3.25
  })
});

export type AngleRecords = {
  id: Number;
  angles: Array<Number>;
}
// angles = angles for one polygon/linestring
const angles: Array<Number> = [];
// allAngles = dictionary with all the angles for all the polygons/linestrings
const allAngles: Array<AngleRecords> = [];

const measuringStyle = (f: any) => {
  if(f.values_.geometry.flatCoordinates.length >= 4) {
    const coords = f.values_.geometry.flatCoordinates;
    let x = coords[coords.length-2] - coords[coords.length-4]
    let y = coords[coords.length-1] - coords[coords.length-3]
    let pi = Math.PI;

    let angle = -(Math.atan2(y, x));
    if(-pi/2 > angle || angle > pi/2) {
      angle = angle - pi;
    }

    angles[coords.length / 2 - 2] = angle;
  }

  const styles: Array<OlStyle> = [];

  const geom = f.getGeometry() as Geometry;
  const geomType = geom?.getType();

  const line = geomType === "LineString" ? geom as OlGeomLineString
    : geomType === "Polygon" ? new OlGeomLineString((geom as OlGeomPolygon).getCoordinates()[0])
      : null
    ;

  const lineStyle = new OlStyle({
    stroke: new OlStroke({
      color: 'rgba(0, 0, 0, 0.65)',
      width: 4
    }),
    fill: new OlFill({
      color: 'rgba(255, 255, 255, 0.2)'
    }),
  })
  styles.push(lineStyle);

  const pointsStyle = new OlStyle({
    image: new OlCircle({
      radius: 3,
      stroke: new OlStroke({
        color: "#232f34"
      }),
      fill: new OlFill({
        color: "#FFFFFF"
      })
    }),
    geometry: function (feature) {
      const geom = feature.getGeometry() as Geometry;
      const geomType = geom?.getType();
      switch (geomType) {
        case "Polygon":
          return new OlGeomMultiPoint((geom as OlGeomPolygon).getCoordinates()[0]);
        case "LineString":
          return new OlGeomMultiPoint((geom as OlGeomLineString).getCoordinates());
        default:
          return geom;
      }
    }
  });
  styles.push(pointsStyle);

  const labelStyle = new OlStyle({
    text: new OlText({
      font: '16px Roboto,sans-serif',
      fill: new OlFill({
        color: 'rgba(0, 0, 0, 1)'
      }),
      stroke: new OlStroke({
        color: 'rgba(255, 255, 255, 1)',
        width: 5
      })
    })
  });

  const labelStyleCache: Array<OlStyle> = [];

  if (line) {
    let count = 0;
    line.forEachSegment(function (a, b) {
      var segment = new OlGeomLineString([a, b]);
      var label = formatLength(segment);
      if (labelStyleCache.length - 1 < count) {
        labelStyleCache.push(new OlStyle({
          text: new OlText({
            font: '16px Roboto,sans-serif',
            fill: new OlFill({
              color: 'rgba(0, 0, 0, 1)'
            }),
            stroke: new OlStroke({
              color: 'rgba(255, 255, 255, 1)',
              width: 5
            }),
            rotation: angles.length > 0 ? Number(angles[count]) : 0
          })
        }));
      }
      labelStyleCache[count].setGeometry(segment);
      labelStyleCache[count].getText().setText(label);
      styles.push(labelStyleCache[count]);
      count++;
    });
  }

  return styles;
};

const measurementsStyle = (f: any, res: number) => {
  // Drawing has ended - push the current state of angles into the allAngleState
  let test = true;

  for (const record of allAngles) {
    if(record.id === f.ol_uid) {
      test = false;
    }
  }

  if(test) {
    const newRecord = {
      id: f.ol_uid,
      angles: angles.slice() 
    }

    allAngles.push(newRecord);
  }

  const styles: Array<OlStyle> = [];

  const geom = f.getGeometry() as Geometry;
  const geomType = geom?.getType();

  const line = geomType === "LineString" ? geom as OlGeomLineString
    : geomType === "Polygon" ? new OlGeomLineString((geom as OlGeomPolygon).getCoordinates()[0])
      : null
    ;

  const lineStyle = new OlStyle({
    stroke: new OlStroke({
      color: 'rgba(0, 0, 0, 0.65)',
      lineDash: [10, 10],
      width: 4
    }),
    fill: new OlFill({
      color: "rgba(255, 255, 255, 0.5)"
    })
  })
  styles.push(lineStyle);

  const pointsStyle = new OlStyle({
    image: new OlCircle({
      radius: 4,
      stroke: new OlStroke({
        color: "#232f34",
        width: 2
      }),
      fill: new OlFill({
        color: "#FFFFFF"
      })
    }),
    geometry: function (feature) {
      const geom = feature.getGeometry() as Geometry;
      const geomType = geom?.getType();
      switch (geomType) {
        case "Polygon":
          return new OlGeomMultiPoint((geom as OlGeomPolygon).getCoordinates()[0]);
        case "LineString":
          return new OlGeomMultiPoint((geom as OlGeomLineString).getCoordinates());
        default:
          return geom;
      }
    }
  });
  styles.push(pointsStyle);

  const labelStyle = new OlStyle({
    text: new OlText({
      font: '16px Roboto,sans-serif',
      fill: new OlFill({
        color: 'rgba(0, 0, 0, 1)'
      }),
      stroke: new OlStroke({
        color: 'rgba(255, 255, 255, 1)',
        width: 5
      })
    })
  });

  const labelStyleCache: Array<OlStyle> = [];

  if (line && res < 0.3) {
    let count = 0;
    line.forEachSegment(function (a, b) {
      var segment = new OlGeomLineString([a, b]);
      var label = formatLength(segment);
      if (labelStyleCache.length - 1 < count) {
        labelStyleCache.push(new OlStyle({
          text: new OlText({
            font: '16px Roboto,sans-serif',
            fill: new OlFill({
              color: 'rgba(0, 0, 0, 1)'
            }),
            stroke: new OlStroke({
              color: 'rgba(255, 255, 255, 1)',
              width: 5
            }),
            // @ts-ignore - Type conversion to number
            rotation: allAngles.find((element) => element.id === f.ol_uid) ? Number(allAngles.find((element) => element.id === f.ol_uid)?.angles[count]) : 0
          })
        }));
      }
      labelStyleCache[count].setGeometry(segment);
      labelStyleCache[count].getText().setText(label);
      styles.push(labelStyleCache[count]);
      count++;
    });
  }


  if (geomType === "Point") {

    const htrs_array = transform((geom as OlGeomPoint).getCoordinates(), "EPSG:3857", "EPSG:3765");
    const output = "X: " + htrs_array[0].toFixed(0) + ", Y:" + htrs_array[1].toFixed(0);

    const singlePointLabelStyle = new OlStyle({
      text: new OlText({
        font: "16px Roboto,sans-serif",
        fill: new OlFill({ color: "#000" }),
        stroke: new OlStroke({
          color: "#fff"
          , width: 8
        }),
        text: output,
        textBaseline: "center",
        offsetY: -12
      })
    })
    styles.push(singlePointLabelStyle);
  }

  const resultsStyle = new OlStyle({
    text: new OlText({
      font: '16px Roboto,sans-serif',
      fill: new OlFill({
        color: 'rgba(0, 0, 0, 1)'
      }),
      stroke: new OlStroke({
        color: 'rgba(250, 108, 92, 1)', // -red ; rgba(237, 220, 126, 1) - yellow
        width: 5
      }),
      text: geomType === "Polygon" ? formatArea(geom)
      : geomType === "LineString" ? formatLength(geom)
      : undefined
    }),
    geometry: function (feature) {
      const geom = feature.getGeometry() as Geometry;
      const geomType = geom?.getType();
      switch (geomType) {
        case "Polygon":
          return (geom as OlGeomPolygon).getInteriorPoint();
        case "LineString":
          return new OlGeomPoint((geom as OlGeomLineString).getLastCoordinate());
        default:
          return geom as OlGeomPoint;
      }
    }})
    styles.push(resultsStyle)

  return styles;
};

const commentingStyle = new OlStyle({
  fill: new OlFill({
    color: 'rgba(255, 255, 255, 0.2)',
  }),
  stroke: new OlStroke({
    color: 'rgba(56, 142, 60, 0.5)',
    lineDash: [10, 10],
    width: 4,
  }),
  image: new OlCircle({
    radius: 5,
    stroke: new OlStroke({
      color: 'rgba(56, 142, 60, 0.5)',
      width: 4
    }),
    fill: new OlFill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
  })
});

const commentsStyle = (f:any) => {
  const geom = f.getGeometry();
  var output;
  var tooltipCoord;

  if (geom instanceof OlGeomPolygon) {
    output = formatArea(geom);
    //tooltipCoord = geom.getInteriorPoint().getCoordinates();
    tooltipCoord = geom.getCoordinates()[0];
  } else if (geom instanceof OlGeomLineString) {
    output = formatLength(geom);
    tooltipCoord = geom.getCoordinates()[0];
  } else if (geom instanceof OlGeomPoint) {
    let htrs_array = transform(geom.getCoordinates(), "EPSG:3857", "EPSG:3765");
    output = "X: "+htrs_array[0].toFixed(0) + ", Y:" + htrs_array[1].toFixed(0);
    tooltipCoord = geom;
  }
  let style = [
    new OlStyle({
      image: new OlCircle({
        radius: 5,
        stroke: new OlStroke({
          color: "#388e3c",
          width: 4
        }),
        fill: new OlFill({
          color: "rgba(255, 255, 255, 0.2)"
        })
      })
    }),
    new OlStyle({
      fill: new OlFill({
        color: "rgba(255, 255, 255, 0.5)"
      }),
      stroke: new OlStroke({
        color: "#388e3c",
        // lineDash: [10, 10],
        width: 4,
      })
    }),
    // new OlStyle({
    //   text: new OlText({
    //     font: "12px sans serif",
    //     fill: new OlFill({ color: "#000" }),
    //     stroke: new OlStroke({color: "#fff", width: 8}),
    //     text: output,
    //     textBaseline: "middle",
    //     offsetY: -12
    //   })
    // })

    // ,
    // new OlStyle({
    //   image: new OlCircle({
    //     radius: 3,
    //     stroke: new OlStroke({
    //       color: "#232f34"
    //     }),
    //     fill: new OlFill({
    //       color: "rgba(255, 255, 255, 0.2)"
    //     })
    //   }),
    //   geometry: function (feature) {
    //     // return the coordinates of the first ring of the polygon
    //     const geom = feature.getGeometry();
    //     const type = geom.getType();
    //     var coordinates = geom.getCoordinates
    //       ? type === "Polygon"
    //         ? geom.getCoordinates()[0]
    //         : geom.getCoordinates()
    //       : null;
    //     return coordinates ? new OlGeomMultiPoint(coordinates) : null;
    //   }
    // })
  ];
  return style;
};

const geoLocateStyle = new OlStyle({
  image: new OlCircle({
    radius: 6,
    fill: new OlFill({
      color: '#3399CC',
    }),
    stroke: new OlStroke({
      color: '#fff',
      width: 2,
    })
  })
});

const highlightedVectorStyle = () => {
  return new OlStyle({
    image: new OlCircle({
      radius: 20,
      stroke: new OlStroke({
        color: "#FF00FF",
        width: 3.25
      })
    }),
    stroke: new OlStroke({
      color: "#FF00FF",
      width: 3.25
    }),
    fill: new OlFill({
      color: "rgba(255, 0, 255, 0.2)"
    })
  });
}
