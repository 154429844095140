import React, { VoidFunctionComponent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

//MUI
import Box from "@mui/material/Box";

//Custom Components
import { Permissions as P } from "@/lib/permissions";
import AppPage from "@/ui/AppPage/AppPage";
import DataCentricPrimaryTable from "./DataCentricPrimaryTable";
import DataCentricSecondaryTable from "./DataCentricSecondaryTable";
import DataCentricMiniMap from "./DataCentricMiniMap";
import DataCentricImagesOverlay from "./DataCentricImagesOverlay";

//Types
import { DCRecord } from "@/@types/lib/dataController";
import { IImageData } from "@/@types/views/KomunalniObveznici";

import { IModel } from "@/@types/models/model";
import { Permission } from "@/@types/common"
import { RowActionFn } from "@/@types/ui/Table";

export type DataCentricPageParams = {
  obj_id?: string;

  needPermission?: [Permission]

  primaryModel: IModel
  primaryViewName?: string
  primaryTitleToken: string
  primaryRecordLocationField: string
  onCustomPrimaryTableAction?: RowActionFn,

  secondaryModel: IModel
  secondaryViewName?: string
  secondaryTitleToken: string

  baseRecordPath: string
  childRecordRelativePath: string
  geomRelativePath: string

  mapId: number
  mapModel: IModel

  filterByLocation?: boolean;
  allowShowImages? : boolean;

  disableControlsPrimary?: boolean;
  disableControlsSecondary?: boolean;
  allowLegend?: boolean;
  onLegendClick?: () => void;
  allowFilterTogglePrimary?: boolean;
  allowExport?: boolean;
  // allowFilterToggleSecondary?: boolean;
}


function DataCentricPage(props: DataCentricPageParams) {
  const [primaryRecord, setPrimaryRecord] = useState<DCRecord | null>(null);
  const [lokacijaid, setLokacijaId] = useState(0);
  const [selectedObjektId, setSelectedObjektId] = useState(0);
  const [showImages, setShowImages] = useState(false);
  const [imgData, setImgData] = useState<IImageData | null>(null);
  const [objExtent, setObjExtent] = useState<number[]>([]);
  const [internalObjId, setInternalObjId] = useState<number>(-1)

  const {
    needPermission,
    primaryModel,
    primaryViewName,
    primaryTitleToken,
    primaryRecordLocationField,
    onCustomPrimaryTableAction,
    secondaryModel,
    secondaryViewName,
    secondaryTitleToken,
    mapId,
    mapModel,
    baseRecordPath,
    childRecordRelativePath,
    geomRelativePath,
    filterByLocation,
    allowShowImages,
    disableControlsPrimary,
    disableControlsSecondary,
    allowLegend,
    onLegendClick,
    allowFilterTogglePrimary,
    // allowFilterToggleSecondary,
    allowExport
  } = props;

  const [searchParams] = useSearchParams();

  const obj_id = searchParams.get("obj_id");
  const openFirst = searchParams.get("open_first");
  const loc_id = searchParams.get("loc_id");

  const int_obj_id = obj_id ? parseInt(obj_id, 10) : undefined;
  const bool_open_first = openFirst === "true" ? true : false;
  const int_loc_id = loc_id ? parseInt(loc_id, 10) : undefined;

  const handleLokacijaSelect = (id: number, record: DCRecord | null) => {
    if (id === lokacijaid) {
      setLokacijaId(0);
      setPrimaryRecord(null);
    } else {
      //@ts-ignore
      setLokacijaId((record[primaryRecordLocationField]) as number);
      setPrimaryRecord(record);
    }
    setInternalObjId(-1);
    setSelectedObjektId(0);
  };

  const handleLokacijeLoad = (records: Array<DCRecord>) => {
    if (bool_open_first && records.length > 0) {
      const firstRec = records[0];
      const firstRecLokId = firstRec[primaryRecordLocationField] as number;
      setLokacijaId(firstRecLokId);
      setPrimaryRecord(firstRec);
    }
  };

  const handleObjektSelect = (id: number) => {
    if (id === selectedObjektId) {
      setSelectedObjektId(0);
    } else {
      setSelectedObjektId(id); 
    }
    if (!showImages && id) {
      setShowImages(true);
    }
  };

  const handleObjektiLoad = (records: Array<DCRecord>) => {
    if (bool_open_first && records.length > 0) {
      const firstObj = records[0];
      const firstObjId = firstObj.id as number;
      if (internalObjId !== -1) {
        setSelectedObjektId(internalObjId);
        setShowImages(true);
      }
    }
  };

  useEffect(() => {
    setShowImages(selectedObjektId !== 0 && selectedObjektId !== null);
  }, [selectedObjektId]);


  useEffect(() => {
    if (int_obj_id === undefined) {
      setPrimaryRecord(null);
      setLokacijaId(0);
      setSelectedObjektId(0);
    } else {
      setInternalObjId(int_obj_id);
    }
  }, [int_obj_id])

  useEffect(() => {
    if (int_loc_id !== undefined) {
      setLokacijaId(int_loc_id)
    }
  }, [int_loc_id])

  return (
    <AppPage needPermission={needPermission}>
      <Box m={0} style={{ height: "60%", position: "relative" }}>
        <DataCentricPrimaryTable 
            onRowSelect={handleLokacijaSelect} 
            onLoad={handleLokacijeLoad} 
            lokacijaId={lokacijaid}
            filterByLocation={filterByLocation}
            allowFilterToggle={allowFilterTogglePrimary !== undefined ? allowFilterTogglePrimary : false}
            
            model={primaryModel}
            viewName={primaryViewName}
            titleToken={primaryTitleToken}
            locationField={primaryRecordLocationField}
            disableControls={disableControlsPrimary !== undefined ? disableControlsPrimary : true}
            
            allowLegend={allowLegend !== undefined ? allowLegend : false}
            onLegendClick={onLegendClick ? onLegendClick : undefined}
            allowExport={allowExport}

            onRowAction={onCustomPrimaryTableAction}
        />
        {allowShowImages && showImages ? (
          <DataCentricImagesOverlay
            selectedObjektId={selectedObjektId}
            closeOverlay={() => setShowImages(false)}
            imgData={imgData}
            objExtent={objExtent}

            baseRecordPath={primaryModel.apiPath}
          />
        ) : null}
      </Box>
      <Box m={0} style={{ height: "40%" }}>
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
          <div style={{ height: "100%", aspectRatio: "1/1" }}>
            <DataCentricMiniMap
              lokacijaId={lokacijaid}
              onObjektSelect={handleObjektSelect}
              onLokacijaSelect={handleLokacijaSelect}
              selectedObjektId={selectedObjektId}
              _setObjExtent={setObjExtent}

              model={mapModel}
              mapId={mapId}
              baseRecordPath={baseRecordPath}
              geomRelativePath={geomRelativePath}
              locationField={primaryRecordLocationField}
            />
          </div>
          <div style={{ width: "100%", height: "100%" }}>
            <DataCentricSecondaryTable
              lokacijaId={lokacijaid}
              primaryRecord={primaryRecord}
              onObjektSelect={handleObjektSelect}
              selectedObjektId={selectedObjektId}
              setImgData={setImgData}
              onLoad={handleObjektiLoad}

              model={secondaryModel}
              viewName={secondaryViewName}
              titleToken={secondaryTitleToken}

              baseRecordPath={baseRecordPath}
              childRecordRelativePath={childRecordRelativePath}

              disableControls={disableControlsSecondary !== undefined ? disableControlsSecondary : true}
            />
          </div>
        </div>
      </Box>
    </AppPage>
  );
}

export default DataCentricPage;
