import { IModel } from "@/@types/models/model";
import { commonFields } from "./helpers/commonFields";

const User: IModel = {
  title: "Adrese",
  apiPath: "data/adrese",
  forms : {
    "default": {
      fields: [ "ulica_kbr", "na_ime"]
    }
  },
  listViews: {
    "default": {
      fields: ["id", "ulica_kbr", "na_ime", "show_on_map"]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      type: "numeric",
      idattr: true,
      readonly: true,
      search: false
    },
    {
      title: "ulica_kbr",
      source: "ulica_kbr",
      ttoken: "common.adresa",
      type: "text",
      readonly: true
    },
    {
      title: "na_ime",
      source: "na_ime",
      ttoken: "common.naselje",
      type: "text",
      readonly: true
    },
    {
      title: "",
      source: "show_on_map",
      tooltip: "buttons.show_on_map",
      ttoken: "",
      type: "button",
      icon: "fmd_good",
      action: "show_on_map",
      padding: "none",
      sort: false,
      search: false
    },
    commonFields.wkt,
    commonFields.proj
  ]
}

export default User;
