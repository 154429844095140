import { IModel } from "@/@types/models/model";

const Objekti: IModel = {
  title: "Statusi",
  source: "statusi",
  apiPath: "komunalni-obveznici-statusi",
  forms : {
    "default": {
      fields: ["id", "status"]
    }
  },
  listViews: {
    "default": {
      fields: ["status", "opis"],
      hidden: ["id"]
    }
  },
  fields: [
    {
      title: "id",
      source: "id",
      ttoken: "common.id",
      tooltip: "objekti.id",
      type: "numeric",
      idattr: true,
      readonly: true
    },
    {
      title: "status",
      source: "status",
      filter: true,
      ttoken: "objekti.status",
      tooltip: "objekti.istatus",
      type: "numeric",
    },
    {
      title: "sifra",
      source: "sifra",
      ttoken: "objekti.status",
      tooltip: "objekti.istatus",
      type: "text",
    },
    {
      title: "opis",
      source: "opis",
      filter: true,
      ttoken: "objekti.opis",
      tooltip: "objekti.istatus",
      type: "text",
    }
  ]
}

export default Objekti;
